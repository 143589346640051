import { CREATE_PROVIDER_TYPE_ERROR, CREATE_PROVIDER_TYPE_LOADING, CREATE_PROVIDER_TYPE_SUCCESS, DELETE_PROVIDER_TYPE_ERROR, DELETE_PROVIDER_TYPE_LOADING, DELETE_PROVIDER_TYPE_SUCCESS, EXPORT_ALL_CSV_PROVIDER_TYPES_ERROR, EXPORT_ALL_CSV_PROVIDER_TYPES_LOADING, EXPORT_ALL_CSV_PROVIDER_TYPES_SUCCESS, GET_ALL_PROVIDER_TYPES_ERROR, GET_ALL_PROVIDER_TYPES_LOADING, GET_ALL_PROVIDER_TYPES_SUCCESS, IMPORT_ALL_CSV_PROVIDER_TYPES_ERROR, IMPORT_ALL_CSV_PROVIDER_TYPES_LOADING, IMPORT_ALL_CSV_PROVIDER_TYPES_SUCCESS, SEARCH_PROVIDER_TYPE_ERROR, SEARCH_PROVIDER_TYPE_LOADING, SEARCH_PROVIDER_TYPE_SUCCESS, UPDATE_PROVIDER_TYPE_ERROR, UPDATE_PROVIDER_TYPE_LOADING, UPDATE_PROVIDER_TYPE_STATUS_ERROR, UPDATE_PROVIDER_TYPE_STATUS_LOADING, UPDATE_PROVIDER_TYPE_STATUS_SUCCESS, UPDATE_PROVIDER_TYPE_SUCCESS } from "./actionTypes";

const initialState = {
    getAllProviderTypes: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvProviderTypes: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvImportProviderTypes: {
        loading: false,
        data: null,
        error: null
    },
    createProviderType: {
        loading: false,
        data: null,
        error: null
    },
    updateProviderType: {
        loading: false,
        data: null,
        error: null
    },
    updateProviderTypeStatus: {
        loading: false,
        data: null,
        error: null
    },
    deleteProviderType: {
        loading: false,
        data: null,
        error: null
    },
    searchProviderType: {
        loading: false,
        data: null,
        error: null
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PROVIDER_TYPES_LOADING:
            return { ...state, getAllProviderTypes: { ...state.getAllProviderTypes, loading: true, data: null, error: null } };
        case GET_ALL_PROVIDER_TYPES_SUCCESS:
            return { ...state, getAllProviderTypes: { ...state.getAllProviderTypes, loading: false, data: action.payload, error: null } };
        case GET_ALL_PROVIDER_TYPES_ERROR:
            return { ...state, getAllProviderTypes: { ...state.getAllProviderTypes, loading: false, data: null, error: action.payload } };

        case EXPORT_ALL_CSV_PROVIDER_TYPES_LOADING:
            return { ...state, getAllCsvProviderTypes: { ...state.getAllCsvProviderTypes, loading: true, data: null, error: null } };
        case EXPORT_ALL_CSV_PROVIDER_TYPES_SUCCESS:
            return { ...state, getAllCsvProviderTypes: { ...state.getAllCsvProviderTypes, loading: false, data: action.payload, error: null } };
        case EXPORT_ALL_CSV_PROVIDER_TYPES_ERROR:
            return { ...state, getAllCsvProviderTypes: { ...state.getAllCsvProviderTypes, loading: false, data: null, error: action.payload } };

        case IMPORT_ALL_CSV_PROVIDER_TYPES_LOADING:
            return { ...state, getAllCsvImportProviderTypes: { ...state.getAllCsvImportProviderTypes, loading: true, data: null, error: null } };
        case IMPORT_ALL_CSV_PROVIDER_TYPES_SUCCESS:
            return { ...state, getAllCsvImportProviderTypes: { ...state.getAllCsvImportProviderTypes, loading: false, data: action.payload, error: null } };
        case IMPORT_ALL_CSV_PROVIDER_TYPES_ERROR:
            return { ...state, getAllCsvImportProviderTypes: { ...state.getAllCsvImportProviderTypes, loading: false, data: null, error: action.payload } };

        case CREATE_PROVIDER_TYPE_LOADING:
            return { ...state, createProviderType: { ...state.createProviderType, loading: true, data: null, error: null } };
        case CREATE_PROVIDER_TYPE_SUCCESS:
            return { ...state, createProviderType: { ...state.createProviderType, loading: false, data: action.payload, error: null } };
        case CREATE_PROVIDER_TYPE_ERROR:
            return { ...state, createProviderType: { ...state.createProviderType, loading: false, data: null, error: action.payload } };

        case UPDATE_PROVIDER_TYPE_LOADING:
            return { ...state, updateProviderType: { ...state.updateProviderType, loading: true, data: null, error: null } };
        case UPDATE_PROVIDER_TYPE_SUCCESS:
            return { ...state, updateProviderType: { ...state.updateProviderType, loading: false, data: action.payload, error: null } };
        case UPDATE_PROVIDER_TYPE_ERROR:
            return { ...state, updateProviderType: { ...state.updateProviderType, loading: false, data: null, error: action.payload } };

        case UPDATE_PROVIDER_TYPE_STATUS_LOADING:
            return { ...state, updateProviderTypeStatus: { ...state.updateProviderTypeStatus, loading: true, data: null, error: null } };
        case UPDATE_PROVIDER_TYPE_STATUS_SUCCESS:
            return { ...state, updateProviderTypeStatus: { ...state.updateProviderTypeStatus, loading: false, data: action.payload, error: null } };
        case UPDATE_PROVIDER_TYPE_STATUS_ERROR:
            return { ...state, updateProviderTypeStatus: { ...state.updateProviderTypeStatus, loading: false, data: null, error: action.payload } };

        case DELETE_PROVIDER_TYPE_LOADING:
            return { ...state, deleteProviderType: { ...state.deleteProviderType, loading: true, data: null, error: null } };
        case DELETE_PROVIDER_TYPE_SUCCESS:
            return { ...state, deleteProviderType: { ...state.deleteProviderType, loading: false, data: action.payload, error: null } };
        case DELETE_PROVIDER_TYPE_ERROR:
            return { ...state, deleteProviderType: { ...state.deleteProviderType, loading: false, data: null, error: action.payload } };

        case SEARCH_PROVIDER_TYPE_LOADING:
            return { ...state, searchProviderType: { ...state.searchProviderType, loading: true, data: null, error: null } };
        case SEARCH_PROVIDER_TYPE_SUCCESS:
            return { ...state, searchProviderType: { ...state.searchProviderType, loading: false, data: action.payload, error: null } };
        case SEARCH_PROVIDER_TYPE_ERROR:
            return { ...state, searchProviderType: { ...state.searchProviderType, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
