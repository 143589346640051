import { CREATE_PRODUCT_ERROR, CREATE_PRODUCT_LOADING, CREATE_PRODUCT_SUCCESS, DELETE_PRODUCT_ERROR, DELETE_PRODUCT_LOADING, DELETE_PRODUCT_SUCCESS, EXPORT_ALL_CSV_PRODUCTS_ERROR, EXPORT_ALL_CSV_PRODUCTS_LOADING, EXPORT_ALL_CSV_PRODUCTS_SUCCESS, GET_ALL_PRODUCTS_ERROR, GET_ALL_PRODUCTS_LOADING, GET_ALL_PRODUCTS_SUCCESS, IMPORT_ALL_CSV_PRODUCTS_ERROR, IMPORT_ALL_CSV_PRODUCTS_LOADING, IMPORT_ALL_CSV_PRODUCTS_SUCCESS, SEARCH_PRODUCT_ERROR, SEARCH_PRODUCT_FROM_CAT_ERROR, SEARCH_PRODUCT_FROM_CAT_LOADING, SEARCH_PRODUCT_FROM_CAT_SUCCESS, SEARCH_PRODUCT_LOADING, SEARCH_PRODUCT_SUCCESS, UPDATE_PRODUCT_ERROR, UPDATE_PRODUCT_LOADING, UPDATE_PRODUCT_STATUS_ERROR, UPDATE_PRODUCT_STATUS_LOADING, UPDATE_PRODUCT_STATUS_SUCCESS, UPDATE_PRODUCT_SUCCESS } from "./actionTypes";

const initialState = {
    getAllProducts: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvProducts: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvImportProducts: {
        loading: false,
        data: null,
        error: null
    },
    createProduct: {
        loading: false,
        data: null,
        error: null
    },
    updateProduct: {
        loading: false,
        data: null,
        error: null
    },
    updateProductStatus: {
        loading: false,
        data: null,
        error: null
    },
    deleteProduct: {
        loading: false,
        data: null,
        error: null
    },
    searchProduct: {
        loading: false,
        data: null,
        error: null
    },
    searchProductFromCategories: {
        loading: false,
        data: null,
        error: null
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PRODUCTS_LOADING:
            return { ...state, getAllProducts: { ...state.getAllProducts, loading: true, data: null, error: null } };
        case GET_ALL_PRODUCTS_SUCCESS:
            return { ...state, getAllProducts: { ...state.getAllProducts, loading: false, data: action.payload, error: null } };
        case GET_ALL_PRODUCTS_ERROR:
            return { ...state, getAllProducts: { ...state.getAllProducts, loading: false, data: null, error: action.payload } };

        case EXPORT_ALL_CSV_PRODUCTS_LOADING:
            return { ...state, getAllCsvProducts: { ...state.getAllCsvProducts, loading: true, data: null, error: null } };
        case EXPORT_ALL_CSV_PRODUCTS_SUCCESS:
            return { ...state, getAllCsvProducts: { ...state.getAllCsvProducts, loading: false, data: action.payload, error: null } };
        case EXPORT_ALL_CSV_PRODUCTS_ERROR:
            return { ...state, getAllCsvProducts: { ...state.getAllCsvProducts, loading: false, data: null, error: action.payload } };

        case IMPORT_ALL_CSV_PRODUCTS_LOADING:
            return { ...state, getAllCsvImportProducts: { ...state.getAllCsvImportProducts, loading: true, data: null, error: null } };
        case IMPORT_ALL_CSV_PRODUCTS_SUCCESS:
            return { ...state, getAllCsvImportProducts: { ...state.getAllCsvImportProducts, loading: false, data: action.payload, error: null } };
        case IMPORT_ALL_CSV_PRODUCTS_ERROR:
            return { ...state, getAllCsvImportProducts: { ...state.getAllCsvImportProducts, loading: false, data: null, error: action.payload } };

        case CREATE_PRODUCT_LOADING:
            return { ...state, createProduct: { ...state.createProduct, loading: true, data: null, error: null } };
        case CREATE_PRODUCT_SUCCESS:
            return { ...state, createProduct: { ...state.createProduct, loading: false, data: action.payload, error: null } };
        case CREATE_PRODUCT_ERROR:
            return { ...state, createProduct: { ...state.createProduct, loading: false, data: null, error: action.payload } };

        case UPDATE_PRODUCT_LOADING:
            return { ...state, updateProduct: { ...state.updateProduct, loading: true, data: null, error: null } };
        case UPDATE_PRODUCT_SUCCESS:
            return { ...state, updateProduct: { ...state.updateProduct, loading: false, data: action.payload, error: null } };
        case UPDATE_PRODUCT_ERROR:
            return { ...state, updateProduct: { ...state.updateProduct, loading: false, data: null, error: action.payload } };

        case UPDATE_PRODUCT_STATUS_LOADING:
            return { ...state, updateProductStatus: { ...state.updateProductStatus, loading: true, data: null, error: null } };
        case UPDATE_PRODUCT_STATUS_SUCCESS:
            return { ...state, updateProductStatus: { ...state.updateProductStatus, loading: false, data: action.payload, error: null } };
        case UPDATE_PRODUCT_STATUS_ERROR:
            return { ...state, updateProductStatus: { ...state.updateProductStatus, loading: false, data: null, error: action.payload } };

        case DELETE_PRODUCT_LOADING:
            return { ...state, deleteProduct: { ...state.deleteProduct, loading: true, data: null, error: null } };
        case DELETE_PRODUCT_SUCCESS:
            return { ...state, deleteProduct: { ...state.deleteProduct, loading: false, data: action.payload, error: null } };
        case DELETE_PRODUCT_ERROR:
            return { ...state, deleteProduct: { ...state.deleteProduct, loading: false, data: null, error: action.payload } };

        case SEARCH_PRODUCT_LOADING:
            return { ...state, searchProduct: { ...state.searchProduct, loading: true, data: null, error: null } };
        case SEARCH_PRODUCT_SUCCESS:
            return { ...state, searchProduct: { ...state.searchProduct, loading: false, data: action.payload, error: null } };
        case SEARCH_PRODUCT_ERROR:
            return { ...state, searchProduct: { ...state.searchProduct, loading: false, data: null, error: action.payload } };

        case SEARCH_PRODUCT_FROM_CAT_LOADING:
            return { ...state, searchProductFromCategories: { ...state.searchProductFromCategories, loading: true, data: null, error: null } };
        case SEARCH_PRODUCT_FROM_CAT_SUCCESS:
            return { ...state, searchProductFromCategories: { ...state.searchProductFromCategories, loading: false, data: action.payload, error: null } };
        case SEARCH_PRODUCT_FROM_CAT_ERROR:
            return { ...state, searchProductFromCategories: { ...state.searchProductFromCategories, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
