import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';


import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { adminCreateUserAction, adminDeleteUserAction, adminGetAllUserAction, adminUpdateUserAction, adminUpdateUserStatusAction, getAdminUserAction } from '../../store/admin/asyncActions';
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Grow, InputLabel, MenuItem, Paper, Select, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import { getAllPermissionAction, getAllRolesAction } from '../../store/role/asyncActions';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  }
}));

function AdminUsers() {
  const classes = useStyles();
  const [users, setUsers] = useState(null);
  const [roles, setRoles] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userID, setUserID] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();
  const adminUser = useSelector((state) => state?.admin?.adminUser);
  const adminRoles = useSelector((state) => state?.role?.getAllRoles);

  const usersList = useSelector((state) => state?.admin?.adminGetAllUsers);
  const newUser = useSelector((state) => state?.admin?.adminCreateUser);
  const updatedUser = useSelector((state) => state?.admin?.adminUpdateUser);
  const updatedUserStatus = useSelector((state) => state?.admin?.adminUpdateUserStatus);
  const deletedUser = useSelector((state) => state?.admin?.adminDeleteUser);

  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermission, setAdminPermission] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermission(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    dispatch(adminGetAllUserAction(page + 1, pageSize));
    dispatch(getAllRolesAction(1, 500));
  }, [page, pageSize]);

  useEffect(() => {
    document.title = 'Admin Users';
    dispatch(getAdminUserAction())
  }, []);

  useEffect(() => {
    if (usersList?.data?.success && Array.isArray(usersList?.data?.success?.records)) {
      setUsers(usersList?.data?.success?.records);
      setTotalCount(usersList?.data?.success?.totalCount);
    }
  }, [usersList]);

  useEffect(() => {
    if (adminRoles?.data?.status && Array.isArray(adminRoles?.data?.data?.records)) {
      setRoles(adminRoles?.data?.data?.records)
    }
  }, [adminRoles]);

  const [userFormData, setUserFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    mobile: '',
    role: ''
  });

  const resetForm = () => {
    setErrors({});
    delete userFormData?._id;
    delete userFormData?.modified_by;
    setUserFormData({
      ...userFormData,
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      mobile: '',
      role: ''
    });
  }

  const userDataHandleChange = (e) => {
    setUserFormData({
      ...userFormData,
      [e.target.name]: e.target.value
    });
  }

  const handleMobileChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= 10) {
      userDataHandleChange(e);
    } else {
      e.preventDefault(); // Prevent default behavior (form submission)
    }
  };

  const createUser = () => {
    dispatch(adminCreateUserAction(userFormData))
  }

  useEffect(() => {
    if (newUser?.data?.success) {
      setOpenModal(false);
      dispatch(adminGetAllUserAction(page + 1, pageSize));
      resetForm();
      newUser.data = null;
    }
  }, [newUser]);

  useEffect(() => {
    if (updatedUser?.data?.success) {
      setOpenModal(false);
      dispatch(adminGetAllUserAction(page + 1, pageSize));
      resetForm();
      updatedUser.data = null;
    }
  }, [updatedUser]);

  useEffect(() => {
    if (updatedUserStatus?.data?.success) {
      dispatch(adminGetAllUserAction(page + 1, pageSize));
      updatedUserStatus.data = null;
    }
  }, [updatedUserStatus]);

  useEffect(() => {
    if (deletedUser?.data?.success) {
      dispatch(adminGetAllUserAction(page + 1, pageSize));
      setDeleteModal(false);
      setUserID(null);
      deletedUser.data = null;
    }
  }, [deletedUser]);

  const editUser = (row) => {
    setErrors({});
    setUserFormData({
      _id: row?._id,
      first_name: row?.first_name,
      last_name: row?.last_name,
      email: row?.email,
      password: null,
      mobile: row?.mobile.toString(),
      role: row?.role,
    });
    setOpenModal(true);
  }

  const updateUser = () => {
    dispatch(adminUpdateUserAction(userFormData))
  }

  const updateUserStatus = (data) => {
    {
      adminPermission && (adminPermission.includes('Edit Admin') || adminPermission.includes('All')) &&
        dispatch(adminUpdateUserStatusAction(data))
    }
  }

  const save = () => {
    let validationResult;
    if (userFormData?._id && userFormData?._id !== '' && userFormData?._id !== null) {
      validationResult = updateSchema.validate(userFormData, { abortEarly: false });
    } else {
      validationResult = addSchema.validate(userFormData, { abortEarly: false });
    }
    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
    } else {
      setErrors({});
      if (userFormData?._id && userFormData?._id !== '' && userFormData?._id !== null) {
        updateUser()
      } else {
        createUser()
      }
    }
  }

  const deleteUser = (userID) => {
    dispatch(adminDeleteUserAction({ id: userID }));
  }

  const [errors, setErrors] = useState({});
  const passwordComplexity = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const addSchema = Joi.object({
    first_name: Joi.string().required().label('First Name'),
    last_name: Joi.string().required().label('Last Name'),
    email: Joi.string().email({ tlds: { allow: false } }).required().label('Email'),
    password: Joi.string()
      .required()
      .regex(passwordComplexity)
      .messages({
        'string.pattern.base': 'Password must be contain at least one lowercase letter, one uppercase letter, one digit, and one special character from @$!%*?&',
      })
      .min(8)
      .label('Password'),
    mobile: Joi.string()
      .required()
      .pattern(/^\d{10}$/)
      .message('Mobile number must be a 10-digit number without any spaces or special characters')
      .label('Mobile'),
    role: Joi.string().required().label('Role'),
  });

  const updateSchema = Joi.object({
    _id: Joi.string().required().label('_id'),
    first_name: Joi.string().required().label('First Name'),
    last_name: Joi.string().required().label('Last Name'),
    email: Joi.string().email({ tlds: { allow: false } }).required().label('Email'),
    password: Joi.string().allow(null, '')
      .required()
      .regex(passwordComplexity)
      .messages({
        'string.pattern.base': 'Password must be contain at least one lowercase letter, one uppercase letter, one digit, and one special character from @$!%*?&',
      })
      .min(8)
      .label('Password'),
    mobile: Joi.string()
      .required()
      .pattern(/^\d{10}$/)
      .message('Mobile number must be a 10-digit number without any spaces or special characters')
      .label('Mobile'),
    role: Joi.string().required().label('Role'),
  });

  return (
    <div>
      <Card className='pageCover'>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant='h5' color="textSecondary" className="flexSpacer">
              Admin Users
            </Typography>
            {adminPermission && (adminPermission.includes('Add Admin') || adminPermission.includes('All')) &&
              <Tooltip title={'Add New User'} placement="left">
                <Button color='default' variant='outlined' onClick={() => { resetForm(); setOpenModal(true); }}>+ Add New</Button>
              </Tooltip>
            }
          </Toolbar>
        </AppBar>
        <Paper>
          <TableContainer className='Custom_Datatable'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>First Name</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Last name</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>E-Mail</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Mobile</TableCell>
                  <TableCell style={{ minWidth: '125px' }}>Role</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell style={{ minWidth: '150px' }} align='right'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users && users.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                      <TableCell>{row?.first_name}</TableCell>
                      <TableCell>{row?.last_name}</TableCell>
                      <TableCell>{row?.email}</TableCell>
                      <TableCell>{row?.mobile}</TableCell>
                      <TableCell>{row?.roleData?.[0] && row?.roleData?.[0]?.role_name}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row?.status}
                              onChange={(e) => updateUserStatus({ _id: row?._id, status: e.target.checked })}
                              color="primary"
                              disabled={!adminPermission || (!adminPermission.includes('Edit Admin') && !adminPermission.includes('All'))}
                            />
                          }
                          label={row?.status ? 'Active' : 'Inactive'}
                        />
                      </TableCell>
                      <TableCell align='right'>
                        {adminPermission && (adminPermission.includes('Edit Admin') || adminPermission.includes('All')) &&
                          <Tooltip title={'Edit User'} placement="left">
                            <IconButton onClick={() => editUser(row)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        }
                        {adminPermission && (adminPermission.includes('Delete Admin') || adminPermission.includes('All')) &&
                          <Tooltip title={'Delete User'} placement="left">
                            <IconButton onClick={() => { setUserID(row?._id); setDeleteModal(true) }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            onChangePage={(e, page) => handlePageChange(page)}
            onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
          />
        </Paper>
      </Card>

      {/* user form dialog */}
      <Dialog
        fullScreen
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => setOpenModal(false)} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              {userFormData?._id ? 'Edit User' : 'Create User'}
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }} onClick={() => save()}>
              {userFormData?._id ? 'Update' : 'Save'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <Box>
            <Grid container style={{ maxWidth: '450px', paddingTop: '25px' }}>
              <Grid item xs={12} sm={12} md={6} xl={6} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='first_name'
                  label="First Name"
                  variant="outlined"
                  size="small"
                  value={userFormData?.first_name}
                  error={errors?.first_name}
                  helperText={errors?.first_name}
                  onChange={(e) => userDataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='last_name'
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  value={userFormData?.last_name}
                  error={errors?.last_name}
                  helperText={errors?.last_name}
                  onChange={(e) => userDataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='email'
                  label="E-Mail"
                  variant="outlined"
                  size="small"
                  type='email'
                  value={userFormData?.email}
                  error={errors?.email}
                  helperText={errors?.email}
                  onChange={(e) => userDataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='password'
                  label="Password"
                  variant="outlined"
                  size="small"
                  type='password'
                  value={userFormData?.password}
                  error={errors?.password}
                  helperText={errors?.password}
                  onChange={(e) => userDataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='mobile'
                  label="Mobile"
                  variant="outlined"
                  size="small"
                  type='number'
                  value={userFormData?.mobile}
                  error={errors?.mobile}
                  helperText={errors?.mobile}
                  onChange={(e) => handleMobileChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <FormControl variant="outlined" size="small" error={errors?.role} required style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
                  <Select
                    name='role'
                    label="Role"
                    value={userFormData?.role}
                    onChange={(e) => userDataHandleChange(e)}
                  >
                    {roles && roles.map((rol) => {
                      return <MenuItem value={rol?._id}>{rol?.role_name}</MenuItem>
                    })}
                  </Select>
                  <FormHelperText>{errors?.role}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      {/* user delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }} onClick={() => deleteUser(userID)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default AdminUsers
