import { getAllCardOffersLoading, getAllCardOffersSuccess, getAllCardOffersError, createCardOfferLoading, createCardOfferSuccess, createCardOfferError, updateCardOfferLoading, updateCardOfferSuccess, updateCardOfferError, deleteCardOfferLoading, deleteCardOfferSuccess, deleteCardOfferError, updateCardOfferStatusLoading, updateCardOfferStatusSuccess, updateCardOfferStatusError, searchCardOfferLoading, searchCardOfferSuccess, searchCardOfferError, exportAllCsvCardOffersLoading, exportAllCsvCardOffersSuccess, exportAllCsvCardOffersError, importAllCsvCardOffersLoading, importAllCsvCardOffersSuccess, importAllCsvCardOffersError, searchCountriesLoading, searchCountriesSuccess, searchCountriesError, searchStatesLoading, searchStatesSuccess, searchStatesError, searchCitiesLoading, searchCitiesSuccess, searchCitiesError, checkAllCsvCardOffersLoading, checkAllCsvCardOffersSuccess, checkAllCsvCardOffersError } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllCardOffersAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllCardOffersLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&provider_id=${filter?.provider_id}&card_id=${filter?.card_id}&title=${filter?.title}&type=${filter?.type}&status=${filter?.status}&created_by=${filter?.created_by}`;
      const response = await api.get(`card-offer/get/all?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllCardOffersSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllCardOffersError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const getAllCsvCardOffersAction = (filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(exportAllCsvCardOffersLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&provider_id=${filter?.provider_id}&name=${filter?.name}&type=${filter?.type}&status=${filter?.status}&created_by=${filter?.created_by}`;
      const response = await api.get(`card-offer/export/csv?${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(exportAllCsvCardOffersSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(exportAllCsvCardOffersError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const checkCsvCardOfferAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(checkAllCsvCardOffersLoading());
      const response = await api.post('card-offer/check/csv', postData);
      const data = response.data;
      dispatch(checkAllCsvCardOffersSuccess(data));
      if (data?.status && data?.response_code === 201) {
        toast.success(data?.message);
      }
      if (data?.status && data?.response_code === 200) {
        toast.error(data?.message);
      }
    } catch (error) {
      dispatch(checkAllCsvCardOffersError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const importCsvCardOfferAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(importAllCsvCardOffersLoading());
      const response = await api.post('card-offer/import/csv', postData);
      const data = response.data;
      dispatch(importAllCsvCardOffersSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(importAllCsvCardOffersError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const createCardOfferAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(createCardOfferLoading());
      const response = await api.post('card-offer/create', postData);
      const data = response.data;
      dispatch(createCardOfferSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(createCardOfferError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateCardOfferAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateCardOfferLoading());
      const response = await api.post('card-offer/update', postData);
      const data = response.data;
      dispatch(updateCardOfferSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateCardOfferError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateCardOfferStatusAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateCardOfferStatusLoading());
      const response = await api.post('card-offer/update/status', postData);
      const data = response.data;
      dispatch(updateCardOfferStatusSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateCardOfferStatusError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const deleteCardOfferAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(deleteCardOfferLoading());
      const response = await api.post('card-offer/delete', postData);
      const data = response.data;
      dispatch(deleteCardOfferSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(deleteCardOfferError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const searchCardOfferAction = (qry) => {
  return async (dispatch) => {
    try {
      dispatch(searchCardOfferLoading());
      const response = await api.get(`card-offer/search?search_query=${qry}`);
      const data = response.data;
      dispatch(searchCardOfferSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      dispatch(searchCardOfferError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const searchCountriesAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(searchCountriesLoading());
      const response = await api.post(`card-offer/search/countries`, postData);
      const data = response.data;
      dispatch(searchCountriesSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      dispatch(searchCountriesError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const searchStatesAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(searchStatesLoading());
      const response = await api.post(`card-offer/search/states`, postData);
      const data = response.data;
      dispatch(searchStatesSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      dispatch(searchStatesError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const searchCitiesAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(searchCitiesLoading());
      const response = await api.post(`card-offer/search/cities`, postData);
      const data = response.data;
      dispatch(searchCitiesSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      dispatch(searchCitiesError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllCardOffersAction,
  getAllCsvCardOffersAction,
  checkCsvCardOfferAction,
  importCsvCardOfferAction,
  createCardOfferAction,
  updateCardOfferAction,
  updateCardOfferStatusAction,
  deleteCardOfferAction,
  searchCardOfferAction,
  searchCountriesAction,
  searchStatesAction,
  searchCitiesAction
};
