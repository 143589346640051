export const GET_ALL_CARD_NETWORKS_LOADING = 'GET_ALL_CARD_NETWORKS_LOADING';
export const GET_ALL_CARD_NETWORKS_SUCCESS = 'GET_ALL_CARD_NETWORKS_SUCCESS';
export const GET_ALL_CARD_NETWORKS_ERROR = 'GET_ALL_CARD_NETWORKS_ERROR';

export const EXPORT_ALL_CSV_CARD_NETWORKS_LOADING = 'EXPORT_ALL_CSV_CARD_NETWORKS_LOADING';
export const EXPORT_ALL_CSV_CARD_NETWORKS_SUCCESS = 'EXPORT_ALL_CSV_CARD_NETWORKS_SUCCESS';
export const EXPORT_ALL_CSV_CARD_NETWORKS_ERROR = 'EXPORT_ALL_CSV_CARD_NETWORKS_ERROR';

export const IMPORT_ALL_CSV_CARD_NETWORKS_LOADING = 'IMPORT_ALL_CSV_CARD_NETWORKS_LOADING';
export const IMPORT_ALL_CSV_CARD_NETWORKS_SUCCESS = 'IMPORT_ALL_CSV_CARD_NETWORKS_SUCCESS';
export const IMPORT_ALL_CSV_CARD_NETWORKS_ERROR = 'IMPORT_ALL_CSV_CARD_NETWORKS_ERROR';

export const CREATE_CARD_NETWORK_LOADING = 'CREATE_CARD_NETWORK_LOADING';
export const CREATE_CARD_NETWORK_SUCCESS = 'CREATE_CARD_NETWORK_SUCCESS';
export const CREATE_CARD_NETWORK_ERROR = 'CREATE_CARD_NETWORK_ERROR';

export const UPDATE_CARD_NETWORK_LOADING = 'UPDATE_CARD_NETWORK_LOADING';
export const UPDATE_CARD_NETWORK_SUCCESS = 'UPDATE_CARD_NETWORK_SUCCESS';
export const UPDATE_CARD_NETWORK_ERROR = 'UPDATE_CARD_NETWORK_ERROR';

export const UPDATE_CARD_NETWORK_STATUS_LOADING = 'UPDATE_CARD_NETWORK_STATUS_LOADING';
export const UPDATE_CARD_NETWORK_STATUS_SUCCESS = 'UPDATE_CARD_NETWORK_STATUS_SUCCESS';
export const UPDATE_CARD_NETWORK_STATUS_ERROR = 'UPDATE_CARD_NETWORK_STATUS_ERROR';

export const DELETE_CARD_NETWORK_LOADING = 'DELETE_CARD_NETWORK_LOADING';
export const DELETE_CARD_NETWORK_SUCCESS = 'DELETE_CARD_NETWORK_SUCCESS';
export const DELETE_CARD_NETWORK_ERROR = 'DELETE_CARD_NETWORK_ERROR';

export const SEARCH_CARD_NETWORK_LOADING = 'SEARCH_CARD_NETWORK_LOADING';
export const SEARCH_CARD_NETWORK_SUCCESS = 'SEARCH_CARD_NETWORK_SUCCESS';
export const SEARCH_CARD_NETWORK_ERROR = 'SEARCH_CARD_NETWORK_ERROR';
