import { CREATE_PROVIDER_ERROR, CREATE_PROVIDER_LOADING, CREATE_PROVIDER_SUCCESS, DELETE_PROVIDER_ERROR, DELETE_PROVIDER_LOADING, DELETE_PROVIDER_SUCCESS, EXPORT_ALL_CSV_PROVIDERS_ERROR, EXPORT_ALL_CSV_PROVIDERS_LOADING, EXPORT_ALL_CSV_PROVIDERS_SUCCESS, GET_ALL_PROVIDERS_ERROR, GET_ALL_PROVIDERS_LOADING, GET_ALL_PROVIDERS_SUCCESS, IMPORT_ALL_CSV_PROVIDERS_ERROR, IMPORT_ALL_CSV_PROVIDERS_LOADING, IMPORT_ALL_CSV_PROVIDERS_SUCCESS, SEARCH_PROVIDER_ERROR, SEARCH_PROVIDER_LOADING, SEARCH_PROVIDER_SUCCESS, UPDATE_PROVIDER_ERROR, UPDATE_PROVIDER_LOADING, UPDATE_PROVIDER_STATUS_ERROR, UPDATE_PROVIDER_STATUS_LOADING, UPDATE_PROVIDER_STATUS_SUCCESS, UPDATE_PROVIDER_SUCCESS } from "./actionTypes";

const initialState = {
    getAllProviders: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvProviders: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvImportProviders: {
        loading: false,
        data: null,
        error: null
    },
    createProvider: {
        loading: false,
        data: null,
        error: null
    },
    updateProvider: {
        loading: false,
        data: null,
        error: null
    },
    updateProviderStatus: {
        loading: false,
        data: null,
        error: null
    },
    deleteProvider: {
        loading: false,
        data: null,
        error: null
    },
    searchProvider: {
        loading: false,
        data: null,
        error: null
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PROVIDERS_LOADING:
            return { ...state, getAllProviders: { ...state.getAllProviders, loading: true, data: null, error: null } };
        case GET_ALL_PROVIDERS_SUCCESS:
            return { ...state, getAllProviders: { ...state.getAllProviders, loading: false, data: action.payload, error: null } };
        case GET_ALL_PROVIDERS_ERROR:
            return { ...state, getAllProviders: { ...state.getAllProviders, loading: false, data: null, error: action.payload } };

        case EXPORT_ALL_CSV_PROVIDERS_LOADING:
            return { ...state, getAllCsvProviders: { ...state.getAllCsvProviders, loading: true, data: null, error: null } };
        case EXPORT_ALL_CSV_PROVIDERS_SUCCESS:
            return { ...state, getAllCsvProviders: { ...state.getAllCsvProviders, loading: false, data: action.payload, error: null } };
        case EXPORT_ALL_CSV_PROVIDERS_ERROR:
            return { ...state, getAllCsvProviders: { ...state.getAllCsvProviders, loading: false, data: null, error: action.payload } };

        case IMPORT_ALL_CSV_PROVIDERS_LOADING:
            return { ...state, getAllCsvImportProviders: { ...state.getAllCsvImportProviders, loading: true, data: null, error: null } };
        case IMPORT_ALL_CSV_PROVIDERS_SUCCESS:
            return { ...state, getAllCsvImportProviders: { ...state.getAllCsvImportProviders, loading: false, data: action.payload, error: null } };
        case IMPORT_ALL_CSV_PROVIDERS_ERROR:
            return { ...state, getAllCsvImportProviders: { ...state.getAllCsvImportProviders, loading: false, data: null, error: action.payload } };

        case CREATE_PROVIDER_LOADING:
            return { ...state, createProvider: { ...state.createProvider, loading: true, data: null, error: null } };
        case CREATE_PROVIDER_SUCCESS:
            return { ...state, createProvider: { ...state.createProvider, loading: false, data: action.payload, error: null } };
        case CREATE_PROVIDER_ERROR:
            return { ...state, createProvider: { ...state.createProvider, loading: false, data: null, error: action.payload } };

        case UPDATE_PROVIDER_LOADING:
            return { ...state, updateProvider: { ...state.updateProvider, loading: true, data: null, error: null } };
        case UPDATE_PROVIDER_SUCCESS:
            return { ...state, updateProvider: { ...state.updateProvider, loading: false, data: action.payload, error: null } };
        case UPDATE_PROVIDER_ERROR:
            return { ...state, updateProvider: { ...state.updateProvider, loading: false, data: null, error: action.payload } };

        case UPDATE_PROVIDER_STATUS_LOADING:
            return { ...state, updateProviderStatus: { ...state.updateProviderStatus, loading: true, data: null, error: null } };
        case UPDATE_PROVIDER_STATUS_SUCCESS:
            return { ...state, updateProviderStatus: { ...state.updateProviderStatus, loading: false, data: action.payload, error: null } };
        case UPDATE_PROVIDER_STATUS_ERROR:
            return { ...state, updateProviderStatus: { ...state.updateProviderStatus, loading: false, data: null, error: action.payload } };

        case DELETE_PROVIDER_LOADING:
            return { ...state, deleteProvider: { ...state.deleteProvider, loading: true, data: null, error: null } };
        case DELETE_PROVIDER_SUCCESS:
            return { ...state, deleteProvider: { ...state.deleteProvider, loading: false, data: action.payload, error: null } };
        case DELETE_PROVIDER_ERROR:
            return { ...state, deleteProvider: { ...state.deleteProvider, loading: false, data: null, error: action.payload } };

        case SEARCH_PROVIDER_LOADING:
            return { ...state, searchProvider: { ...state.searchProvider, loading: true, data: null, error: null } };
        case SEARCH_PROVIDER_SUCCESS:
            return { ...state, searchProvider: { ...state.searchProvider, loading: false, data: action.payload, error: null } };
        case SEARCH_PROVIDER_ERROR:
            return { ...state, searchProvider: { ...state.searchProvider, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
