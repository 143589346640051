import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import moment from 'moment';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Avatar, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Grow, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Slide, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import { deleteUserAction, getUserListAction, updateUserStatusAction } from '../../store/user/asyncActions';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { getUserWalletAction } from '../../store/withdraw-request/asyncActions';
import { creditDebitAmountAction, getUserWalletLedgerAction } from '../../store/wallet/asyncActions';
import { getGeneratedRefByUserAction, getReceivedRefByUserAction } from '../../store/referral/asyncActions';
import { getAllPermissionAction } from '../../store/role/asyncActions';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

function Users() {
  const classes = useStyles();
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, setID] = useState(null);
  const [rowData, setRowData] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();

  const userListData = useSelector((state) => state?.user?.allUsers);
  const updateUserStatusData = useSelector((state) => state?.user?.updateUserStatus);
  const deleteUserData = useSelector((state) => state?.user?.deleteUser);

  const [userList, setUserList] = useState(null);

  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermission, setAdminPermission] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermission(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    dispatch(getUserListAction(page + 1, pageSize, filter));
  }, [page, pageSize]);

  useEffect(() => {
    document.title = 'Users';
  }, []);

  useEffect(() => {
    if (userListData?.data?.status && Array.isArray(userListData?.data?.data?.records)) {
      setUserList(userListData?.data?.data?.records);
      setTotalCount(userListData?.data?.data?.totalCount);
    }
  }, [userListData]);

  useEffect(() => {
    if (updateUserStatusData?.data?.status) {
      dispatch(getUserListAction(page + 1, pageSize, filter));
      updateUserStatusData.data = null;
    }
  }, [updateUserStatusData]);


  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    name: '',
    mobile: '',
    status: ''
  });

  const filterHandleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  }

  const filterApply = () => {
    dispatch(getUserListAction(page + 1, pageSize, filter));
  }

  const filterClear = () => {
    const reset = {
      from: '',
      to: '',
      name: '',
      mobile: '',
      status: ''
    }
    setFilter(reset);
    dispatch(getUserListAction(page + 1, pageSize, reset));
  }

  const updateUserStatus = (data) => {
    {
      adminPermission && (adminPermission.includes('Edit User') || adminPermission.includes('All')) &&
        dispatch(updateUserStatusAction(data));
    }
  }

  const [walletFormData, setWalletFormData] = useState({
    user_id: '',
    description: '',
    amount: 0,
    type: 'credited'
  });

  const [errors, setErrors] = useState({});
  const validationSchema = Joi.object({
    user_id: Joi.string().required(),
    // description: Joi.string().allow(null, ''),
    description: Joi.string().required(),
    amount: Joi.number().min(1).required(),
    type: Joi.string().valid('credited', 'debited').required()
  });

  const save = () => {
    const validationResult = validationSchema.validate(walletFormData, { abortEarly: false });
    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
    } else {
      setErrors({});
      dispatch(creditDebitAmountAction(walletFormData));
    }
  }

  const deleteUser = () => {
    dispatch(deleteUserAction({ id: ID }));
  }

  useEffect(() => {
    if (deleteUserData?.data?.status) {
      dispatch(getUserListAction(page + 1, pageSize, filter));
      deleteUserData.data = null;
      setDeleteModal(false);
      setID(null);
    }
  }, [deleteUserData]);

  return (
    <div>
      <Card className='pageCover'>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant='h5' color="textSecondary" className="flexSpacer">
              Users
            </Typography>
            {/* <Tooltip title={'Add New Category'} placement="left">
              <Button color='default' variant='outlined' onClick={() => { resetForm(); setOpenModal(true); }}>+ Add New</Button>
            </Tooltip> */}
            <Tooltip title={'Apply Filter On Data'} placement="left">
              <Button color='default' variant='outlined' className='ml-1' onClick={() => setOpenFilter(!openFilter)}>
                <span className='mr-1'>Filter</span> {openFilter ? <ExpandLessRoundedIcon className='btnIco' /> : <ExpandMoreRoundedIcon className='btnIco' />}
              </Button>
            </Tooltip>
          </Toolbar>
        </AppBar>
        {/* {openFilter && */}
        <Box className={`filterOuter ${openFilter && 'activeOpen'}`}>
          <Grid container style={{ width: '100%', paddingTop: '25px' }}>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                variant="outlined"
                label="From Date"
                type="date"
                name='from'
                className='w-100'
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                value={filter?.from}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                variant="outlined"
                label="To Date"
                type="date"
                name='to'
                className='w-100'
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: filter?.from, // This is the minimum date value you want to set
                  },
                }}
                value={filter?.to}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                required
                name='name'
                label="Name"
                variant="outlined"
                size="small"
                value={filter?.name}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                required
                type='number'
                name='mobile'
                label="Mobile"
                variant="outlined"
                size="small"
                value={filter?.mobile}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                <Select
                  name='status'
                  label="Status"
                  value={filter?.status}
                  onChange={(e) => filterHandleChange(e)}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='primary' variant='contained' onClick={() => filterApply()}>Apply</Button>
              <Button color='default' variant='outlined' className='ml-1' onClick={() => filterClear()}>Clear</Button>
            </Grid>
          </Grid>
        </Box>
        {/* } */}
        <Paper>
          <TableContainer className='Custom_Datatable'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>E-Mail</TableCell>
                  <TableCell>Create Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align='right'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userList && userList.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                      <TableCell style={{ minWidth: '150px' }}>{row?.name}</TableCell>
                      <TableCell style={{ minWidth: '150px' }}>{row?.mobile}</TableCell>
                      <TableCell style={{ minWidth: '150px' }}>{row?.email}</TableCell>
                      <TableCell style={{ minWidth: '150px' }}>
                        {moment(rowData?.createdAt).format('DD-MM-YYYY hh:mmA')}
                      </TableCell>
                      <TableCell style={{ minWidth: '100px' }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row?.status}
                              onChange={(e) => updateUserStatus({ id: row?._id, status: e.target.checked })}
                              color="primary"
                              disabled={!adminPermission || (!adminPermission.includes('Edit User') && !adminPermission.includes('All'))}
                            />
                          }
                          label={row?.status ? 'Active' : 'Inactive'}
                        />
                      </TableCell>
                      <TableCell style={{ minWidth: '175px' }} align='right'>
                        {/* <Tooltip title={'Edit'} placement="left">
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Tooltip> */}
                        {adminPermission && (adminPermission.includes('Delete User') || adminPermission.includes('All')) &&
                          <Tooltip title={'Delete'} placement="left">
                            <IconButton onClick={() => { setID(row?._id); setDeleteModal(true) }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            onChangePage={(e, page) => handlePageChange(page)}
            onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
          />
        </Paper>
      </Card>

      {/* delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }} onClick={() => deleteUser()}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Users
