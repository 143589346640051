export const ADMIN_LOGIN_LOADING = 'ADMIN_LOGIN_LOADING';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_ERROR = 'ADMIN_LOGIN_ERROR';

export const GET_ADMIN_BY_TOKEN_LOADING = 'GET_ADMIN_BY_TOKEN_LOADING';
export const GET_ADMIN_BY_TOKEN_SUCCESS = 'GET_ADMIN_BY_TOKEN_SUCCESS';
export const GET_ADMIN_BY_TOKEN_ERROR = 'GET_ADMIN_BY_TOKEN_ERROR';

export const ADMIN_GET_ALL_USERS_LOADING = 'ADMIN_GET_ALL_USERS_LOADING';
export const ADMIN_GET_ALL_USERS_SUCCESS = 'ADMIN_GET_ALL_USERS_SUCCESS';
export const ADMIN_GET_ALL_USERS_ERROR = 'ADMIN_GET_ALL_USERS_ERROR';

export const ADMIN_CREATE_USER_LOADING = 'ADMIN_CREATE_USER_LOADING';
export const ADMIN_CREATE_USER_SUCCESS = 'ADMIN_CREATE_USER_SUCCESS';
export const ADMIN_CREATE_USER_ERROR = 'ADMIN_CREATE_USER_ERROR';

export const ADMIN_UPDATE_USER_LOADING = 'ADMIN_UPDATE_USER_LOADING';
export const ADMIN_UPDATE_USER_SUCCESS = 'ADMIN_UPDATE_USER_SUCCESS';
export const ADMIN_UPDATE_USER_ERROR = 'ADMIN_UPDATE_USER_ERROR';

export const ADMIN_UPDATE_USER_PROFILE_LOADING = 'ADMIN_UPDATE_USER_PROFILE_LOADING';
export const ADMIN_UPDATE_USER_PROFILE_SUCCESS = 'ADMIN_UPDATE_USER_PROFILE_SUCCESS';
export const ADMIN_UPDATE_USER_PROFILE_ERROR = 'ADMIN_UPDATE_USER_PROFILE_ERROR';

export const ADMIN_UPDATE_USER_STATUS_LOADING = 'ADMIN_UPDATE_USER_STATUS_LOADING';
export const ADMIN_UPDATE_USER_STATUS_SUCCESS = 'ADMIN_UPDATE_USER_STATUS_SUCCESS';
export const ADMIN_UPDATE_USER_STATUS_ERROR = 'ADMIN_UPDATE_USER_STATUS_ERROR';

export const ADMIN_DELETE_USER_LOADING = 'ADMIN_DELETE_USER_LOADING';
export const ADMIN_DELETE_USER_SUCCESS = 'ADMIN_DELETE_USER_SUCCESS';
export const ADMIN_DELETE_USER_ERROR = 'ADMIN_DELETE_USER_ERROR';

export const ADMIN_USER_SEARCH_LOADING = 'ADMIN_USER_SEARCH_LOADING';
export const ADMIN_USER_SEARCH_SUCCESS = 'ADMIN_USER_SEARCH_SUCCESS';
export const ADMIN_USER_SEARCH_ERROR = 'ADMIN_USER_SEARCH_ERROR';

export const CREATE_META_JOSN_LOADING = 'CREATE_META_JOSN_LOADING';
export const CREATE_META_JOSN_SUCCESS = 'CREATE_META_JOSN_SUCCESS';
export const CREATE_META_JOSN_ERROR = 'CREATE_META_JOSN_ERROR';
