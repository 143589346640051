import { CREATE_NEWS_LETTER_ERROR, CREATE_NEWS_LETTER_LOADING, CREATE_NEWS_LETTER_SUCCESS, DELETE_NEWS_LETTER_ERROR, DELETE_NEWS_LETTER_LOADING, DELETE_NEWS_LETTER_SUCCESS, EXPORT_ALL_CSV_NEWS_LETTERS_ERROR, EXPORT_ALL_CSV_NEWS_LETTERS_LOADING, EXPORT_ALL_CSV_NEWS_LETTERS_SUCCESS, GET_ALL_NEWS_LETTERS_ERROR, GET_ALL_NEWS_LETTERS_LOADING, GET_ALL_NEWS_LETTERS_SUCCESS, IMPORT_ALL_CSV_NEWS_LETTERS_ERROR, IMPORT_ALL_CSV_NEWS_LETTERS_LOADING, IMPORT_ALL_CSV_NEWS_LETTERS_SUCCESS, SEARCH_NEWS_LETTER_ERROR, SEARCH_NEWS_LETTER_LOADING, SEARCH_NEWS_LETTER_SUCCESS, UPDATE_NEWS_LETTER_ERROR, UPDATE_NEWS_LETTER_LOADING, UPDATE_NEWS_LETTER_STATUS_ERROR, UPDATE_NEWS_LETTER_STATUS_LOADING, UPDATE_NEWS_LETTER_STATUS_SUCCESS, UPDATE_NEWS_LETTER_SUCCESS } from "./actionTypes";

const initialState = {
    getAllNewsLetters: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvNewsLetters: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvImportNewsLetters: {
        loading: false,
        data: null,
        error: null
    },
    createNewsLetter: {
        loading: false,
        data: null,
        error: null
    },
    updateNewsLetter: {
        loading: false,
        data: null,
        error: null
    },
    updateNewsLetterStatus: {
        loading: false,
        data: null,
        error: null
    },
    deleteNewsLetter: {
        loading: false,
        data: null,
        error: null
    },
    searchNewsLetter: {
        loading: false,
        data: null,
        error: null
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_NEWS_LETTERS_LOADING:
            return { ...state, getAllNewsLetters: { ...state.getAllNewsLetters, loading: true, data: null, error: null } };
        case GET_ALL_NEWS_LETTERS_SUCCESS:
            return { ...state, getAllNewsLetters: { ...state.getAllNewsLetters, loading: false, data: action.payload, error: null } };
        case GET_ALL_NEWS_LETTERS_ERROR:
            return { ...state, getAllNewsLetters: { ...state.getAllNewsLetters, loading: false, data: null, error: action.payload } };

        case EXPORT_ALL_CSV_NEWS_LETTERS_LOADING:
            return { ...state, getAllCsvNewsLetters: { ...state.getAllCsvNewsLetters, loading: true, data: null, error: null } };
        case EXPORT_ALL_CSV_NEWS_LETTERS_SUCCESS:
            return { ...state, getAllCsvNewsLetters: { ...state.getAllCsvNewsLetters, loading: false, data: action.payload, error: null } };
        case EXPORT_ALL_CSV_NEWS_LETTERS_ERROR:
            return { ...state, getAllCsvNewsLetters: { ...state.getAllCsvNewsLetters, loading: false, data: null, error: action.payload } };

        case IMPORT_ALL_CSV_NEWS_LETTERS_LOADING:
            return { ...state, getAllCsvImportNewsLetters: { ...state.getAllCsvImportNewsLetters, loading: true, data: null, error: null } };
        case IMPORT_ALL_CSV_NEWS_LETTERS_SUCCESS:
            return { ...state, getAllCsvImportNewsLetters: { ...state.getAllCsvImportNewsLetters, loading: false, data: action.payload, error: null } };
        case IMPORT_ALL_CSV_NEWS_LETTERS_ERROR:
            return { ...state, getAllCsvImportNewsLetters: { ...state.getAllCsvImportNewsLetters, loading: false, data: null, error: action.payload } };

        case CREATE_NEWS_LETTER_LOADING:
            return { ...state, createNewsLetter: { ...state.createNewsLetter, loading: true, data: null, error: null } };
        case CREATE_NEWS_LETTER_SUCCESS:
            return { ...state, createNewsLetter: { ...state.createNewsLetter, loading: false, data: action.payload, error: null } };
        case CREATE_NEWS_LETTER_ERROR:
            return { ...state, createNewsLetter: { ...state.createNewsLetter, loading: false, data: null, error: action.payload } };

        case UPDATE_NEWS_LETTER_LOADING:
            return { ...state, updateNewsLetter: { ...state.updateNewsLetter, loading: true, data: null, error: null } };
        case UPDATE_NEWS_LETTER_SUCCESS:
            return { ...state, updateNewsLetter: { ...state.updateNewsLetter, loading: false, data: action.payload, error: null } };
        case UPDATE_NEWS_LETTER_ERROR:
            return { ...state, updateNewsLetter: { ...state.updateNewsLetter, loading: false, data: null, error: action.payload } };

        case UPDATE_NEWS_LETTER_STATUS_LOADING:
            return { ...state, updateNewsLetterStatus: { ...state.updateNewsLetterStatus, loading: true, data: null, error: null } };
        case UPDATE_NEWS_LETTER_STATUS_SUCCESS:
            return { ...state, updateNewsLetterStatus: { ...state.updateNewsLetterStatus, loading: false, data: action.payload, error: null } };
        case UPDATE_NEWS_LETTER_STATUS_ERROR:
            return { ...state, updateNewsLetterStatus: { ...state.updateNewsLetterStatus, loading: false, data: null, error: action.payload } };

        case DELETE_NEWS_LETTER_LOADING:
            return { ...state, deleteNewsLetter: { ...state.deleteNewsLetter, loading: true, data: null, error: null } };
        case DELETE_NEWS_LETTER_SUCCESS:
            return { ...state, deleteNewsLetter: { ...state.deleteNewsLetter, loading: false, data: action.payload, error: null } };
        case DELETE_NEWS_LETTER_ERROR:
            return { ...state, deleteNewsLetter: { ...state.deleteNewsLetter, loading: false, data: null, error: action.payload } };

        case SEARCH_NEWS_LETTER_LOADING:
            return { ...state, searchNewsLetter: { ...state.searchNewsLetter, loading: true, data: null, error: null } };
        case SEARCH_NEWS_LETTER_SUCCESS:
            return { ...state, searchNewsLetter: { ...state.searchNewsLetter, loading: false, data: action.payload, error: null } };
        case SEARCH_NEWS_LETTER_ERROR:
            return { ...state, searchNewsLetter: { ...state.searchNewsLetter, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
