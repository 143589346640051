import { getAllProvidersLoading, getAllProvidersSuccess, getAllProvidersError, createProviderLoading, createProviderSuccess, createProviderError, updateProviderLoading, updateProviderSuccess, updateProviderError, deleteProviderLoading, deleteProviderSuccess, deleteProviderError, updateProviderStatusLoading, updateProviderStatusSuccess, updateProviderStatusError, searchProviderLoading, searchProviderSuccess, searchProviderError, exportAllCsvProvidersLoading, exportAllCsvProvidersSuccess, exportAllCsvProvidersError, importAllCsvProvidersLoading, importAllCsvProvidersSuccess, importAllCsvProvidersError } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllProvidersAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllProvidersLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&name=${filter?.name}&type_id=${filter?.type_id}&status=${filter?.status}&created_by=${filter?.created_by}`;
      const response = await api.get(`card-provider/get/all?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllProvidersSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllProvidersError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const getAllCsvProvidersAction = (filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(exportAllCsvProvidersLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&name=${filter?.name}&type_id=${filter?.type_id}&status=${filter?.status}&created_by=${filter?.created_by}`;
      const response = await api.get(`card-provider/export/csv?${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(exportAllCsvProvidersSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(exportAllCsvProvidersError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const importCsvProviderAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(importAllCsvProvidersLoading());
      const response = await api.post('card-provider/import/csv', postData);
      const data = response.data;
      dispatch(importAllCsvProvidersSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(importAllCsvProvidersError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const createProviderAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(createProviderLoading());
      const response = await api.post('card-provider/create', postData);
      const data = response.data;
      dispatch(createProviderSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(createProviderError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateProviderAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateProviderLoading());
      const response = await api.post('card-provider/update', postData);
      const data = response.data;
      dispatch(updateProviderSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateProviderError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateProviderStatusAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateProviderStatusLoading());
      const response = await api.post('card-provider/update/status', postData);
      const data = response.data;
      dispatch(updateProviderStatusSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateProviderStatusError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const deleteProviderAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(deleteProviderLoading());
      const response = await api.post('card-provider/delete', postData);
      const data = response.data;
      dispatch(deleteProviderSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(deleteProviderError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const searchProviderAction = (qry) => {
  return async (dispatch) => {
    try {
      dispatch(searchProviderLoading());
      const response = await api.get(`card-provider/search?search_query=${qry}`);
      const data = response.data;
      dispatch(searchProviderSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      dispatch(searchProviderError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllProvidersAction,
  getAllCsvProvidersAction,
  importCsvProviderAction,
  createProviderAction,
  updateProviderAction,
  updateProviderStatusAction,
  deleteProviderAction,
  searchProviderAction
};
