export const GET_ALL_PRODUCTS_LOADING = 'GET_ALL_PRODUCTS_LOADING';
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
export const GET_ALL_PRODUCTS_ERROR = 'GET_ALL_PRODUCTS_ERROR';

export const EXPORT_ALL_CSV_PRODUCTS_LOADING = 'EXPORT_ALL_CSV_PRODUCTS_LOADING';
export const EXPORT_ALL_CSV_PRODUCTS_SUCCESS = 'EXPORT_ALL_CSV_PRODUCTS_SUCCESS';
export const EXPORT_ALL_CSV_PRODUCTS_ERROR = 'EXPORT_ALL_CSV_PRODUCTS_ERROR';

export const IMPORT_ALL_CSV_PRODUCTS_LOADING = 'IMPORT_ALL_CSV_PRODUCTS_LOADING';
export const IMPORT_ALL_CSV_PRODUCTS_SUCCESS = 'IMPORT_ALL_CSV_PRODUCTS_SUCCESS';
export const IMPORT_ALL_CSV_PRODUCTS_ERROR = 'IMPORT_ALL_CSV_PRODUCTS_ERROR';

export const CREATE_PRODUCT_LOADING = 'CREATE_PRODUCT_LOADING';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR';

export const UPDATE_PRODUCT_LOADING = 'UPDATE_PRODUCT_LOADING';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';

export const UPDATE_PRODUCT_STATUS_LOADING = 'UPDATE_PRODUCT_STATUS_LOADING';
export const UPDATE_PRODUCT_STATUS_SUCCESS = 'UPDATE_PRODUCT_STATUS_SUCCESS';
export const UPDATE_PRODUCT_STATUS_ERROR = 'UPDATE_PRODUCT_STATUS_ERROR';

export const DELETE_PRODUCT_LOADING = 'DELETE_PRODUCT_LOADING';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR';

export const SEARCH_PRODUCT_LOADING = 'SEARCH_PRODUCT_LOADING';
export const SEARCH_PRODUCT_SUCCESS = 'SEARCH_PRODUCT_SUCCESS';
export const SEARCH_PRODUCT_ERROR = 'SEARCH_PRODUCT_ERROR';

export const SEARCH_PRODUCT_FROM_CAT_LOADING = 'SEARCH_PRODUCT_FROM_CAT_LOADING';
export const SEARCH_PRODUCT_FROM_CAT_SUCCESS = 'SEARCH_PRODUCT_FROM_CAT_SUCCESS';
export const SEARCH_PRODUCT_FROM_CAT_ERROR = 'SEARCH_PRODUCT_FROM_CAT_ERROR';