import { GET_ALL_PRODUCTS_LOADING, GET_ALL_PRODUCTS_SUCCESS, GET_ALL_PRODUCTS_ERROR, CREATE_PRODUCT_LOADING, CREATE_PRODUCT_SUCCESS, CREATE_PRODUCT_ERROR, UPDATE_PRODUCT_LOADING, UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_ERROR, DELETE_PRODUCT_LOADING, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_ERROR, UPDATE_PRODUCT_STATUS_LOADING, UPDATE_PRODUCT_STATUS_SUCCESS, UPDATE_PRODUCT_STATUS_ERROR, SEARCH_PRODUCT_LOADING, SEARCH_PRODUCT_SUCCESS, SEARCH_PRODUCT_ERROR, EXPORT_ALL_CSV_PRODUCTS_LOADING, EXPORT_ALL_CSV_PRODUCTS_SUCCESS, EXPORT_ALL_CSV_PRODUCTS_ERROR, IMPORT_ALL_CSV_PRODUCTS_LOADING, IMPORT_ALL_CSV_PRODUCTS_SUCCESS, IMPORT_ALL_CSV_PRODUCTS_ERROR, SEARCH_PRODUCT_FROM_CAT_LOADING, SEARCH_PRODUCT_FROM_CAT_SUCCESS, SEARCH_PRODUCT_FROM_CAT_ERROR } from './actionTypes';

// get all Products
const getAllProductsLoading = () => {
  return {
    type: GET_ALL_PRODUCTS_LOADING,
  };
};

const getAllProductsSuccess = (data) => {
  return {
    type: GET_ALL_PRODUCTS_SUCCESS,
    payload: data,
  };
};

const getAllProductsError = (error) => {
  return {
    type: GET_ALL_PRODUCTS_ERROR,
    payload: error,
  };
};

// export all csv Products
const exportAllCsvProductsLoading = () => {
  return {
    type: EXPORT_ALL_CSV_PRODUCTS_LOADING,
  };
};

const exportAllCsvProductsSuccess = (data) => {
  return {
    type: EXPORT_ALL_CSV_PRODUCTS_SUCCESS,
    payload: data,
  };
};

const exportAllCsvProductsError = (error) => {
  return {
    type: EXPORT_ALL_CSV_PRODUCTS_ERROR,
    payload: error,
  };
};

// import all csv Products
const importAllCsvProductsLoading = () => {
  return {
    type: IMPORT_ALL_CSV_PRODUCTS_LOADING,
  };
};

const importAllCsvProductsSuccess = (data) => {
  return {
    type: IMPORT_ALL_CSV_PRODUCTS_SUCCESS,
    payload: data,
  };
};

const importAllCsvProductsError = (error) => {
  return {
    type: IMPORT_ALL_CSV_PRODUCTS_ERROR,
    payload: error,
  };
};

// create product
const createProductLoading = () => {
  return {
    type: CREATE_PRODUCT_LOADING,
  };
};

const createProductSuccess = (data) => {
  return {
    type: CREATE_PRODUCT_SUCCESS,
    payload: data,
  };
};

const createProductError = (error) => {
  return {
    type: CREATE_PRODUCT_ERROR,
    payload: error,
  };
};

// update product
const updateProductLoading = () => {
  return {
    type: UPDATE_PRODUCT_LOADING,
  };
};

const updateProductSuccess = (data) => {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
    payload: data,
  };
};

const updateProductError = (error) => {
  return {
    type: UPDATE_PRODUCT_ERROR,
    payload: error,
  };
};

// update product status
const updateProductStatusLoading = () => {
  return {
    type: UPDATE_PRODUCT_STATUS_LOADING,
  };
};

const updateProductStatusSuccess = (data) => {
  return {
    type: UPDATE_PRODUCT_STATUS_SUCCESS,
    payload: data,
  };
};

const updateProductStatusError = (error) => {
  return {
    type: UPDATE_PRODUCT_STATUS_ERROR,
    payload: error,
  };
};

// delete product
const deleteProductLoading = () => {
  return {
    type: DELETE_PRODUCT_LOADING,
  };
};

const deleteProductSuccess = (data) => {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload: data,
  };
};

const deleteProductError = (error) => {
  return {
    type: DELETE_PRODUCT_ERROR,
    payload: error,
  };
};

// search product
const searchProductLoading = () => {
  return {
    type: SEARCH_PRODUCT_LOADING,
  };
};

const searchProductSuccess = (data) => {
  return {
    type: SEARCH_PRODUCT_SUCCESS,
    payload: data,
  };
};

const searchProductError = (error) => {
  return {
    type: SEARCH_PRODUCT_ERROR,
    payload: error,
  };
};

// search product from categories
const searchProductFromCatLoading = () => {
  return {
    type: SEARCH_PRODUCT_FROM_CAT_LOADING,
  };
};

const searchProductFromCatSuccess = (data) => {
  return {
    type: SEARCH_PRODUCT_FROM_CAT_SUCCESS,
    payload: data,
  };
};

const searchProductFromCatError = (error) => {
  return {
    type: SEARCH_PRODUCT_FROM_CAT_ERROR,
    payload: error,
  };
};

export { 
    getAllProductsLoading, 
    getAllProductsSuccess, 
    getAllProductsError,

    exportAllCsvProductsLoading, 
    exportAllCsvProductsSuccess, 
    exportAllCsvProductsError,

    importAllCsvProductsLoading, 
    importAllCsvProductsSuccess, 
    importAllCsvProductsError,

    createProductLoading, 
    createProductSuccess, 
    createProductError,

    updateProductLoading, 
    updateProductSuccess, 
    updateProductError,

    updateProductStatusLoading, 
    updateProductStatusSuccess, 
    updateProductStatusError,

    deleteProductLoading, 
    deleteProductSuccess, 
    deleteProductError,

    searchProductLoading, 
    searchProductSuccess, 
    searchProductError,

    searchProductFromCatLoading, 
    searchProductFromCatSuccess, 
    searchProductFromCatError
};
