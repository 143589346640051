import { GET_ALL_CARDS_LOADING, GET_ALL_CARDS_SUCCESS, GET_ALL_CARDS_ERROR, CREATE_CARD_LOADING, CREATE_CARD_SUCCESS, CREATE_CARD_ERROR, UPDATE_CARD_LOADING, UPDATE_CARD_SUCCESS, UPDATE_CARD_ERROR, DELETE_CARD_LOADING, DELETE_CARD_SUCCESS, DELETE_CARD_ERROR, UPDATE_CARD_STATUS_LOADING, UPDATE_CARD_STATUS_SUCCESS, UPDATE_CARD_STATUS_ERROR, SEARCH_CARD_LOADING, SEARCH_CARD_SUCCESS, SEARCH_CARD_ERROR, EXPORT_ALL_CSV_CARDS_LOADING, EXPORT_ALL_CSV_CARDS_SUCCESS, EXPORT_ALL_CSV_CARDS_ERROR, IMPORT_ALL_CSV_CARDS_LOADING, IMPORT_ALL_CSV_CARDS_SUCCESS, IMPORT_ALL_CSV_CARDS_ERROR } from './actionTypes';

// get all Cards
const getAllCardsLoading = () => {
  return {
    type: GET_ALL_CARDS_LOADING,
  };
};

const getAllCardsSuccess = (data) => {
  return {
    type: GET_ALL_CARDS_SUCCESS,
    payload: data,
  };
};

const getAllCardsError = (error) => {
  return {
    type: GET_ALL_CARDS_ERROR,
    payload: error,
  };
};

// export all csv Cards
const exportAllCsvCardsLoading = () => {
  return {
    type: EXPORT_ALL_CSV_CARDS_LOADING,
  };
};

const exportAllCsvCardsSuccess = (data) => {
  return {
    type: EXPORT_ALL_CSV_CARDS_SUCCESS,
    payload: data,
  };
};

const exportAllCsvCardsError = (error) => {
  return {
    type: EXPORT_ALL_CSV_CARDS_ERROR,
    payload: error,
  };
};

// import all csv Cards
const importAllCsvCardsLoading = () => {
  return {
    type: IMPORT_ALL_CSV_CARDS_LOADING,
  };
};

const importAllCsvCardsSuccess = (data) => {
  return {
    type: IMPORT_ALL_CSV_CARDS_SUCCESS,
    payload: data,
  };
};

const importAllCsvCardsError = (error) => {
  return {
    type: IMPORT_ALL_CSV_CARDS_ERROR,
    payload: error,
  };
};

// create card
const createCardLoading = () => {
  return {
    type: CREATE_CARD_LOADING,
  };
};

const createCardSuccess = (data) => {
  return {
    type: CREATE_CARD_SUCCESS,
    payload: data,
  };
};

const createCardError = (error) => {
  return {
    type: CREATE_CARD_ERROR,
    payload: error,
  };
};

// update card
const updateCardLoading = () => {
  return {
    type: UPDATE_CARD_LOADING,
  };
};

const updateCardSuccess = (data) => {
  return {
    type: UPDATE_CARD_SUCCESS,
    payload: data,
  };
};

const updateCardError = (error) => {
  return {
    type: UPDATE_CARD_ERROR,
    payload: error,
  };
};

// update card status
const updateCardStatusLoading = () => {
  return {
    type: UPDATE_CARD_STATUS_LOADING,
  };
};

const updateCardStatusSuccess = (data) => {
  return {
    type: UPDATE_CARD_STATUS_SUCCESS,
    payload: data,
  };
};

const updateCardStatusError = (error) => {
  return {
    type: UPDATE_CARD_STATUS_ERROR,
    payload: error,
  };
};

// delete card
const deleteCardLoading = () => {
  return {
    type: DELETE_CARD_LOADING,
  };
};

const deleteCardSuccess = (data) => {
  return {
    type: DELETE_CARD_SUCCESS,
    payload: data,
  };
};

const deleteCardError = (error) => {
  return {
    type: DELETE_CARD_ERROR,
    payload: error,
  };
};

// search card
const searchCardLoading = () => {
  return {
    type: SEARCH_CARD_LOADING,
  };
};

const searchCardSuccess = (data) => {
  return {
    type: SEARCH_CARD_SUCCESS,
    payload: data,
  };
};

const searchCardError = (error) => {
  return {
    type: SEARCH_CARD_ERROR,
    payload: error,
  };
};

export { 
    getAllCardsLoading, 
    getAllCardsSuccess, 
    getAllCardsError,

    exportAllCsvCardsLoading, 
    exportAllCsvCardsSuccess, 
    exportAllCsvCardsError,

    importAllCsvCardsLoading, 
    importAllCsvCardsSuccess, 
    importAllCsvCardsError,

    createCardLoading, 
    createCardSuccess, 
    createCardError,

    updateCardLoading, 
    updateCardSuccess, 
    updateCardError,

    updateCardStatusLoading, 
    updateCardStatusSuccess, 
    updateCardStatusError,

    deleteCardLoading, 
    deleteCardSuccess, 
    deleteCardError,

    searchCardLoading, 
    searchCardSuccess, 
    searchCardError
};
