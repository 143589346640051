export const GET_ALL_USERS_LOADING = 'GET_ALL_USERS_LOADING';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR';

export const GET_ALL_SEARCH_USER_LOADING = 'GET_ALL_SEARCH_USER_LOADING';
export const GET_ALL_SEARCH_USER_SUCCESS = 'GET_ALL_SEARCH_USER_SUCCESS';
export const GET_ALL_SEARCH_USER_ERROR = 'GET_ALL_SEARCH_USER_ERROR';

export const UPDATE_USER_STATUS_LOADING = 'UPDATE_USER_STATUS_LOADING';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_ERROR = 'UPDATE_USER_STATUS_ERROR';

export const DELETE_USER_LOADING = 'DELETE_USER_LOADING';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
