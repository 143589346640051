export const GET_ALL_PROVIDER_TYPES_LOADING = 'GET_ALL_PROVIDER_TYPES_LOADING';
export const GET_ALL_PROVIDER_TYPES_SUCCESS = 'GET_ALL_PROVIDER_TYPES_SUCCESS';
export const GET_ALL_PROVIDER_TYPES_ERROR = 'GET_ALL_PROVIDER_TYPES_ERROR';

export const EXPORT_ALL_CSV_PROVIDER_TYPES_LOADING = 'EXPORT_ALL_CSV_PROVIDER_TYPES_LOADING';
export const EXPORT_ALL_CSV_PROVIDER_TYPES_SUCCESS = 'EXPORT_ALL_CSV_PROVIDER_TYPES_SUCCESS';
export const EXPORT_ALL_CSV_PROVIDER_TYPES_ERROR = 'EXPORT_ALL_CSV_PROVIDER_TYPES_ERROR';

export const IMPORT_ALL_CSV_PROVIDER_TYPES_LOADING = 'IMPORT_ALL_CSV_PROVIDER_TYPES_LOADING';
export const IMPORT_ALL_CSV_PROVIDER_TYPES_SUCCESS = 'IMPORT_ALL_CSV_PROVIDER_TYPES_SUCCESS';
export const IMPORT_ALL_CSV_PROVIDER_TYPES_ERROR = 'IMPORT_ALL_CSV_PROVIDER_TYPES_ERROR';

export const CREATE_PROVIDER_TYPE_LOADING = 'CREATE_PROVIDER_TYPE_LOADING';
export const CREATE_PROVIDER_TYPE_SUCCESS = 'CREATE_PROVIDER_TYPE_SUCCESS';
export const CREATE_PROVIDER_TYPE_ERROR = 'CREATE_PROVIDER_TYPE_ERROR';

export const UPDATE_PROVIDER_TYPE_LOADING = 'UPDATE_PROVIDER_TYPE_LOADING';
export const UPDATE_PROVIDER_TYPE_SUCCESS = 'UPDATE_PROVIDER_TYPE_SUCCESS';
export const UPDATE_PROVIDER_TYPE_ERROR = 'UPDATE_PROVIDER_TYPE_ERROR';

export const UPDATE_PROVIDER_TYPE_STATUS_LOADING = 'UPDATE_PROVIDER_TYPE_STATUS_LOADING';
export const UPDATE_PROVIDER_TYPE_STATUS_SUCCESS = 'UPDATE_PROVIDER_TYPE_STATUS_SUCCESS';
export const UPDATE_PROVIDER_TYPE_STATUS_ERROR = 'UPDATE_PROVIDER_TYPE_STATUS_ERROR';

export const DELETE_PROVIDER_TYPE_LOADING = 'DELETE_PROVIDER_TYPE_LOADING';
export const DELETE_PROVIDER_TYPE_SUCCESS = 'DELETE_PROVIDER_TYPE_SUCCESS';
export const DELETE_PROVIDER_TYPE_ERROR = 'DELETE_PROVIDER_TYPE_ERROR';

export const SEARCH_PROVIDER_TYPE_LOADING = 'SEARCH_PROVIDER_TYPE_LOADING';
export const SEARCH_PROVIDER_TYPE_SUCCESS = 'SEARCH_PROVIDER_TYPE_SUCCESS';
export const SEARCH_PROVIDER_TYPE_ERROR = 'SEARCH_PROVIDER_TYPE_ERROR';
