import { ADMIN_LOGIN_LOADING, ADMIN_LOGIN_SUCCESS, ADMIN_LOGIN_ERROR, ADMIN_GET_ALL_USERS_LOADING, ADMIN_GET_ALL_USERS_SUCCESS, ADMIN_GET_ALL_USERS_ERROR, ADMIN_CREATE_USER_LOADING, ADMIN_CREATE_USER_SUCCESS, ADMIN_CREATE_USER_ERROR, GET_ADMIN_BY_TOKEN_LOADING, GET_ADMIN_BY_TOKEN_SUCCESS, GET_ADMIN_BY_TOKEN_ERROR, ADMIN_DELETE_USER_LOADING, ADMIN_DELETE_USER_SUCCESS, ADMIN_DELETE_USER_ERROR, ADMIN_UPDATE_USER_LOADING, ADMIN_UPDATE_USER_SUCCESS, ADMIN_UPDATE_USER_ERROR, ADMIN_UPDATE_USER_STATUS_LOADING, ADMIN_UPDATE_USER_STATUS_SUCCESS, ADMIN_UPDATE_USER_STATUS_ERROR, ADMIN_UPDATE_USER_PROFILE_SUCCESS, ADMIN_UPDATE_USER_PROFILE_ERROR, ADMIN_UPDATE_USER_PROFILE_LOADING, ADMIN_USER_SEARCH_LOADING, ADMIN_USER_SEARCH_SUCCESS, ADMIN_USER_SEARCH_ERROR, CREATE_META_JOSN_LOADING, CREATE_META_JOSN_SUCCESS, CREATE_META_JOSN_ERROR } from './actionTypes';

// admin login
const adminLoginLoading = () => {
  return {
    type: ADMIN_LOGIN_LOADING,
  };
};

const adminLoginSuccess = (data) => {
  return {
    type: ADMIN_LOGIN_SUCCESS,
    payload: data,
  };
};

const adminLoginError = (error) => {
  return {
    type: ADMIN_LOGIN_ERROR,
    payload: error,
  };
};

// get admin by token
const getAdminByTokenLoading = () => {
  return {
    type: GET_ADMIN_BY_TOKEN_LOADING,
  };
};

const getAdminByTokenSuccess = (data) => {
  return {
    type: GET_ADMIN_BY_TOKEN_SUCCESS,
    payload: data,
  };
};

const getAdminByTokenError = (error) => {
  return {
    type: GET_ADMIN_BY_TOKEN_ERROR,
    payload: error,
  };
};

// get all users by admin
const adminGetAllUsersLoading = () => {
  return {
    type: ADMIN_GET_ALL_USERS_LOADING,
  };
};

const adminGetAllUsersSuccess = (data) => {
  return {
    type: ADMIN_GET_ALL_USERS_SUCCESS,
    payload: data,
  };
};

const adminGetAllUsersError = (error) => {
  return {
    type: ADMIN_GET_ALL_USERS_ERROR,
    payload: error,
  };
};

// create user by admin
const adminCreateUserLoading = () => {
  return {
    type: ADMIN_CREATE_USER_LOADING,
  };
};

const adminCreateUserSuccess = (data) => {
  return {
    type: ADMIN_CREATE_USER_SUCCESS,
    payload: data,
  };
};

const adminCreateUserError = (error) => {
  return {
    type: ADMIN_CREATE_USER_ERROR,
    payload: error,
  };
};

// update user by admin
const adminUpdateUserLoading = () => {
  return {
    type: ADMIN_UPDATE_USER_LOADING,
  };
};

const adminUpdateUserSuccess = (data) => {
  return {
    type: ADMIN_UPDATE_USER_SUCCESS,
    payload: data,
  };
};

const adminUpdateUserError = (error) => {
  return {
    type: ADMIN_UPDATE_USER_ERROR,
    payload: error,
  };
};

// profile update admin user
const adminUserProfileUpdateLoading = () => {
  return {
    type: ADMIN_UPDATE_USER_PROFILE_LOADING,
  };
};

const adminUserProfileUpdateSuccess = (data) => {
  return {
    type: ADMIN_UPDATE_USER_PROFILE_SUCCESS,
    payload: data,
  };
};

const adminUserProfileUpdateError = (error) => {
  return {
    type: ADMIN_UPDATE_USER_PROFILE_ERROR,
    payload: error,
  };
};

// update user status by admin
const adminUpdateUserStatusLoading = () => {
  return {
    type: ADMIN_UPDATE_USER_STATUS_LOADING,
  };
};

const adminUpdateUserStatusSuccess = (data) => {
  return {
    type: ADMIN_UPDATE_USER_STATUS_SUCCESS,
    payload: data,
  };
};

const adminUpdateUserStatusError = (error) => {
  return {
    type: ADMIN_UPDATE_USER_STATUS_ERROR,
    payload: error,
  };
};

// delete user by admin
const adminDeleteUserLoading = () => {
  return {
    type: ADMIN_DELETE_USER_LOADING,
  };
};

const adminDeleteUserSuccess = (data) => {
  return {
    type: ADMIN_DELETE_USER_SUCCESS,
    payload: data,
  };
};

const adminDeleteUserError = (error) => {
  return {
    type: ADMIN_DELETE_USER_ERROR,
    payload: error,
  };
};

// search user by name
const adminUserSearchLoading = () => {
  return {
    type: ADMIN_USER_SEARCH_LOADING,
  };
};

const adminUserSearchSuccess = (data) => {
  return {
    type: ADMIN_USER_SEARCH_SUCCESS,
    payload: data,
  };
};

const adminUserSearchError = (error) => {
  return {
    type: ADMIN_USER_SEARCH_ERROR,
    payload: error,
  };
};

// create meta json
const createMetaJsonLoading = () => {
  return {
    type: CREATE_META_JOSN_LOADING,
  };
};

const createMetaJsonSuccess = (data) => {
  return {
    type: CREATE_META_JOSN_SUCCESS,
    payload: data,
  };
};

const createMetaJsonError = (error) => {
  return {
    type: CREATE_META_JOSN_ERROR,
    payload: error,
  };
};

export { 
    adminLoginLoading, 
    adminLoginSuccess, 
    adminLoginError,

    getAdminByTokenLoading, 
    getAdminByTokenSuccess, 
    getAdminByTokenError,

    adminGetAllUsersLoading, 
    adminGetAllUsersSuccess, 
    adminGetAllUsersError,  

    adminCreateUserLoading, 
    adminCreateUserSuccess, 
    adminCreateUserError,

    adminUpdateUserLoading, 
    adminUpdateUserSuccess, 
    adminUpdateUserError,

    adminUserProfileUpdateLoading, 
    adminUserProfileUpdateSuccess, 
    adminUserProfileUpdateError,

    adminUpdateUserStatusLoading, 
    adminUpdateUserStatusSuccess, 
    adminUpdateUserStatusError,
    
    adminDeleteUserLoading, 
    adminDeleteUserSuccess, 
    adminDeleteUserError,

    adminUserSearchLoading, 
    adminUserSearchSuccess, 
    adminUserSearchError,

    createMetaJsonLoading, 
    createMetaJsonSuccess, 
    createMetaJsonError,
};
