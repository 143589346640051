import { GET_ALL_NEWS_LETTERS_LOADING, GET_ALL_NEWS_LETTERS_SUCCESS, GET_ALL_NEWS_LETTERS_ERROR, CREATE_NEWS_LETTER_LOADING, CREATE_NEWS_LETTER_SUCCESS, CREATE_NEWS_LETTER_ERROR, UPDATE_NEWS_LETTER_LOADING, UPDATE_NEWS_LETTER_SUCCESS, UPDATE_NEWS_LETTER_ERROR, DELETE_NEWS_LETTER_LOADING, DELETE_NEWS_LETTER_SUCCESS, DELETE_NEWS_LETTER_ERROR, UPDATE_NEWS_LETTER_STATUS_LOADING, UPDATE_NEWS_LETTER_STATUS_SUCCESS, UPDATE_NEWS_LETTER_STATUS_ERROR, SEARCH_NEWS_LETTER_LOADING, SEARCH_NEWS_LETTER_SUCCESS, SEARCH_NEWS_LETTER_ERROR, EXPORT_ALL_CSV_NEWS_LETTERS_LOADING, EXPORT_ALL_CSV_NEWS_LETTERS_SUCCESS, EXPORT_ALL_CSV_NEWS_LETTERS_ERROR, IMPORT_ALL_CSV_NEWS_LETTERS_LOADING, IMPORT_ALL_CSV_NEWS_LETTERS_SUCCESS, IMPORT_ALL_CSV_NEWS_LETTERS_ERROR } from './actionTypes';

// get all
const getAllNewsLettersLoading = () => {
  return {
    type: GET_ALL_NEWS_LETTERS_LOADING,
  };
};

const getAllNewsLettersSuccess = (data) => {
  return {
    type: GET_ALL_NEWS_LETTERS_SUCCESS,
    payload: data,
  };
};

const getAllNewsLettersError = (error) => {
  return {
    type: GET_ALL_NEWS_LETTERS_ERROR,
    payload: error,
  };
};

// export all csv
const exportAllCsvNewsLettersLoading = () => {
  return {
    type: EXPORT_ALL_CSV_NEWS_LETTERS_LOADING,
  };
};

const exportAllCsvNewsLettersSuccess = (data) => {
  return {
    type: EXPORT_ALL_CSV_NEWS_LETTERS_SUCCESS,
    payload: data,
  };
};

const exportAllCsvNewsLettersError = (error) => {
  return {
    type: EXPORT_ALL_CSV_NEWS_LETTERS_ERROR,
    payload: error,
  };
};

// import all csv
const importAllCsvNewsLettersLoading = () => {
  return {
    type: IMPORT_ALL_CSV_NEWS_LETTERS_LOADING,
  };
};

const importAllCsvNewsLettersSuccess = (data) => {
  return {
    type: IMPORT_ALL_CSV_NEWS_LETTERS_SUCCESS,
    payload: data,
  };
};

const importAllCsvNewsLettersError = (error) => {
  return {
    type: IMPORT_ALL_CSV_NEWS_LETTERS_ERROR,
    payload: error,
  };
};

// create
const createNewsLetterLoading = () => {
  return {
    type: CREATE_NEWS_LETTER_LOADING,
  };
};

const createNewsLetterSuccess = (data) => {
  return {
    type: CREATE_NEWS_LETTER_SUCCESS,
    payload: data,
  };
};

const createNewsLetterError = (error) => {
  return {
    type: CREATE_NEWS_LETTER_ERROR,
    payload: error,
  };
};

// update
const updateNewsLetterLoading = () => {
  return {
    type: UPDATE_NEWS_LETTER_LOADING,
  };
};

const updateNewsLetterSuccess = (data) => {
  return {
    type: UPDATE_NEWS_LETTER_SUCCESS,
    payload: data,
  };
};

const updateNewsLetterError = (error) => {
  return {
    type: UPDATE_NEWS_LETTER_ERROR,
    payload: error,
  };
};

// update status
const updateNewsLetterStatusLoading = () => {
  return {
    type: UPDATE_NEWS_LETTER_STATUS_LOADING,
  };
};

const updateNewsLetterStatusSuccess = (data) => {
  return {
    type: UPDATE_NEWS_LETTER_STATUS_SUCCESS,
    payload: data,
  };
};

const updateNewsLetterStatusError = (error) => {
  return {
    type: UPDATE_NEWS_LETTER_STATUS_ERROR,
    payload: error,
  };
};

// delete
const deleteNewsLetterLoading = () => {
  return {
    type: DELETE_NEWS_LETTER_LOADING,
  };
};

const deleteNewsLetterSuccess = (data) => {
  return {
    type: DELETE_NEWS_LETTER_SUCCESS,
    payload: data,
  };
};

const deleteNewsLetterError = (error) => {
  return {
    type: DELETE_NEWS_LETTER_ERROR,
    payload: error,
  };
};

// search
const searchNewsLetterLoading = () => {
  return {
    type: SEARCH_NEWS_LETTER_LOADING,
  };
};

const searchNewsLetterSuccess = (data) => {
  return {
    type: SEARCH_NEWS_LETTER_SUCCESS,
    payload: data,
  };
};

const searchNewsLetterError = (error) => {
  return {
    type: SEARCH_NEWS_LETTER_ERROR,
    payload: error,
  };
};

export { 
    getAllNewsLettersLoading, 
    getAllNewsLettersSuccess, 
    getAllNewsLettersError,

    exportAllCsvNewsLettersLoading, 
    exportAllCsvNewsLettersSuccess, 
    exportAllCsvNewsLettersError,

    importAllCsvNewsLettersLoading, 
    importAllCsvNewsLettersSuccess, 
    importAllCsvNewsLettersError,

    createNewsLetterLoading, 
    createNewsLetterSuccess, 
    createNewsLetterError,

    updateNewsLetterLoading, 
    updateNewsLetterSuccess, 
    updateNewsLetterError,

    updateNewsLetterStatusLoading, 
    updateNewsLetterStatusSuccess, 
    updateNewsLetterStatusError,

    deleteNewsLetterLoading, 
    deleteNewsLetterSuccess, 
    deleteNewsLetterError,

    searchNewsLetterLoading, 
    searchNewsLetterSuccess, 
    searchNewsLetterError
};
