import { GET_ALL_CARD_OFFERS_LOADING, GET_ALL_CARD_OFFERS_SUCCESS, GET_ALL_CARD_OFFERS_ERROR, CREATE_CARD_OFFER_LOADING, CREATE_CARD_OFFER_SUCCESS, CREATE_CARD_OFFER_ERROR, UPDATE_CARD_OFFER_LOADING, UPDATE_CARD_OFFER_SUCCESS, UPDATE_CARD_OFFER_ERROR, DELETE_CARD_OFFER_LOADING, DELETE_CARD_OFFER_SUCCESS, DELETE_CARD_OFFER_ERROR, UPDATE_CARD_OFFER_STATUS_LOADING, UPDATE_CARD_OFFER_STATUS_SUCCESS, UPDATE_CARD_OFFER_STATUS_ERROR, SEARCH_CARD_OFFER_LOADING, SEARCH_CARD_OFFER_SUCCESS, SEARCH_CARD_OFFER_ERROR, EXPORT_ALL_CSV_CARD_OFFERS_LOADING, EXPORT_ALL_CSV_CARD_OFFERS_SUCCESS, EXPORT_ALL_CSV_CARD_OFFERS_ERROR, IMPORT_ALL_CSV_CARD_OFFERS_LOADING, IMPORT_ALL_CSV_CARD_OFFERS_SUCCESS, IMPORT_ALL_CSV_CARD_OFFERS_ERROR, SEARCH_COUNTRIES_LOADING, SEARCH_COUNTRIES_SUCCESS, SEARCH_COUNTRIES_ERROR, SEARCH_STATES_LOADING, SEARCH_STATES_SUCCESS, SEARCH_STATES_ERROR, SEARCH_CITIES_LOADING, SEARCH_CITIES_SUCCESS, SEARCH_CITIES_ERROR, CHECK_ALL_CSV_CARD_OFFERS_LOADING, CHECK_ALL_CSV_CARD_OFFERS_SUCCESS, CHECK_ALL_CSV_CARD_OFFERS_ERROR } from './actionTypes';

// get all CardOffers
const getAllCardOffersLoading = () => {
  return {
    type: GET_ALL_CARD_OFFERS_LOADING,
  };
};

const getAllCardOffersSuccess = (data) => {
  return {
    type: GET_ALL_CARD_OFFERS_SUCCESS,
    payload: data,
  };
};

const getAllCardOffersError = (error) => {
  return {
    type: GET_ALL_CARD_OFFERS_ERROR,
    payload: error,
  };
};

// export all csv CardOffers
const exportAllCsvCardOffersLoading = () => {
  return {
    type: EXPORT_ALL_CSV_CARD_OFFERS_LOADING,
  };
};

const exportAllCsvCardOffersSuccess = (data) => {
  return {
    type: EXPORT_ALL_CSV_CARD_OFFERS_SUCCESS,
    payload: data,
  };
};

const exportAllCsvCardOffersError = (error) => {
  return {
    type: EXPORT_ALL_CSV_CARD_OFFERS_ERROR,
    payload: error,
  };
};

// check all csv CardOffers
const checkAllCsvCardOffersLoading = () => {
  return {
    type: CHECK_ALL_CSV_CARD_OFFERS_LOADING,
  };
};

const checkAllCsvCardOffersSuccess = (data) => {
  return {
    type: CHECK_ALL_CSV_CARD_OFFERS_SUCCESS,
    payload: data,
  };
};

const checkAllCsvCardOffersError = (error) => {
  return {
    type: CHECK_ALL_CSV_CARD_OFFERS_ERROR,
    payload: error,
  };
};

// import all csv CardOffers
const importAllCsvCardOffersLoading = () => {
  return {
    type: IMPORT_ALL_CSV_CARD_OFFERS_LOADING,
  };
};

const importAllCsvCardOffersSuccess = (data) => {
  return {
    type: IMPORT_ALL_CSV_CARD_OFFERS_SUCCESS,
    payload: data,
  };
};

const importAllCsvCardOffersError = (error) => {
  return {
    type: IMPORT_ALL_CSV_CARD_OFFERS_ERROR,
    payload: error,
  };
};

// create card offer
const createCardOfferLoading = () => {
  return {
    type: CREATE_CARD_OFFER_LOADING,
  };
};

const createCardOfferSuccess = (data) => {
  return {
    type: CREATE_CARD_OFFER_SUCCESS,
    payload: data,
  };
};

const createCardOfferError = (error) => {
  return {
    type: CREATE_CARD_OFFER_ERROR,
    payload: error,
  };
};

// update card offer
const updateCardOfferLoading = () => {
  return {
    type: UPDATE_CARD_OFFER_LOADING,
  };
};

const updateCardOfferSuccess = (data) => {
  return {
    type: UPDATE_CARD_OFFER_SUCCESS,
    payload: data,
  };
};

const updateCardOfferError = (error) => {
  return {
    type: UPDATE_CARD_OFFER_ERROR,
    payload: error,
  };
};

// update card offer status
const updateCardOfferStatusLoading = () => {
  return {
    type: UPDATE_CARD_OFFER_STATUS_LOADING,
  };
};

const updateCardOfferStatusSuccess = (data) => {
  return {
    type: UPDATE_CARD_OFFER_STATUS_SUCCESS,
    payload: data,
  };
};

const updateCardOfferStatusError = (error) => {
  return {
    type: UPDATE_CARD_OFFER_STATUS_ERROR,
    payload: error,
  };
};

// delete card offer
const deleteCardOfferLoading = () => {
  return {
    type: DELETE_CARD_OFFER_LOADING,
  };
};

const deleteCardOfferSuccess = (data) => {
  return {
    type: DELETE_CARD_OFFER_SUCCESS,
    payload: data,
  };
};

const deleteCardOfferError = (error) => {
  return {
    type: DELETE_CARD_OFFER_ERROR,
    payload: error,
  };
};

// search card offer
const searchCardOfferLoading = () => {
  return {
    type: SEARCH_CARD_OFFER_LOADING,
  };
};

const searchCardOfferSuccess = (data) => {
  return {
    type: SEARCH_CARD_OFFER_SUCCESS,
    payload: data,
  };
};

const searchCardOfferError = (error) => {
  return {
    type: SEARCH_CARD_OFFER_ERROR,
    payload: error,
  };
};

// search Countries
const searchCountriesLoading = () => {
  return {
    type: SEARCH_COUNTRIES_LOADING,
  };
};

const searchCountriesSuccess = (data) => {
  return {
    type: SEARCH_COUNTRIES_SUCCESS,
    payload: data,
  };
};

const searchCountriesError = (error) => {
  return {
    type: SEARCH_COUNTRIES_ERROR,
    payload: error,
  };
};

// search States
const searchStatesLoading = () => {
  return {
    type: SEARCH_STATES_LOADING,
  };
};

const searchStatesSuccess = (data) => {
  return {
    type: SEARCH_STATES_SUCCESS,
    payload: data,
  };
};

const searchStatesError = (error) => {
  return {
    type: SEARCH_STATES_ERROR,
    payload: error,
  };
};

// search Cities
const searchCitiesLoading = () => {
  return {
    type: SEARCH_CITIES_LOADING,
  };
};

const searchCitiesSuccess = (data) => {
  return {
    type: SEARCH_CITIES_SUCCESS,
    payload: data,
  };
};

const searchCitiesError = (error) => {
  return {
    type: SEARCH_CITIES_ERROR,
    payload: error,
  };
};

export { 
    getAllCardOffersLoading, 
    getAllCardOffersSuccess, 
    getAllCardOffersError,

    exportAllCsvCardOffersLoading, 
    exportAllCsvCardOffersSuccess, 
    exportAllCsvCardOffersError,

    checkAllCsvCardOffersLoading, 
    checkAllCsvCardOffersSuccess, 
    checkAllCsvCardOffersError,

    importAllCsvCardOffersLoading, 
    importAllCsvCardOffersSuccess, 
    importAllCsvCardOffersError,

    createCardOfferLoading, 
    createCardOfferSuccess, 
    createCardOfferError,

    updateCardOfferLoading, 
    updateCardOfferSuccess, 
    updateCardOfferError,

    updateCardOfferStatusLoading, 
    updateCardOfferStatusSuccess, 
    updateCardOfferStatusError,

    deleteCardOfferLoading, 
    deleteCardOfferSuccess, 
    deleteCardOfferError,

    searchCardOfferLoading, 
    searchCardOfferSuccess, 
    searchCardOfferError,

    searchCountriesLoading, 
    searchCountriesSuccess, 
    searchCountriesError,

    searchStatesLoading, 
    searchStatesSuccess, 
    searchStatesError,

    searchCitiesLoading, 
    searchCitiesSuccess, 
    searchCitiesError,
};
