import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import PermMediaIcon from '@material-ui/icons/PermMedia';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Grow, InputLabel, Menu, MenuItem, Paper, Select, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import { createCardAction, deleteCardAction, getAllCsvCardsAction, getAllCardsAction, importCsvCardAction, searchCardAction, updateCardAction, updateCardStatusAction } from '../../store/cards/asyncActions';
import { Autocomplete } from '@material-ui/lab';
import { searchTypeegoryAction } from '../../store/category/asyncActions';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { getAllPermissionAction } from '../../store/role/asyncActions';
import { createSlug, exportCSV, statusColor } from '../../helpers';
import CSVReader from 'react-csv-reader';
import { toast } from 'react-toastify';
import moment from 'moment';
import { searchProviderTypeAction } from '../../store/card-providers-types/asyncActions';
import { searchAdminUserAction } from '../../store/admin/asyncActions';
import { searchProviderAction } from '../../store/card-providers/asyncActions';
import { searchCardNetworkAction } from '../../store/card-networks/asyncActions';



const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  }
}));

function CardList() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, setID] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();


  const [cards, setCards] = useState(null);
  const [createdByList, setCreatedByList] = useState(null);
  const [providerList, setProviderList] = useState(null);
  const [networkList, setNetworkList] = useState(null);

  const cardList = useSelector((state) => state?.card?.getAllCards);
  const csvCardList = useSelector((state) => state?.card?.getAllCsvCards);
  const importCardList = useSelector((state) => state?.card?.getAllCsvImportCards);
  const providerSearch = useSelector((state) => state?.provider?.searchProvider);
  const networkSearch = useSelector((state) => state?.cardNetwork?.searchCardNetwork);
  const newCard = useSelector((state) => state?.card?.createCard);
  const updatedCard = useSelector((state) => state?.card?.updateCard);
  const updatedCardStatus = useSelector((state) => state?.card?.updateCardStatus);
  const deletedCard = useSelector((state) => state?.card?.deleteCard);
  const createdByData = useSelector((state) => state?.admin?.adminUserSearch);

  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermission, setAdminPermission] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
    dispatch(searchProviderAction(''))
    dispatch(searchCardNetworkAction(''))
    dispatch(searchAdminUserAction(''))
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermission(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    if (providerSearch?.data?.status && Array.isArray(providerSearch?.data?.data)) {
      setProviderList(providerSearch?.data?.data)
    }
  }, [providerSearch]);

  useEffect(() => {
    if (networkSearch?.data?.status && Array.isArray(networkSearch?.data?.data)) {
      setNetworkList(networkSearch?.data?.data)
    }
  }, [networkSearch]);

  useEffect(() => {
    if (createdByData?.data?.status && Array.isArray(createdByData?.data?.data)) {
      setCreatedByList(createdByData?.data?.data)
    }
  }, [createdByData]);

  useEffect(() => {
    dispatch(getAllCardsAction(page + 1, pageSize, filter));
  }, [page, pageSize]);

  useEffect(() => {
    document.title = 'Cards';
  }, []);

  useEffect(() => {
    if (cardList?.data?.status && Array.isArray(cardList?.data?.data?.records)) {
      setCards(cardList?.data?.data?.records);
      setTotalCount(cardList?.data?.data?.totalCount);
    }
  }, [cardList]);

  useEffect(() => {
    if (csvCardList?.data?.status && Array.isArray(csvCardList?.data?.data?.records)) {
      exportCSV(csvCardList?.data?.data?.records, 'instruments.csv');
      setCsvLoader(false);
      csvCardList.data = null;
    }
  }, [csvCardList]);

  useEffect(() => {
    if (importCardList?.data?.status) {
      setCsvLoader(false);
      dispatch(getAllCardsAction(page + 1, pageSize, filter));
      setFileData(null);
      setFileDetails(null);
      importCardList.data = null;
    }
  }, [importCardList]);

  useEffect(() => {
    if (newCard?.data?.status) {
      setOpenModal(false);
      dispatch(getAllCardsAction(page + 1, pageSize, filter));
      resetForm();
      newCard.data = null;
    }
  }, [newCard]);

  useEffect(() => {
    if (updatedCard?.data?.status) {
      setOpenModal(false);
      dispatch(getAllCardsAction(page + 1, pageSize, filter));
      resetForm();
      updatedCard.data = null;
    }
  }, [updatedCard]);

  useEffect(() => {
    if (updatedCardStatus?.data?.status) {
      dispatch(getAllCardsAction(page + 1, pageSize, filter));
      updatedCardStatus.data = null;
    }
  }, [updatedCardStatus]);

  useEffect(() => {
    if (deletedCard?.data?.status) {
      dispatch(getAllCardsAction(page + 1, pageSize, filter));
      setDeleteModal(false);
      deletedCard.data = null;
    }
  }, [deletedCard]);

  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState({
    provider_id: '',
    name: '',
    type: '',
    status: '',
    created_by: ''
  });

  const filterHandleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  }

  const filterApply = () => {
    dispatch(getAllCardsAction(page + 1, pageSize, filter));
  }

  const filterClear = () => {
    const reset = {
      provider_id: '',
      name: '',
      type: '',
      status: '',
      created_by: ''
    }
    setFilter(reset);
    setFilterProvider(null);
    setCreatedBy(null);
    dispatch(getAllCardsAction(page + 1, pageSize, reset));
  }

  const [cardFormData, setCardFormData] = useState({
    card_provider_id: '',
    name: '',
    card_network_id: '',
    type: 'Credit Card',
    slug: '',
    description: '',
    logo: null,
    status: true,
    meta_title: '',
    meta_keywords: '',
    meta_description: '',
  });

  const [provider, setProvider] = useState(null);
  const [cardNetwork, setCardNetwork] = useState(null);
  const [filterProvider, setFilterProvider] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);

  const resetForm = () => {
    setImagePreview(null);
    setErrors({});
    setProvider(null);
    setCardNetwork(null);
    setCardFormData({
      card_provider_id: '',
      name: '',
      card_network_id: '',
      type: 'Credit Card',
      slug: '',
      description: '',
      logo: null,
      status: true,
      meta_title: '',
      meta_keywords: '',
      meta_description: '',
    });
  }

  const DataHandleChange = (e) => {
    setCardFormData({
      ...cardFormData,
      [e.target.name]: e.target.value
    });
  }

  const addNew = () => {
    resetForm();
    // dispatch(searchCardTypeAction(''))
    setOpenModal(true);
  }

  const editCard = (row) => {
    if (!row?.logo) {
      setImagePreview(null);
    }
    setErrors({});
    setCardFormData({
      id: row?._id,
      card_provider_id: row?.provider?._id,
      name: row?.name,
      card_network_id: row?.network?._id,
      type: row?.type,
      slug: row?.slug,
      description: row?.description,
      logo: row?.logo || null,
      status: row?.status,
      meta_title: row?.meta_title || '',
      meta_keywords: row?.meta_keywords || '',
      meta_description: row?.meta_description || '',
    });
    setProvider(row?.provider ? row?.provider : null);
    setCardNetwork(row?.network ? row?.network : null);
    setOpenModal(true);
  }

  const createCard = () => {
    dispatch(createCardAction(setFormDataFunc()))
  }

  const updateCard = () => {
    dispatch(updateCardAction(setFormDataFunc()))
  }

  const updateCardStatus = (data) => {
    {
      adminPermission && (adminPermission.includes('Edit Card') || adminPermission.includes('All')) &&
        dispatch(updateCardStatusAction(data))
    }
  }

  const setFormDataFunc = () => {
    const Data = new FormData();
    if (cardFormData?.id && cardFormData?.id !== null && cardFormData?.id !== '') {
      Data.append('id', cardFormData?.id);
    }
    Data.append('card_provider_id', cardFormData?.card_provider_id);
    Data.append('name', cardFormData?.name);
    Data.append('card_network_id', cardFormData?.card_network_id);
    Data.append('type', cardFormData?.type);
    Data.append('slug', cardFormData?.slug);
    Data.append('description', cardFormData?.description);
    Data.append('logo', cardFormData?.logo);
    Data.append('status', cardFormData?.status);
    Data.append('meta_title', cardFormData?.meta_title);
    Data.append('meta_keywords', cardFormData?.meta_keywords);
    Data.append('meta_description', cardFormData?.meta_description);
    return Data;
  }

  const save = () => {
    const validationResult = validationSchema.validate(cardFormData, { abortEarly: false });
    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
      console.log(validationErrors);
    } else {
      setErrors({});
      if (cardFormData?.id && cardFormData?.id !== '' && cardFormData?.id !== null) {
        updateCard()
      } else {
        createCard()
      }
    }
  }

  const deleteCard = (id) => {
    dispatch(deleteCardAction({ id: id }));
  }

  const [errors, setErrors] = useState({});
  const validationSchema = Joi.object({
    id: Joi.string().allow(null, ''),
    card_provider_id: Joi.string().required(),
    name: Joi.string().required(),
    card_network_id: Joi.string().required(),
    type: Joi.string().required(),
    slug: Joi.string().required(),
    description: Joi.string().allow(null, ''),
    logo: Joi.allow(null, '').required(),
    status: Joi.boolean().required(),
    meta_title: Joi.string().allow(null, ''),
    meta_keywords: Joi.string().allow(null, ''),
    meta_description: Joi.string().allow(null, ''),
  });


  const [importCardModal, setImportCardModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [csvLoader, setCsvLoader] = useState(false);
  const [fileDetails, setFileDetails] = useState(null);
  const [fileData, setFileData] = useState(null);
  const exportCsvData = () => {
    setCsvLoader(true);
    dispatch(getAllCsvCardsAction(filter));
    setAnchorEl(null);
  }
  const handleCSVData = (data, fileInfo) => {
    if (fileInfo?.type !== 'text/csv') {
      toast.error('Invalid file format!');
      setAnchorEl(null);
      return;
    }
    if (data && Array.isArray(data)) {
      setFileData(data);
      setFileDetails(fileInfo);
      setAnchorEl(null);
    }
  };
  const importCsvData = () => {
    setImportCardModal(false);
    setCsvLoader(true);
    dispatch(importCsvCardAction({ cardData: fileData }));
  }
  const closeImpCsvModal = () => {
    setImportCardModal(false);
    setFileDetails(null);
    setFileData(null);
  }

  const cardsDemoCsv = () => {
    const demo = [
      {
        _id: "650af7e88a3ea2e1e91b880a",
        card_provider_id: "650af7e88a3ea2e1e91b880a",
        name: `"Platinum"`,
        card_network_id: "650af7e88a3ea2e1e91b880a",
        type: `"Credit Card"`,
        description: `"description..."`,
        logo: "650ae7b58f69cd6f4f48a965",
      },
      {
        _id: "650af7e88a3ea2e1e91b880a",
        card_provider_id: "650af7e88a3ea2e1e91b880a",
        name: `"Platinum - 2"`,
        card_network_id: "650af7e88a3ea2e1e91b880a",
        type: `"Debit Card"`,
        description: `"description..."`,
        logo: "650ae7b58f69cd6f4f48a965",
      }
    ];
    exportCSV(demo, 'instruments_demo.csv');
  }

  const [imagePreview, setImagePreview] = useState(null);
  const handleFileChange = (event) => {
    setCardFormData({
      ...cardFormData,
      logo: event?.target?.files[0]
    });
  };

  useEffect(() => {
    if (cardFormData?.logo !== '' && cardFormData?.logo !== 'remove' && cardFormData?.logo !== null) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      if (typeof cardFormData?.logo === 'string') {
        setImagePreview(cardFormData?.logo);
      } else {
        reader.readAsDataURL(cardFormData?.logo);
      }
    }
  }, [cardFormData?.logo]);

  const removeImg = () => {
    setCardFormData({
      ...cardFormData,
      logo: 'remove'
    });
    setImagePreview(null);
  }

  return (
    <div>
      <Card className='pageCover'>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant='h5' color="textSecondary" className="flexSpacer">
              Instruments
            </Typography>

            <div style={{ display: 'flex', alignCards: 'center' }}>
              <Button
                color='default' variant='outlined' className='mr-1'
                aria-owns={anchorEl ? 'csv-menu' : null}
                aria-haspopup="true"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                disabled={csvLoader}
              >
                Export / Import
                {csvLoader && <CircularProgress style={{ width: '20px', height: '20px' }} className='ml-1' />}
              </Button>
              <Menu
                id="csv-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => exportCsvData()}>Export CSV</MenuItem>
                <MenuItem style={{ width: '133px' }} onClick={() => setImportCardModal(true)}>
                  Import CSV
                </MenuItem>
              </Menu>
            </div>

            {adminPermission && (adminPermission.includes('Add Card') || adminPermission.includes('All')) &&
              <Tooltip title={'Add New Instrument'} placement="left">
                <Button color='default' variant='outlined' onClick={() => addNew()}>+ Add New</Button>
              </Tooltip>
            }
            <Tooltip title={'Apply Filter On Data'} placement="left">
              <Button color='default' variant='outlined' className='ml-1' onClick={() => setOpenFilter(!openFilter)}>
                <span className='mr-1'>Filter</span> {openFilter ? <ExpandLessRoundedIcon className='btnIco' /> : <ExpandMoreRoundedIcon className='btnIco' />}
              </Button>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box className={`filterOuter ${openFilter && 'activeOpen'}`}>
          <Grid container style={{ width: '100%', paddingTop: '25px' }}>
            <Grid card xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <Autocomplete
                options={providerList || []}
                getOptionLabel={(option) => option.name}
                value={filterProvider}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Provider Name"
                    placeholder="Search name"
                    size='small'
                  />
                )}
                onInputChange={(event, newInputValue) => {
                  dispatch(searchProviderAction(newInputValue))
                }}
                onChange={(event, selectedOptions) => {
                  setFilterProvider(selectedOptions);
                  setFilter({
                    ...filter,
                    provider_id: selectedOptions?._id
                  });
                }}
              />
            </Grid>
            <Grid card xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                name='name'
                label="Instrument Name"
                variant="outlined"
                size="small"
                value={filter?.name}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid card xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                <Select
                  name='status'
                  label="Status"
                  value={filter?.status}
                  onChange={(e) => filterHandleChange(e)}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid card xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <Autocomplete
                options={createdByList || []}
                getOptionLabel={(option) => option.name}
                value={createdBy}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Created By"
                    placeholder="Search name"
                    size='small'
                    error={errors?.type_id}
                    helperText={errors?.type_id}
                  />
                )}
                onInputChange={(event, newInputValue) => {
                  dispatch(searchAdminUserAction(newInputValue))
                }}
                onChange={(event, selectedOptions) => {
                  setCreatedBy(selectedOptions);
                  setFilter({
                    ...filter,
                    created_by: selectedOptions?._id
                  });
                }}
              />
            </Grid>
            <Grid card xs={6} sm={6} md={2} xl={2} className='mb-2 px-1' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='primary' variant='contained' onClick={() => filterApply()}>Apply</Button>
              <Button color='default' variant='outlined' className='ml-1' onClick={() => filterClear()}>Clear</Button>
            </Grid>
          </Grid>
        </Box>
        <Paper>
          <TableContainer className='Custom_Datatable'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell style={{ minWidth: '75px' }}>Logo</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Provider Name</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Instrument Name</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Instrument Network</TableCell>
                  <TableCell style={{ minWidth: '125px' }}>Instrument Type</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Status</TableCell>
                  <TableCell style={{ minWidth: '125px' }}>Created By</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Created At</TableCell>
                  <TableCell style={{ minWidth: '125px' }} align='right'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cards && cards.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                      <TableCell>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={row?.logo} alt={row?.logo} className='LogoIMG' />
                        </div>
                      </TableCell>
                      <TableCell>{row?.provider?.name}</TableCell>
                      <TableCell>{row?.name}</TableCell>
                      <TableCell>{row?.network?.name}</TableCell>
                      <TableCell>{row?.type}</TableCell>
                      <TableCell>
                        <div className='StatusDiv'>
                          <div className={`StatusDot ${statusColor(row?.status ? 'Active' : 'Inactive')}`}></div>
                          {row?.status ? 'Active' : 'Inactive'}
                        </div>
                      </TableCell>
                      <TableCell>
                        {row?.created_by?.first_name + ' ' + row?.created_by?.last_name}
                      </TableCell>
                      <TableCell>
                        {moment(row?.createdAt).format('DD-MM-YYYY hh:mmA')}
                      </TableCell>
                      <TableCell align='right'>
                        {adminPermission && (adminPermission.includes('Edit Card') || adminPermission.includes('All')) &&
                          <Tooltip title={'Edit Card'} placement="left">
                            <IconButton onClick={() => editCard(row)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        }
                        {adminPermission && (adminPermission.includes('Delete Card') || adminPermission.includes('All')) &&
                          <Tooltip title={'Delete Card'} placement="left">
                            <IconButton onClick={() => { setID(row?._id); setDeleteModal(true) }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            onChangePage={(e, page) => handlePageChange(page)}
            onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
          />
        </Paper>
      </Card>

      {/* Card form dialog */}
      <Dialog
        fullScreen
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => setOpenModal(false)} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              {cardFormData?.id ? 'Edit Instrument' : 'Create Instrument'}
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }} onClick={() => save()}>
              {cardFormData?.id ? 'Update' : 'Save'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <Box>
            <Grid container style={{ maxWidth: '450px', paddingTop: '25px' }}>
              <Grid card xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Autocomplete
                  options={providerList || []}
                  getOptionLabel={(option) => option.name}
                  value={provider}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Instrument Provider"
                      placeholder="Search name"
                      size='small'
                      error={errors?.card_provider_id}
                      helperText={errors?.card_provider_id}
                    />
                  )}
                  onInputChange={(event, newInputValue) => {
                    dispatch(searchProviderAction(newInputValue))
                  }}
                  onChange={(event, selectedOptions) => {
                    setProvider(selectedOptions);
                    setCardFormData({
                      ...cardFormData,
                      card_provider_id: selectedOptions?._id,
                    });
                  }}
                />
              </Grid>
              <Grid card xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='name'
                  label="Instrument Name"
                  variant="outlined"
                  size="small"
                  value={cardFormData?.name}
                  error={errors?.name}
                  helperText={errors?.name}
                  onChange={(e) => {
                    setCardFormData({
                      ...cardFormData,
                      name: e.target.value,
                      ...(cardFormData.id ? {} : { slug: createSlug(e.target.value) })
                    });
                  }}
                />
              </Grid>
              <Grid card xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Autocomplete
                  options={networkList || []}
                  getOptionLabel={(option) => option.name}
                  value={cardNetwork}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Instrument Network"
                      placeholder="Search name"
                      size='small'
                      error={errors?.card_network_id}
                      helperText={errors?.card_network_id}
                    />
                  )}
                  onInputChange={(event, newInputValue) => {
                    dispatch(searchCardNetworkAction(newInputValue))
                  }}
                  onChange={(event, selectedOptions) => {
                    setCardNetwork(selectedOptions);
                    setCardFormData({
                      ...cardFormData,
                      card_network_id: selectedOptions?._id
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <FormControl variant="outlined" size="small" error={errors?.type} required style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-outlined-label">Instrument Type</InputLabel>
                  <Select
                    name='type'
                    label="Instrument Type"
                    value={cardFormData?.type}
                    onChange={(e) => DataHandleChange(e)}
                  >
                    <MenuItem value="Credit Card">Credit Card</MenuItem>
                    <MenuItem value="Debit Card">Debit Card</MenuItem>
                    <MenuItem value="Wallet">Wallet</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.type}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid card xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='slug'
                  label="URL Slug"
                  variant="outlined"
                  size="small"
                  value={cardFormData?.slug}
                  error={errors?.slug}
                  helperText={errors?.slug}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid card xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  name='description'
                  label="Description"
                  variant="outlined"
                  size="small"
                  rows={4}
                  multiline
                  value={cardFormData?.description}
                  error={errors?.description}
                  helperText={errors?.description}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              {!imagePreview ?
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <div className='fileDragArea'>
                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e)} />
                    <div className='iconDiv'><PermMediaIcon /></div>
                    <div className='fileupTxt'>Drag & Drop to upload image</div>
                    <div className='fileupTxt'>or</div>
                    <div style={{ paddingTop: '10px' }}>
                      <Button color="primary" variant='contained'>
                        Browse file
                      </Button>
                    </div>
                  </div>
                </Grid>
                :
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <div className='fileDragArea'>
                    <div className='imgPrevOuter'>
                      <img src={imagePreview} alt="Image Preview" />
                      <div className='imgRemove' onClick={() => removeImg()}>
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                </Grid>
              }
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Box className='statusBox'>
                  <Typography color="inherit" className="flexSpacer">
                    <div>Status</div>
                  </Typography>
                  <FormControlLabel
                    style={{ marginLeft: 'auto', marginRight: 0 }}
                    control={
                      <Switch
                        checked={cardFormData?.status}
                        onChange={(e) => setCardFormData({ ...cardFormData, status: e?.target?.checked })}
                        color="primary"
                      />
                    }
                    label={cardFormData?.status ? 'Active' : 'Inactive'}
                  />
                </Box>
              </Grid>

              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-1 px-1'>
                <Typography variant="h5" color="inherit">Meta Content</Typography>
              </Grid>

              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  name='meta_title'
                  label="Meta Title"
                  variant="outlined"
                  size="small"
                  value={cardFormData?.meta_title}
                  error={errors?.meta_title}
                  helperText={errors?.meta_title}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  name='meta_keywords'
                  label="Meta Keywords"
                  variant="outlined"
                  size="small"
                  value={cardFormData?.meta_keywords}
                  error={errors?.meta_keywords}
                  helperText={errors?.meta_keywords}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  name='meta_description'
                  label="Meta Description"
                  variant="outlined"
                  size="small"
                  value={cardFormData?.meta_description}
                  error={errors?.meta_description}
                  helperText={errors?.meta_description}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>

            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      {/* delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }} onClick={() => deleteCard(ID)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* import file dialog */}
      <Dialog
        open={importCardModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => closeImpCsvModal()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Import Cards
        </DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: '25px' }}>
            <div className='CSVReaderInp' style={{ marginBottom: '10px' }}>
              <span className='FileNam'>
                {fileDetails && fileDetails?.name}
              </span>
              <Button color="primary" variant='contained' style={{ marginLeft: 'auto' }}>
                Browse
              </Button>
              <CSVReader
                onFileLoaded={handleCSVData}
                parserOptions={{ header: true }}
              />
            </div>
            <div className='SampleCsv'>
              Download <span onClick={() => cardsDemoCsv()}>Sample.csv</span>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="default" variant='contained' onClick={() => closeImpCsvModal()}>
            Cancel
          </Button>
          {fileDetails &&
            <Button color="primary" variant='contained' onClick={() => importCsvData()}>
              Import
            </Button>
          }
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default CardList
