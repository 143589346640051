import { CREATE_CARD_NETWORK_ERROR, CREATE_CARD_NETWORK_LOADING, CREATE_CARD_NETWORK_SUCCESS, DELETE_CARD_NETWORK_ERROR, DELETE_CARD_NETWORK_LOADING, DELETE_CARD_NETWORK_SUCCESS, EXPORT_ALL_CSV_CARD_NETWORKS_ERROR, EXPORT_ALL_CSV_CARD_NETWORKS_LOADING, EXPORT_ALL_CSV_CARD_NETWORKS_SUCCESS, GET_ALL_CARD_NETWORKS_ERROR, GET_ALL_CARD_NETWORKS_LOADING, GET_ALL_CARD_NETWORKS_SUCCESS, IMPORT_ALL_CSV_CARD_NETWORKS_ERROR, IMPORT_ALL_CSV_CARD_NETWORKS_LOADING, IMPORT_ALL_CSV_CARD_NETWORKS_SUCCESS, SEARCH_CARD_NETWORK_ERROR, SEARCH_CARD_NETWORK_LOADING, SEARCH_CARD_NETWORK_SUCCESS, UPDATE_CARD_NETWORK_ERROR, UPDATE_CARD_NETWORK_LOADING, UPDATE_CARD_NETWORK_STATUS_ERROR, UPDATE_CARD_NETWORK_STATUS_LOADING, UPDATE_CARD_NETWORK_STATUS_SUCCESS, UPDATE_CARD_NETWORK_SUCCESS } from "./actionTypes";

const initialState = {
    getAllCardNetworks: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvCardNetworks: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvImportCardNetworks: {
        loading: false,
        data: null,
        error: null
    },
    createCardNetwork: {
        loading: false,
        data: null,
        error: null
    },
    updateCardNetwork: {
        loading: false,
        data: null,
        error: null
    },
    updateCardNetworkStatus: {
        loading: false,
        data: null,
        error: null
    },
    deleteCardNetwork: {
        loading: false,
        data: null,
        error: null
    },
    searchCardNetwork: {
        loading: false,
        data: null,
        error: null
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CARD_NETWORKS_LOADING:
            return { ...state, getAllCardNetworks: { ...state.getAllCardNetworks, loading: true, data: null, error: null } };
        case GET_ALL_CARD_NETWORKS_SUCCESS:
            return { ...state, getAllCardNetworks: { ...state.getAllCardNetworks, loading: false, data: action.payload, error: null } };
        case GET_ALL_CARD_NETWORKS_ERROR:
            return { ...state, getAllCardNetworks: { ...state.getAllCardNetworks, loading: false, data: null, error: action.payload } };

        case EXPORT_ALL_CSV_CARD_NETWORKS_LOADING:
            return { ...state, getAllCsvCardNetworks: { ...state.getAllCsvCardNetworks, loading: true, data: null, error: null } };
        case EXPORT_ALL_CSV_CARD_NETWORKS_SUCCESS:
            return { ...state, getAllCsvCardNetworks: { ...state.getAllCsvCardNetworks, loading: false, data: action.payload, error: null } };
        case EXPORT_ALL_CSV_CARD_NETWORKS_ERROR:
            return { ...state, getAllCsvCardNetworks: { ...state.getAllCsvCardNetworks, loading: false, data: null, error: action.payload } };

        case IMPORT_ALL_CSV_CARD_NETWORKS_LOADING:
            return { ...state, getAllCsvImportCardNetworks: { ...state.getAllCsvImportCardNetworks, loading: true, data: null, error: null } };
        case IMPORT_ALL_CSV_CARD_NETWORKS_SUCCESS:
            return { ...state, getAllCsvImportCardNetworks: { ...state.getAllCsvImportCardNetworks, loading: false, data: action.payload, error: null } };
        case IMPORT_ALL_CSV_CARD_NETWORKS_ERROR:
            return { ...state, getAllCsvImportCardNetworks: { ...state.getAllCsvImportCardNetworks, loading: false, data: null, error: action.payload } };

        case CREATE_CARD_NETWORK_LOADING:
            return { ...state, createCardNetwork: { ...state.createCardNetwork, loading: true, data: null, error: null } };
        case CREATE_CARD_NETWORK_SUCCESS:
            return { ...state, createCardNetwork: { ...state.createCardNetwork, loading: false, data: action.payload, error: null } };
        case CREATE_CARD_NETWORK_ERROR:
            return { ...state, createCardNetwork: { ...state.createCardNetwork, loading: false, data: null, error: action.payload } };

        case UPDATE_CARD_NETWORK_LOADING:
            return { ...state, updateCardNetwork: { ...state.updateCardNetwork, loading: true, data: null, error: null } };
        case UPDATE_CARD_NETWORK_SUCCESS:
            return { ...state, updateCardNetwork: { ...state.updateCardNetwork, loading: false, data: action.payload, error: null } };
        case UPDATE_CARD_NETWORK_ERROR:
            return { ...state, updateCardNetwork: { ...state.updateCardNetwork, loading: false, data: null, error: action.payload } };

        case UPDATE_CARD_NETWORK_STATUS_LOADING:
            return { ...state, updateCardNetworkStatus: { ...state.updateCardNetworkStatus, loading: true, data: null, error: null } };
        case UPDATE_CARD_NETWORK_STATUS_SUCCESS:
            return { ...state, updateCardNetworkStatus: { ...state.updateCardNetworkStatus, loading: false, data: action.payload, error: null } };
        case UPDATE_CARD_NETWORK_STATUS_ERROR:
            return { ...state, updateCardNetworkStatus: { ...state.updateCardNetworkStatus, loading: false, data: null, error: action.payload } };

        case DELETE_CARD_NETWORK_LOADING:
            return { ...state, deleteCardNetwork: { ...state.deleteCardNetwork, loading: true, data: null, error: null } };
        case DELETE_CARD_NETWORK_SUCCESS:
            return { ...state, deleteCardNetwork: { ...state.deleteCardNetwork, loading: false, data: action.payload, error: null } };
        case DELETE_CARD_NETWORK_ERROR:
            return { ...state, deleteCardNetwork: { ...state.deleteCardNetwork, loading: false, data: null, error: action.payload } };

        case SEARCH_CARD_NETWORK_LOADING:
            return { ...state, searchCardNetwork: { ...state.searchCardNetwork, loading: true, data: null, error: null } };
        case SEARCH_CARD_NETWORK_SUCCESS:
            return { ...state, searchCardNetwork: { ...state.searchCardNetwork, loading: false, data: action.payload, error: null } };
        case SEARCH_CARD_NETWORK_ERROR:
            return { ...state, searchCardNetwork: { ...state.searchCardNetwork, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
