import { GET_ALL_PROVIDER_TYPES_LOADING, GET_ALL_PROVIDER_TYPES_SUCCESS, GET_ALL_PROVIDER_TYPES_ERROR, CREATE_PROVIDER_TYPE_LOADING, CREATE_PROVIDER_TYPE_SUCCESS, CREATE_PROVIDER_TYPE_ERROR, UPDATE_PROVIDER_TYPE_LOADING, UPDATE_PROVIDER_TYPE_SUCCESS, UPDATE_PROVIDER_TYPE_ERROR, DELETE_PROVIDER_TYPE_LOADING, DELETE_PROVIDER_TYPE_SUCCESS, DELETE_PROVIDER_TYPE_ERROR, UPDATE_PROVIDER_TYPE_STATUS_LOADING, UPDATE_PROVIDER_TYPE_STATUS_SUCCESS, UPDATE_PROVIDER_TYPE_STATUS_ERROR, SEARCH_PROVIDER_TYPE_LOADING, SEARCH_PROVIDER_TYPE_SUCCESS, SEARCH_PROVIDER_TYPE_ERROR, EXPORT_ALL_CSV_PROVIDER_TYPES_LOADING, EXPORT_ALL_CSV_PROVIDER_TYPES_SUCCESS, EXPORT_ALL_CSV_PROVIDER_TYPES_ERROR, IMPORT_ALL_CSV_PROVIDER_TYPES_LOADING, IMPORT_ALL_CSV_PROVIDER_TYPES_SUCCESS, IMPORT_ALL_CSV_PROVIDER_TYPES_ERROR } from './actionTypes';

// get all ProviderTypes
const getAllProviderTypesLoading = () => {
  return {
    type: GET_ALL_PROVIDER_TYPES_LOADING,
  };
};

const getAllProviderTypesSuccess = (data) => {
  return {
    type: GET_ALL_PROVIDER_TYPES_SUCCESS,
    payload: data,
  };
};

const getAllProviderTypesError = (error) => {
  return {
    type: GET_ALL_PROVIDER_TYPES_ERROR,
    payload: error,
  };
};

// export all csv ProviderTypes
const exportAllCsvProviderTypesLoading = () => {
  return {
    type: EXPORT_ALL_CSV_PROVIDER_TYPES_LOADING,
  };
};

const exportAllCsvProviderTypesSuccess = (data) => {
  return {
    type: EXPORT_ALL_CSV_PROVIDER_TYPES_SUCCESS,
    payload: data,
  };
};

const exportAllCsvProviderTypesError = (error) => {
  return {
    type: EXPORT_ALL_CSV_PROVIDER_TYPES_ERROR,
    payload: error,
  };
};

// import all csv ProviderTypes
const importAllCsvProviderTypesLoading = () => {
  return {
    type: IMPORT_ALL_CSV_PROVIDER_TYPES_LOADING,
  };
};

const importAllCsvProviderTypesSuccess = (data) => {
  return {
    type: IMPORT_ALL_CSV_PROVIDER_TYPES_SUCCESS,
    payload: data,
  };
};

const importAllCsvProviderTypesError = (error) => {
  return {
    type: IMPORT_ALL_CSV_PROVIDER_TYPES_ERROR,
    payload: error,
  };
};

// create provider
const createProviderTypeLoading = () => {
  return {
    type: CREATE_PROVIDER_TYPE_LOADING,
  };
};

const createProviderTypeSuccess = (data) => {
  return {
    type: CREATE_PROVIDER_TYPE_SUCCESS,
    payload: data,
  };
};

const createProviderTypeError = (error) => {
  return {
    type: CREATE_PROVIDER_TYPE_ERROR,
    payload: error,
  };
};

// update provider
const updateProviderTypeLoading = () => {
  return {
    type: UPDATE_PROVIDER_TYPE_LOADING,
  };
};

const updateProviderTypeSuccess = (data) => {
  return {
    type: UPDATE_PROVIDER_TYPE_SUCCESS,
    payload: data,
  };
};

const updateProviderTypeError = (error) => {
  return {
    type: UPDATE_PROVIDER_TYPE_ERROR,
    payload: error,
  };
};

// update provider status
const updateProviderTypeStatusLoading = () => {
  return {
    type: UPDATE_PROVIDER_TYPE_STATUS_LOADING,
  };
};

const updateProviderTypeStatusSuccess = (data) => {
  return {
    type: UPDATE_PROVIDER_TYPE_STATUS_SUCCESS,
    payload: data,
  };
};

const updateProviderTypeStatusError = (error) => {
  return {
    type: UPDATE_PROVIDER_TYPE_STATUS_ERROR,
    payload: error,
  };
};

// delete provider
const deleteProviderTypeLoading = () => {
  return {
    type: DELETE_PROVIDER_TYPE_LOADING,
  };
};

const deleteProviderTypeSuccess = (data) => {
  return {
    type: DELETE_PROVIDER_TYPE_SUCCESS,
    payload: data,
  };
};

const deleteProviderTypeError = (error) => {
  return {
    type: DELETE_PROVIDER_TYPE_ERROR,
    payload: error,
  };
};

// search provider
const searchProviderTypeLoading = () => {
  return {
    type: SEARCH_PROVIDER_TYPE_LOADING,
  };
};

const searchProviderTypeSuccess = (data) => {
  return {
    type: SEARCH_PROVIDER_TYPE_SUCCESS,
    payload: data,
  };
};

const searchProviderTypeError = (error) => {
  return {
    type: SEARCH_PROVIDER_TYPE_ERROR,
    payload: error,
  };
};

export { 
    getAllProviderTypesLoading, 
    getAllProviderTypesSuccess, 
    getAllProviderTypesError,

    exportAllCsvProviderTypesLoading, 
    exportAllCsvProviderTypesSuccess, 
    exportAllCsvProviderTypesError,

    importAllCsvProviderTypesLoading, 
    importAllCsvProviderTypesSuccess, 
    importAllCsvProviderTypesError,

    createProviderTypeLoading, 
    createProviderTypeSuccess, 
    createProviderTypeError,

    updateProviderTypeLoading, 
    updateProviderTypeSuccess, 
    updateProviderTypeError,

    updateProviderTypeStatusLoading, 
    updateProviderTypeStatusSuccess, 
    updateProviderTypeStatusError,

    deleteProviderTypeLoading, 
    deleteProviderTypeSuccess, 
    deleteProviderTypeError,

    searchProviderTypeLoading, 
    searchProviderTypeSuccess, 
    searchProviderTypeError
};
