import { getAllCardNetworksLoading, getAllCardNetworksSuccess, getAllCardNetworksError, createCardNetworkLoading, createCardNetworkSuccess, createCardNetworkError, updateCardNetworkLoading, updateCardNetworkSuccess, updateCardNetworkError, deleteCardNetworkLoading, deleteCardNetworkSuccess, deleteCardNetworkError, updateCardNetworkStatusLoading, updateCardNetworkStatusSuccess, updateCardNetworkStatusError, searchCardNetworkLoading, searchCardNetworkSuccess, searchCardNetworkError, exportAllCsvCardNetworksLoading, exportAllCsvCardNetworksSuccess, exportAllCsvCardNetworksError, importAllCsvCardNetworksLoading, importAllCsvCardNetworksSuccess, importAllCsvCardNetworksError } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllCardNetworksAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllCardNetworksLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&name=${filter?.name}&status=${filter?.status}&created_by=${filter?.created_by}`;
      const response = await api.get(`card-network/get/all?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllCardNetworksSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllCardNetworksError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const getAllCsvCardNetworksAction = (filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(exportAllCsvCardNetworksLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&name=${filter?.name}&type_id=${filter?.type_id}&status=${filter?.status}&created_by=${filter?.created_by}`;
      const response = await api.get(`card-network/export/csv?${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(exportAllCsvCardNetworksSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(exportAllCsvCardNetworksError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const importCsvCardNetworkAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(importAllCsvCardNetworksLoading());
      const response = await api.post('card-network/import/csv', postData);
      const data = response.data;
      dispatch(importAllCsvCardNetworksSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(importAllCsvCardNetworksError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const createCardNetworkAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(createCardNetworkLoading());
      const response = await api.post('card-network/create', postData);
      const data = response.data;
      dispatch(createCardNetworkSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(createCardNetworkError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateCardNetworkAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateCardNetworkLoading());
      const response = await api.post('card-network/update', postData);
      const data = response.data;
      dispatch(updateCardNetworkSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateCardNetworkError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateCardNetworkStatusAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateCardNetworkStatusLoading());
      const response = await api.post('card-network/update/status', postData);
      const data = response.data;
      dispatch(updateCardNetworkStatusSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateCardNetworkStatusError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const deleteCardNetworkAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(deleteCardNetworkLoading());
      const response = await api.post('card-network/delete', postData);
      const data = response.data;
      dispatch(deleteCardNetworkSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(deleteCardNetworkError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const searchCardNetworkAction = (qry) => {
  return async (dispatch) => {
    try {
      dispatch(searchCardNetworkLoading());
      const response = await api.get(`card-network/search?search_query=${qry}`);
      const data = response.data;
      dispatch(searchCardNetworkSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      dispatch(searchCardNetworkError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllCardNetworksAction,
  getAllCsvCardNetworksAction,
  importCsvCardNetworkAction,
  createCardNetworkAction,
  updateCardNetworkAction,
  updateCardNetworkStatusAction,
  deleteCardNetworkAction,
  searchCardNetworkAction
};
