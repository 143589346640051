import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Grow, InputLabel, Menu, MenuItem, Paper, Select, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import { createNewsLetterAction, deleteNewsLetterAction, getAllCsvNewsLettersAction, getAllNewsLettersAction, importCsvNewsLetterAction, updateNewsLetterAction, updateNewsLetterStatusAction } from '../../store/news-letters/asyncActions';
import { Autocomplete } from '@material-ui/lab';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { getAllPermissionAction } from '../../store/role/asyncActions';
import { exportCSV, statusColor } from '../../helpers';
import CSVReader from 'react-csv-reader';
import { toast } from 'react-toastify';
import moment from 'moment';
import { searchAdminUserAction } from '../../store/admin/asyncActions';


const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  }
}));

function NewsLetters() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, setID] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();

  const [newsLetters, setNewsLetters] = useState(null);
  const [createdByList, setCreatedByList] = useState(null);

  const newsLetterList = useSelector((state) => state?.newsLetter?.getAllNewsLetters);
  const csvNewsLetterList = useSelector((state) => state?.newsLetter?.getAllCsvNewsLetters);
  const importNewsLetterList = useSelector((state) => state?.newsLetter?.getAllCsvImportNewsLetters);
  const newNewsLetter = useSelector((state) => state?.newsLetter?.createNewsLetter);
  const updatedNewsLetter = useSelector((state) => state?.newsLetter?.updateNewsLetter);
  const updatedNewsLetterStatus = useSelector((state) => state?.newsLetter?.updateNewsLetterStatus);
  const deletedNewsLetter = useSelector((state) => state?.newsLetter?.deleteNewsLetter);
  const createdByData = useSelector((state) => state?.admin?.adminUserSearch);

  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermission, setAdminPermission] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
    dispatch(searchAdminUserAction(''))
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermission(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    if (createdByData?.data?.status && Array.isArray(createdByData?.data?.data)) {
      setCreatedByList(createdByData?.data?.data)
    }
  }, [createdByData]);

  useEffect(() => {
    dispatch(getAllNewsLettersAction(page + 1, pageSize, filter));
  }, [page, pageSize]);

  useEffect(() => {
    document.title = 'News Letters';
  }, []);

  useEffect(() => {
    if (newsLetterList?.data?.status && Array.isArray(newsLetterList?.data?.data?.records)) {
      setNewsLetters(newsLetterList?.data?.data?.records);
      setTotalCount(newsLetterList?.data?.data?.totalCount);
    }
  }, [newsLetterList]);

  useEffect(() => {
    if (csvNewsLetterList?.data?.status && Array.isArray(csvNewsLetterList?.data?.data?.records)) {
      exportCSV(csvNewsLetterList?.data?.data?.records, 'News_Letters.csv');
      setCsvLoader(false);
      csvNewsLetterList.data = null;
    }
  }, [csvNewsLetterList]);

  useEffect(() => {
    if (importNewsLetterList?.data?.status) {
      setCsvLoader(false);
      dispatch(getAllNewsLettersAction(page + 1, pageSize, filter));
      setFileData(null);
      setFileDetails(null);
      importNewsLetterList.data = null;
    }
  }, [importNewsLetterList]);

  useEffect(() => {
    if (newNewsLetter?.data?.status) {
      setOpenModal(false);
      dispatch(getAllNewsLettersAction(page + 1, pageSize, filter));
      resetForm();
      newNewsLetter.data = null;
    }
  }, [newNewsLetter]);

  useEffect(() => {
    if (updatedNewsLetter?.data?.status) {
      setOpenModal(false);
      dispatch(getAllNewsLettersAction(page + 1, pageSize, filter));
      resetForm();
      updatedNewsLetter.data = null;
    }
  }, [updatedNewsLetter]);

  useEffect(() => {
    if (updatedNewsLetterStatus?.data?.status) {
      dispatch(getAllNewsLettersAction(page + 1, pageSize, filter));
      updatedNewsLetterStatus.data = null;
    }
  }, [updatedNewsLetterStatus]);

  useEffect(() => {
    if (deletedNewsLetter?.data?.status) {
      dispatch(getAllNewsLettersAction(page + 1, pageSize, filter));
      setDeleteModal(false);
      deletedNewsLetter.data = null;
    }
  }, [deletedNewsLetter]);

  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState({
    email: '',
    status: '',
  });

  const filterHandleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  }

  const filterApply = () => {
    dispatch(getAllNewsLettersAction(page + 1, pageSize, filter));
  }

  const filterClear = () => {
    const reset = {
      email: '',
      status: '',
    }
    setFilter(reset);
    setCreatedBy(null);
    dispatch(getAllNewsLettersAction(page + 1, pageSize, reset));
  }

  const [newsLetterFormData, setNewsLetterFormData] = useState({
    email: '',
    status: 'Subscribe'
  });

  const [createdBy, setCreatedBy] = useState(null);

  const resetForm = () => {
    setErrors({});
    setNewsLetterFormData({
      email: '',
      status: 'Subscribe'
    });
  }

  const DataHandleChange = (e) => {
    setNewsLetterFormData({
      ...newsLetterFormData,
      [e.target.name]: e.target.value
    });
  }

  const addNew = () => {
    resetForm();
    setOpenModal(true);
  }

  const editNewsLetter = (row) => {
    setErrors({});
    setNewsLetterFormData({
      id: row?._id,
      email: row?.email,
      status: row?.status
    });
    setOpenModal(true);
  }

  const createNewsLetter = () => {
    dispatch(createNewsLetterAction(newsLetterFormData))
  }

  const updateNewsLetter = () => {
    dispatch(updateNewsLetterAction(newsLetterFormData))
  }

  const updateNewsLetterStatus = (data) => {
    {
      adminPermission && (adminPermission.includes('Edit News Letter') || adminPermission.includes('All')) &&
        dispatch(updateNewsLetterStatusAction(data))
    }
  }

  const save = () => {
    const validationResult = validationSchema.validate(newsLetterFormData, { abortEarly: false });
    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
      console.log(validationErrors);
    } else {
      setErrors({});
      if (newsLetterFormData?.id && newsLetterFormData?.id !== '' && newsLetterFormData?.id !== null) {
        updateNewsLetter()
      } else {
        createNewsLetter()
      }
    }
  }

  const deleteNewsLetter = (id) => {
    dispatch(deleteNewsLetterAction({ id: id }));
  }

  const [errors, setErrors] = useState({});
  const validationSchema = Joi.object({
    id: Joi.string().allow(null, ''),
    email: Joi.string().email({ tlds: { allow: false } }).required(),
    status: Joi.string().valid('Subscribe', 'Unsubscribe').required()
  });


  const [importNewsLetterModal, setImportNewsLetterModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [csvLoader, setCsvLoader] = useState(false);
  const [fileDetails, setFileDetails] = useState(null);
  const [fileData, setFileData] = useState(null);
  const exportCsvData = () => {
    setCsvLoader(true);
    dispatch(getAllCsvNewsLettersAction(filter));
    setAnchorEl(null);
  }
  const handleCSVData = (data, fileInfo) => {
    if (fileInfo?.type !== 'text/csv') {
      toast.error('Invalid file format!');
      setAnchorEl(null);
      return;
    }
    if (data && Array.isArray(data)) {
      setFileData(data);
      setFileDetails(fileInfo);
      setAnchorEl(null);
    }
  };
  const importCsvData = () => {
    setImportNewsLetterModal(false);
    setCsvLoader(true);
    dispatch(importCsvNewsLetterAction({ newsLetterData: fileData }));
  }
  const closeImpCsvModal = () => {
    setImportNewsLetterModal(false);
    setFileDetails(null);
    setFileData(null);
  }

  const newsLettersDemoCsv = () => {
    const demo = [
      {
        _id: "650af7e88a3ea2e1e91b880a",
        newsLetter_name: `"Inspection, measurement and testing equipment"`,
        description: `"description..."`,
        category: "650ae7b58f69cd6f4f48a965",
        price: 500
      },
      {
        _id: "650af7e88a3ea2e1e91b87f6",
        newsLetter_name: `"Coffee, Tea & Beverages"`,
        description: `"description..."`,
        category: "650ae7b58f69cd6f4f48a963",
        price: 500
      }
    ];
    exportCSV(demo, 'provider_types_demo.csv');
  }

  return (
    <div>
      <Card className='pageCover'>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant='h5' color="textSecondary" className="flexSpacer">
              News Letters
            </Typography>

            {/* <div style={{ display: 'flex', alignNewsLetters: 'center' }}>
              <Button
                color='default' variant='outlined' className='mr-1'
                aria-owns={anchorEl ? 'csv-menu' : null}
                aria-haspopup="true"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                disabled={csvLoader}
              >
                Export / Import
                {csvLoader && <CircularProgress style={{ width: '20px', height: '20px' }} className='ml-1' />}
              </Button>
              <Menu
                id="csv-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => exportCsvData()}>Export CSV</MenuItem>
                <MenuItem style={{ width: '133px' }} onClick={() => setImportNewsLetterModal(true)}>
                  Import CSV
                </MenuItem>
              </Menu>
            </div> */}

            {adminPermission && (adminPermission.includes('Add News Letter') || adminPermission.includes('All')) &&
              <Tooltip title={'Add News Letter'} placement="left">
                <Button color='default' variant='outlined' onClick={() => addNew()}>+ Add New</Button>
              </Tooltip>
            }
            <Tooltip title={'Apply Filter On Data'} placement="left">
              <Button color='default' variant='outlined' className='ml-1' onClick={() => setOpenFilter(!openFilter)}>
                <span className='mr-1'>Filter</span> {openFilter ? <ExpandLessRoundedIcon className='btnIco' /> : <ExpandMoreRoundedIcon className='btnIco' />}
              </Button>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box className={`filterOuter ${openFilter && 'activeOpen'}`}>
          <Grid container style={{ width: '100%', paddingTop: '25px' }}>
            <Grid newsLetter xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                name='email'
                label="E-Mail"
                variant="outlined"
                size="small"
                value={filter?.email}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid newsLetter xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                <Select
                  name='status'
                  label="Status"
                  value={filter?.status}
                  onChange={(e) => filterHandleChange(e)}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Subscribe">Subscribe</MenuItem>
                  <MenuItem value="Unsubscribe">Unsubscribe</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid newsLetter xs={6} sm={6} md={2} xl={2} className='mb-2 px-1' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='primary' variant='contained' onClick={() => filterApply()}>Apply</Button>
              <Button color='default' variant='outlined' className='ml-1' onClick={() => filterClear()}>Clear</Button>
            </Grid>
          </Grid>
        </Box>
        <Paper>
          <TableContainer className='Custom_Datatable'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>E-Mail</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Status</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Created At</TableCell>
                  <TableCell style={{ minWidth: '125px' }} align='right'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newsLetters && newsLetters.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                      <TableCell>{row?.email}</TableCell>
                      <TableCell>
                        <div className='StatusDiv'>
                          <div className={`StatusDot ${statusColor(row?.status === 'Subscribe' ? 'Active' : 'Inactive')}`}></div>
                          {row?.status}
                        </div>
                      </TableCell>
                      <TableCell>
                        {moment(row?.createdAt).format('DD-MM-YYYY hh:mmA')}
                      </TableCell>
                      <TableCell align='right'>
                        {adminPermission && (adminPermission.includes('Edit NewsLetter') || adminPermission.includes('All')) &&
                          <Tooltip title={'Edit NewsLetter'} placement="left">
                            <IconButton onClick={() => editNewsLetter(row)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        }
                        {adminPermission && (adminPermission.includes('Delete NewsLetter') || adminPermission.includes('All')) &&
                          <Tooltip title={'Delete NewsLetter'} placement="left">
                            <IconButton onClick={() => { setID(row?._id); setDeleteModal(true) }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            onChangePage={(e, page) => handlePageChange(page)}
            onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
          />
        </Paper>
      </Card>

      {/* NewsLetter form dialog */}
      <Dialog
        fullScreen
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => setOpenModal(false)} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              {newsLetterFormData?.id ? 'Edit News Letter' : 'Create News Letter'}
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }} onClick={() => save()}>
              {newsLetterFormData?.id ? 'Update' : 'Save'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <Box>
            <Grid container style={{ maxWidth: '450px', paddingTop: '25px' }}>
              <Grid newsLetter xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='email'
                  label="E-Mail"
                  variant="outlined"
                  size="small"
                  value={newsLetterFormData?.email}
                  error={errors?.email}
                  helperText={errors?.email}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Box className='statusBox'>
                  <Typography color="inherit" className="flexSpacer">
                    <div>Status</div>
                  </Typography>
                  <FormControlLabel
                    style={{ marginLeft: 'auto', marginRight: 0 }}
                    control={
                      <Switch
                        checked={newsLetterFormData?.status === 'Subscribe'}
                        onChange={(e) => setNewsLetterFormData({ 
                          ...newsLetterFormData, 
                          status: e?.target?.checked ? 'Subscribe' : 'Unsubscribe'
                        })}
                        color="primary"
                      />
                    }
                    label={newsLetterFormData?.status ? 'Subscribe' : 'Unsubscribe'}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      {/* delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }} onClick={() => deleteNewsLetter(ID)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* import file dialog */}
      <Dialog
        open={importNewsLetterModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => closeImpCsvModal()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Import NewsLetters
        </DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: '25px' }}>
            <div className='CSVReaderInp' style={{ marginBottom: '10px' }}>
              <span className='FileNam'>
                {fileDetails && fileDetails?.name}
              </span>
              <Button color="primary" variant='contained' style={{ marginLeft: 'auto' }}>
                Browse
              </Button>
              <CSVReader
                onFileLoaded={handleCSVData}
                parserOptions={{ header: true }}
              />
            </div>
            <div className='SampleCsv'>
              Download <span onClick={() => newsLettersDemoCsv()}>Sample.csv</span>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="default" variant='contained' onClick={() => closeImpCsvModal()}>
            Cancel
          </Button>
          {fileDetails &&
            <Button color="primary" variant='contained' onClick={() => importCsvData()}>
              Import
            </Button>
          }
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default NewsLetters
