export const GET_ALL_CARD_OFFERS_LOADING = 'GET_ALL_CARD_OFFERS_LOADING';
export const GET_ALL_CARD_OFFERS_SUCCESS = 'GET_ALL_CARD_OFFERS_SUCCESS';
export const GET_ALL_CARD_OFFERS_ERROR = 'GET_ALL_CARD_OFFERS_ERROR';

export const EXPORT_ALL_CSV_CARD_OFFERS_LOADING = 'EXPORT_ALL_CSV_CARD_OFFERS_LOADING';
export const EXPORT_ALL_CSV_CARD_OFFERS_SUCCESS = 'EXPORT_ALL_CSV_CARD_OFFERS_SUCCESS';
export const EXPORT_ALL_CSV_CARD_OFFERS_ERROR = 'EXPORT_ALL_CSV_CARD_OFFERS_ERROR';

export const CHECK_ALL_CSV_CARD_OFFERS_LOADING = 'CHECK_ALL_CSV_CARD_OFFERS_LOADING';
export const CHECK_ALL_CSV_CARD_OFFERS_SUCCESS = 'CHECK_ALL_CSV_CARD_OFFERS_SUCCESS';
export const CHECK_ALL_CSV_CARD_OFFERS_ERROR = 'CHECK_ALL_CSV_CARD_OFFERS_ERROR';

export const IMPORT_ALL_CSV_CARD_OFFERS_LOADING = 'IMPORT_ALL_CSV_CARD_OFFERS_LOADING';
export const IMPORT_ALL_CSV_CARD_OFFERS_SUCCESS = 'IMPORT_ALL_CSV_CARD_OFFERS_SUCCESS';
export const IMPORT_ALL_CSV_CARD_OFFERS_ERROR = 'IMPORT_ALL_CSV_CARD_OFFERS_ERROR';

export const CREATE_CARD_OFFER_LOADING = 'CREATE_CARD_OFFER_LOADING';
export const CREATE_CARD_OFFER_SUCCESS = 'CREATE_CARD_OFFER_SUCCESS';
export const CREATE_CARD_OFFER_ERROR = 'CREATE_CARD_OFFER_ERROR';

export const UPDATE_CARD_OFFER_LOADING = 'UPDATE_CARD_OFFER_LOADING';
export const UPDATE_CARD_OFFER_SUCCESS = 'UPDATE_CARD_OFFER_SUCCESS';
export const UPDATE_CARD_OFFER_ERROR = 'UPDATE_CARD_OFFER_ERROR';

export const UPDATE_CARD_OFFER_STATUS_LOADING = 'UPDATE_CARD_OFFER_STATUS_LOADING';
export const UPDATE_CARD_OFFER_STATUS_SUCCESS = 'UPDATE_CARD_OFFER_STATUS_SUCCESS';
export const UPDATE_CARD_OFFER_STATUS_ERROR = 'UPDATE_CARD_OFFER_STATUS_ERROR';

export const DELETE_CARD_OFFER_LOADING = 'DELETE_CARD_OFFER_LOADING';
export const DELETE_CARD_OFFER_SUCCESS = 'DELETE_CARD_OFFER_SUCCESS';
export const DELETE_CARD_OFFER_ERROR = 'DELETE_CARD_OFFER_ERROR';

export const SEARCH_CARD_OFFER_LOADING = 'SEARCH_CARD_OFFER_LOADING';
export const SEARCH_CARD_OFFER_SUCCESS = 'SEARCH_CARD_OFFER_SUCCESS';
export const SEARCH_CARD_OFFER_ERROR = 'SEARCH_CARD_OFFER_ERROR';

export const SEARCH_COUNTRIES_LOADING = 'SEARCH_COUNTRIES_LOADING';
export const SEARCH_COUNTRIES_SUCCESS = 'SEARCH_COUNTRIES_SUCCESS';
export const SEARCH_COUNTRIES_ERROR = 'SEARCH_COUNTRIES_ERROR';

export const SEARCH_STATES_LOADING = 'SEARCH_STATES_LOADING';
export const SEARCH_STATES_SUCCESS = 'SEARCH_STATES_SUCCESS';
export const SEARCH_STATES_ERROR = 'SEARCH_STATES_ERROR';

export const SEARCH_CITIES_LOADING = 'SEARCH_CITIES_LOADING';
export const SEARCH_CITIES_SUCCESS = 'SEARCH_CITIES_SUCCESS';
export const SEARCH_CITIES_ERROR = 'SEARCH_CITIES_ERROR';
