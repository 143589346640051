import { GET_ALL_CARD_NETWORKS_LOADING, GET_ALL_CARD_NETWORKS_SUCCESS, GET_ALL_CARD_NETWORKS_ERROR, CREATE_CARD_NETWORK_LOADING, CREATE_CARD_NETWORK_SUCCESS, CREATE_CARD_NETWORK_ERROR, UPDATE_CARD_NETWORK_LOADING, UPDATE_CARD_NETWORK_SUCCESS, UPDATE_CARD_NETWORK_ERROR, DELETE_CARD_NETWORK_LOADING, DELETE_CARD_NETWORK_SUCCESS, DELETE_CARD_NETWORK_ERROR, UPDATE_CARD_NETWORK_STATUS_LOADING, UPDATE_CARD_NETWORK_STATUS_SUCCESS, UPDATE_CARD_NETWORK_STATUS_ERROR, SEARCH_CARD_NETWORK_LOADING, SEARCH_CARD_NETWORK_SUCCESS, SEARCH_CARD_NETWORK_ERROR, EXPORT_ALL_CSV_CARD_NETWORKS_LOADING, EXPORT_ALL_CSV_CARD_NETWORKS_SUCCESS, EXPORT_ALL_CSV_CARD_NETWORKS_ERROR, IMPORT_ALL_CSV_CARD_NETWORKS_LOADING, IMPORT_ALL_CSV_CARD_NETWORKS_SUCCESS, IMPORT_ALL_CSV_CARD_NETWORKS_ERROR } from './actionTypes';

// get all CardNetworks
const getAllCardNetworksLoading = () => {
  return {
    type: GET_ALL_CARD_NETWORKS_LOADING,
  };
};

const getAllCardNetworksSuccess = (data) => {
  return {
    type: GET_ALL_CARD_NETWORKS_SUCCESS,
    payload: data,
  };
};

const getAllCardNetworksError = (error) => {
  return {
    type: GET_ALL_CARD_NETWORKS_ERROR,
    payload: error,
  };
};

// export all csv CardNetworks
const exportAllCsvCardNetworksLoading = () => {
  return {
    type: EXPORT_ALL_CSV_CARD_NETWORKS_LOADING,
  };
};

const exportAllCsvCardNetworksSuccess = (data) => {
  return {
    type: EXPORT_ALL_CSV_CARD_NETWORKS_SUCCESS,
    payload: data,
  };
};

const exportAllCsvCardNetworksError = (error) => {
  return {
    type: EXPORT_ALL_CSV_CARD_NETWORKS_ERROR,
    payload: error,
  };
};

// import all csv CardNetworks
const importAllCsvCardNetworksLoading = () => {
  return {
    type: IMPORT_ALL_CSV_CARD_NETWORKS_LOADING,
  };
};

const importAllCsvCardNetworksSuccess = (data) => {
  return {
    type: IMPORT_ALL_CSV_CARD_NETWORKS_SUCCESS,
    payload: data,
  };
};

const importAllCsvCardNetworksError = (error) => {
  return {
    type: IMPORT_ALL_CSV_CARD_NETWORKS_ERROR,
    payload: error,
  };
};

// create Card Network
const createCardNetworkLoading = () => {
  return {
    type: CREATE_CARD_NETWORK_LOADING,
  };
};

const createCardNetworkSuccess = (data) => {
  return {
    type: CREATE_CARD_NETWORK_SUCCESS,
    payload: data,
  };
};

const createCardNetworkError = (error) => {
  return {
    type: CREATE_CARD_NETWORK_ERROR,
    payload: error,
  };
};

// update Card Network
const updateCardNetworkLoading = () => {
  return {
    type: UPDATE_CARD_NETWORK_LOADING,
  };
};

const updateCardNetworkSuccess = (data) => {
  return {
    type: UPDATE_CARD_NETWORK_SUCCESS,
    payload: data,
  };
};

const updateCardNetworkError = (error) => {
  return {
    type: UPDATE_CARD_NETWORK_ERROR,
    payload: error,
  };
};

// update Card Network status
const updateCardNetworkStatusLoading = () => {
  return {
    type: UPDATE_CARD_NETWORK_STATUS_LOADING,
  };
};

const updateCardNetworkStatusSuccess = (data) => {
  return {
    type: UPDATE_CARD_NETWORK_STATUS_SUCCESS,
    payload: data,
  };
};

const updateCardNetworkStatusError = (error) => {
  return {
    type: UPDATE_CARD_NETWORK_STATUS_ERROR,
    payload: error,
  };
};

// delete Card Network
const deleteCardNetworkLoading = () => {
  return {
    type: DELETE_CARD_NETWORK_LOADING,
  };
};

const deleteCardNetworkSuccess = (data) => {
  return {
    type: DELETE_CARD_NETWORK_SUCCESS,
    payload: data,
  };
};

const deleteCardNetworkError = (error) => {
  return {
    type: DELETE_CARD_NETWORK_ERROR,
    payload: error,
  };
};

// search Card Network
const searchCardNetworkLoading = () => {
  return {
    type: SEARCH_CARD_NETWORK_LOADING,
  };
};

const searchCardNetworkSuccess = (data) => {
  return {
    type: SEARCH_CARD_NETWORK_SUCCESS,
    payload: data,
  };
};

const searchCardNetworkError = (error) => {
  return {
    type: SEARCH_CARD_NETWORK_ERROR,
    payload: error,
  };
};

export { 
    getAllCardNetworksLoading, 
    getAllCardNetworksSuccess, 
    getAllCardNetworksError,

    exportAllCsvCardNetworksLoading, 
    exportAllCsvCardNetworksSuccess, 
    exportAllCsvCardNetworksError,

    importAllCsvCardNetworksLoading, 
    importAllCsvCardNetworksSuccess, 
    importAllCsvCardNetworksError,

    createCardNetworkLoading, 
    createCardNetworkSuccess, 
    createCardNetworkError,

    updateCardNetworkLoading, 
    updateCardNetworkSuccess, 
    updateCardNetworkError,

    updateCardNetworkStatusLoading, 
    updateCardNetworkStatusSuccess, 
    updateCardNetworkStatusError,

    deleteCardNetworkLoading, 
    deleteCardNetworkSuccess, 
    deleteCardNetworkError,

    searchCardNetworkLoading, 
    searchCardNetworkSuccess, 
    searchCardNetworkError
};
