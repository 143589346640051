import { CREATE_CARD_ERROR, CREATE_CARD_LOADING, CREATE_CARD_SUCCESS, DELETE_CARD_ERROR, DELETE_CARD_LOADING, DELETE_CARD_SUCCESS, EXPORT_ALL_CSV_CARDS_ERROR, EXPORT_ALL_CSV_CARDS_LOADING, EXPORT_ALL_CSV_CARDS_SUCCESS, GET_ALL_CARDS_ERROR, GET_ALL_CARDS_LOADING, GET_ALL_CARDS_SUCCESS, IMPORT_ALL_CSV_CARDS_ERROR, IMPORT_ALL_CSV_CARDS_LOADING, IMPORT_ALL_CSV_CARDS_SUCCESS, SEARCH_CARD_ERROR, SEARCH_CARD_LOADING, SEARCH_CARD_SUCCESS, UPDATE_CARD_ERROR, UPDATE_CARD_LOADING, UPDATE_CARD_STATUS_ERROR, UPDATE_CARD_STATUS_LOADING, UPDATE_CARD_STATUS_SUCCESS, UPDATE_CARD_SUCCESS } from "./actionTypes";

const initialState = {
    getAllCards: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvCards: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvImportCards: {
        loading: false,
        data: null,
        error: null
    },
    createCard: {
        loading: false,
        data: null,
        error: null
    },
    updateCard: {
        loading: false,
        data: null,
        error: null
    },
    updateCardStatus: {
        loading: false,
        data: null,
        error: null
    },
    deleteCard: {
        loading: false,
        data: null,
        error: null
    },
    searchCard: {
        loading: false,
        data: null,
        error: null
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CARDS_LOADING:
            return { ...state, getAllCards: { ...state.getAllCards, loading: true, data: null, error: null } };
        case GET_ALL_CARDS_SUCCESS:
            return { ...state, getAllCards: { ...state.getAllCards, loading: false, data: action.payload, error: null } };
        case GET_ALL_CARDS_ERROR:
            return { ...state, getAllCards: { ...state.getAllCards, loading: false, data: null, error: action.payload } };

        case EXPORT_ALL_CSV_CARDS_LOADING:
            return { ...state, getAllCsvCards: { ...state.getAllCsvCards, loading: true, data: null, error: null } };
        case EXPORT_ALL_CSV_CARDS_SUCCESS:
            return { ...state, getAllCsvCards: { ...state.getAllCsvCards, loading: false, data: action.payload, error: null } };
        case EXPORT_ALL_CSV_CARDS_ERROR:
            return { ...state, getAllCsvCards: { ...state.getAllCsvCards, loading: false, data: null, error: action.payload } };

        case IMPORT_ALL_CSV_CARDS_LOADING:
            return { ...state, getAllCsvImportCards: { ...state.getAllCsvImportCards, loading: true, data: null, error: null } };
        case IMPORT_ALL_CSV_CARDS_SUCCESS:
            return { ...state, getAllCsvImportCards: { ...state.getAllCsvImportCards, loading: false, data: action.payload, error: null } };
        case IMPORT_ALL_CSV_CARDS_ERROR:
            return { ...state, getAllCsvImportCards: { ...state.getAllCsvImportCards, loading: false, data: null, error: action.payload } };

        case CREATE_CARD_LOADING:
            return { ...state, createCard: { ...state.createCard, loading: true, data: null, error: null } };
        case CREATE_CARD_SUCCESS:
            return { ...state, createCard: { ...state.createCard, loading: false, data: action.payload, error: null } };
        case CREATE_CARD_ERROR:
            return { ...state, createCard: { ...state.createCard, loading: false, data: null, error: action.payload } };

        case UPDATE_CARD_LOADING:
            return { ...state, updateCard: { ...state.updateCard, loading: true, data: null, error: null } };
        case UPDATE_CARD_SUCCESS:
            return { ...state, updateCard: { ...state.updateCard, loading: false, data: action.payload, error: null } };
        case UPDATE_CARD_ERROR:
            return { ...state, updateCard: { ...state.updateCard, loading: false, data: null, error: action.payload } };

        case UPDATE_CARD_STATUS_LOADING:
            return { ...state, updateCardStatus: { ...state.updateCardStatus, loading: true, data: null, error: null } };
        case UPDATE_CARD_STATUS_SUCCESS:
            return { ...state, updateCardStatus: { ...state.updateCardStatus, loading: false, data: action.payload, error: null } };
        case UPDATE_CARD_STATUS_ERROR:
            return { ...state, updateCardStatus: { ...state.updateCardStatus, loading: false, data: null, error: action.payload } };

        case DELETE_CARD_LOADING:
            return { ...state, deleteCard: { ...state.deleteCard, loading: true, data: null, error: null } };
        case DELETE_CARD_SUCCESS:
            return { ...state, deleteCard: { ...state.deleteCard, loading: false, data: action.payload, error: null } };
        case DELETE_CARD_ERROR:
            return { ...state, deleteCard: { ...state.deleteCard, loading: false, data: null, error: action.payload } };

        case SEARCH_CARD_LOADING:
            return { ...state, searchCard: { ...state.searchCard, loading: true, data: null, error: null } };
        case SEARCH_CARD_SUCCESS:
            return { ...state, searchCard: { ...state.searchCard, loading: false, data: action.payload, error: null } };
        case SEARCH_CARD_ERROR:
            return { ...state, searchCard: { ...state.searchCard, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
