import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Grow, InputLabel, Menu, MenuItem, Paper, Select, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import { createProductAction, deleteProductAction, getAllCsvProductsAction, getAllProductsAction, importCsvProductAction, updateProductAction, updateProductStatusAction } from '../../store/products/asyncActions';
import { Autocomplete } from '@material-ui/lab';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { getAllPermissionAction } from '../../store/role/asyncActions';
import { createSlug, exportCSV, statusColor } from '../../helpers';
import CSVReader from 'react-csv-reader';
import { toast } from 'react-toastify';
import moment from 'moment';
import { searchAdminUserAction } from '../../store/admin/asyncActions';
import { searchCategoryAction } from '../../store/category/asyncActions';


const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  }
}));

function ProductsList() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, setID] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();

  const [products, setProducts] = useState(null);
  const [createdByList, setCreatedByList] = useState(null);
  const [categoryList, setCategoryList] = useState(null);

  const productList = useSelector((state) => state?.product?.getAllProducts);
  const csvProductList = useSelector((state) => state?.product?.getAllCsvProducts);
  const importProductList = useSelector((state) => state?.product?.getAllCsvImportProducts);
  const newProduct = useSelector((state) => state?.product?.createProduct);
  const updatedProduct = useSelector((state) => state?.product?.updateProduct);
  const updatedProductStatus = useSelector((state) => state?.product?.updateProductStatus);
  const deletedProduct = useSelector((state) => state?.product?.deleteProduct);
  const createdByData = useSelector((state) => state?.admin?.adminUserSearch);
  const searchCategoryData = useSelector((state) => state?.category?.searchCategory);

  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermission, setAdminPermission] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
    dispatch(searchAdminUserAction(''))
    dispatch(searchCategoryAction(''))
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermission(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    if (createdByData?.data?.status && Array.isArray(createdByData?.data?.data)) {
      setCreatedByList(createdByData?.data?.data)
    }
  }, [createdByData]);

  useEffect(() => {
    if (searchCategoryData?.data?.status && Array.isArray(searchCategoryData?.data?.data)) {
      setCategoryList(searchCategoryData?.data?.data)
    }
  }, [searchCategoryData]);

  useEffect(() => {
    dispatch(getAllProductsAction(page + 1, pageSize, filter));
  }, [page, pageSize]);

  useEffect(() => {
    document.title = 'Products / Brands / Services';
  }, []);

  useEffect(() => {
    if (productList?.data?.status && Array.isArray(productList?.data?.data?.records)) {
      setProducts(productList?.data?.data?.records);
      setTotalCount(productList?.data?.data?.totalCount);
    }
  }, [productList]);

  useEffect(() => {
    if (csvProductList?.data?.status && Array.isArray(csvProductList?.data?.data?.records)) {
      exportCSV(csvProductList?.data?.data?.records, 'products.csv');
      setCsvLoader(false);
      csvProductList.data = null;
    }
  }, [csvProductList]);

  useEffect(() => {
    if (importProductList?.data?.status) {
      setCsvLoader(false);
      dispatch(getAllProductsAction(page + 1, pageSize, filter));
      setFileData(null);
      setFileDetails(null);
      importProductList.data = null;
    }
  }, [importProductList]);

  useEffect(() => {
    if (newProduct?.data?.status) {
      setOpenModal(false);
      dispatch(getAllProductsAction(page + 1, pageSize, filter));
      resetForm();
      newProduct.data = null;
    }
  }, [newProduct]);

  useEffect(() => {
    if (updatedProduct?.data?.status) {
      setOpenModal(false);
      dispatch(getAllProductsAction(page + 1, pageSize, filter));
      resetForm();
      updatedProduct.data = null;
    }
  }, [updatedProduct]);

  useEffect(() => {
    if (updatedProductStatus?.data?.status) {
      dispatch(getAllProductsAction(page + 1, pageSize, filter));
      updatedProductStatus.data = null;
    }
  }, [updatedProductStatus]);

  useEffect(() => {
    if (deletedProduct?.data?.status) {
      dispatch(getAllProductsAction(page + 1, pageSize, filter));
      setDeleteModal(false);
      deletedProduct.data = null;
    }
  }, [deletedProduct]);

  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState({
    name: '',
    type: '',
    status: '',
    created_by: ''
  });

  const filterHandleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  }

  const filterApply = () => {
    dispatch(getAllProductsAction(page + 1, pageSize, filter));
  }

  const filterClear = () => {
    const reset = {
      name: '',
      type: '',
      status: '',
      created_by: ''
    }
    setFilter(reset);
    setCreatedBy(null);
    dispatch(getAllProductsAction(page + 1, pageSize, reset));
  }

  const [productFormData, setProductFormData] = useState({
    category: '',
    name: '',
    type: '',
    slug: '',
    description: '',
    status: true,
    meta_title: '',
    meta_keywords: '',
    meta_description: '',
  });

  const [createdBy, setCreatedBy] = useState(null);
  const [category, setCategory] = useState(null);

  const resetForm = () => {
    setErrors({});
    setProductFormData({
      category: '',
      name: '',
      type: '',
      slug: '',
      description: '',
      status: true,
      meta_title: '',
      meta_keywords: '',
      meta_description: '',
    });
    setCategory(null);
  }

  const DataHandleChange = (e) => {
    setProductFormData({
      ...productFormData,
      [e.target.name]: e.target.value
    });
  }

  const addNew = () => {
    resetForm();
    setOpenModal(true);
  }

  const editProduct = (row) => {
    setErrors({});
    setProductFormData({
      id: row?._id,
      category: row?.category?._id,
      name: row?.name,
      type: row?.type,
      slug: row?.slug,
      description: row?.description,
      status: row?.status,
      meta_title: row?.meta_title || '',
      meta_keywords: row?.meta_keywords || '',
      meta_description: row?.meta_description || '',
    });
    setCategory(row?.category);
    setOpenModal(true);
  }

  const createProduct = () => {
    dispatch(createProductAction(productFormData))
  }

  const updateProduct = () => {
    dispatch(updateProductAction(productFormData))
  }

  const updateProductStatus = (data) => {
    {
      adminPermission && (adminPermission.includes('Edit Product') || adminPermission.includes('All')) &&
        dispatch(updateProductStatusAction(data))
    }
  }

  const save = () => {
    const validationResult = validationSchema.validate(productFormData, { abortEarly: false });
    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
      console.log(validationErrors);
    } else {
      setErrors({});
      if (productFormData?.id && productFormData?.id !== '' && productFormData?.id !== null) {
        updateProduct()
      } else {
        createProduct()
      }
    }
  }

  const deleteProduct = (id) => {
    dispatch(deleteProductAction({ id: id }));
  }

  const [errors, setErrors] = useState({});
  const validationSchema = Joi.object({
    id: Joi.string().allow(null, ''),
    category: Joi.string().required(),
    name: Joi.string().required(),
    type: Joi.string().required(),
    slug: Joi.string().required(),
    description: Joi.string().allow(null, ''),
    status: Joi.boolean().required(),
    meta_title: Joi.string().allow(null, ''),
    meta_keywords: Joi.string().allow(null, ''),
    meta_description: Joi.string().allow(null, ''),
  });


  const [importProductModal, setImportProductModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [csvLoader, setCsvLoader] = useState(false);
  const [fileDetails, setFileDetails] = useState(null);
  const [fileData, setFileData] = useState(null);
  const exportCsvData = () => {
    setCsvLoader(true);
    dispatch(getAllCsvProductsAction(filter));
    setAnchorEl(null);
  }
  const handleCSVData = (data, fileInfo) => {
    if (fileInfo?.type !== 'text/csv') {
      toast.error('Invalid file format!');
      setAnchorEl(null);
      return;
    }
    if (data && Array.isArray(data)) {
      setFileData(data);
      setFileDetails(fileInfo);
      setAnchorEl(null);
    }
  };
  const importCsvData = () => {
    setImportProductModal(false);
    setCsvLoader(true);
    dispatch(importCsvProductAction({ productData: fileData }));
  }
  const closeImpCsvModal = () => {
    setImportProductModal(false);
    setFileDetails(null);
    setFileData(null);
  }

  const productsDemoCsv = () => {
    const demo = [
      {
        _id: "650af7e88a3ea2e1e91b880a",
        category: "650ae7b58f69cd6f4f48a965",
        name: `"Inspection, measurement and testing"`,
        type: `"service"`,
        description: `"description..."`,
      },
      {
        _id: "650af7e88a3ea2e1e91b880a",
        category: "650ae7b58f69cd6f4f48a965",
        name: `"Coffee, Tea & Beverages"`,
        type: `"product"`,
        description: `"description..."`,
      }
    ];
    exportCSV(demo, 'products_demo.csv');
  }

  return (
    <div>
      <Card className='pageCover'>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant='h5' color="textSecondary" className="flexSpacer">
              Products / Brands / Services
            </Typography>

            <div style={{ display: 'flex', alignProducts: 'center' }}>
              <Button
                color='default' variant='outlined' className='mr-1'
                aria-owns={anchorEl ? 'csv-menu' : null}
                aria-haspopup="true"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                disabled={csvLoader}
              >
                Export / Import
                {csvLoader && <CircularProgress style={{ width: '20px', height: '20px' }} className='ml-1' />}
              </Button>
              <Menu
                id="csv-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => exportCsvData()}>Export CSV</MenuItem>
                <MenuItem style={{ width: '133px' }} onClick={() => setImportProductModal(true)}>
                  Import CSV
                </MenuItem>
              </Menu>
            </div>

            {adminPermission && (adminPermission.includes('Add Product') || adminPermission.includes('All')) &&
              <Tooltip title={'Add New Product'} placement="left">
                <Button color='default' variant='outlined' onClick={() => addNew()}>+ Add New</Button>
              </Tooltip>
            }
            <Tooltip title={'Apply Filter On Data'} placement="left">
              <Button color='default' variant='outlined' className='ml-1' onClick={() => setOpenFilter(!openFilter)}>
                <span className='mr-1'>Filter</span> {openFilter ? <ExpandLessRoundedIcon className='btnIco' /> : <ExpandMoreRoundedIcon className='btnIco' />}
              </Button>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box className={`filterOuter ${openFilter && 'activeOpen'}`}>
          <Grid container style={{ width: '100%', paddingTop: '25px' }}>
            <Grid product xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                name='name'
                label="Name"
                variant="outlined"
                size="small"
                value={filter?.name}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <FormControl variant="outlined" size="small" error={errors?.type} required style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                <Select
                  name='type'
                  label="Type"
                  value={filter?.type}
                  onChange={(e) => filterHandleChange(e)}
                >
                  <MenuItem value="product">Product</MenuItem>
                  <MenuItem value="brand">Brand</MenuItem>
                  <MenuItem value="service">Service</MenuItem>
                </Select>
                <FormHelperText>{errors?.type}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid product xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                <Select
                  name='status'
                  label="Status"
                  value={filter?.status}
                  onChange={(e) => filterHandleChange(e)}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid product xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <Autocomplete
                options={createdByList || []}
                getOptionLabel={(option) => option.name}
                value={createdBy}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Created By"
                    placeholder="Search name"
                    size='small'
                    error={errors?.created_by}
                    helperText={errors?.created_by}
                  />
                )}
                onInputChange={(event, newInputValue) => {
                  dispatch(searchAdminUserAction(newInputValue))
                }}
                onChange={(event, selectedOptions) => {
                  setCreatedBy(selectedOptions);
                  setFilter({
                    ...filter,
                    created_by: selectedOptions?._id
                  });
                }}
              />
            </Grid>
            <Grid product xs={6} sm={6} md={2} xl={2} className='mb-2 px-1' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='primary' variant='contained' onClick={() => filterApply()}>Apply</Button>
              <Button color='default' variant='outlined' className='ml-1' onClick={() => filterClear()}>Clear</Button>
            </Grid>
          </Grid>
        </Box>
        <Paper>
          <TableContainer className='Custom_Datatable'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Category</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Name</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Type</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Status</TableCell>
                  <TableCell style={{ minWidth: '125px' }}>Created By</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Created At</TableCell>
                  <TableCell style={{ minWidth: '125px' }} align='right'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products && products.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                      <TableCell>{row?.category?.category_name}</TableCell>
                      <TableCell>{row?.name}</TableCell>
                      <TableCell>{row?.type}</TableCell>
                      <TableCell>
                        <div className='StatusDiv'>
                          <div className={`StatusDot ${statusColor(row?.status ? 'Active' : 'Inactive')}`}></div>
                          {row?.status ? 'Active' : 'Inactive'}
                        </div>
                      </TableCell>
                      <TableCell>
                        {row?.created_by?.first_name + ' ' + row?.created_by?.last_name}
                      </TableCell>
                      <TableCell>
                        {moment(row?.createdAt).format('DD-MM-YYYY hh:mmA')}
                      </TableCell>
                      <TableCell align='right'>
                        {adminPermission && (adminPermission.includes('Edit Product') || adminPermission.includes('All')) &&
                          <Tooltip title={'Edit Product'} placement="left">
                            <IconButton onClick={() => editProduct(row)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        }
                        {adminPermission && (adminPermission.includes('Delete Product') || adminPermission.includes('All')) &&
                          <Tooltip title={'Delete Product'} placement="left">
                            <IconButton onClick={() => { setID(row?._id); setDeleteModal(true) }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            onChangePage={(e, page) => handlePageChange(page)}
            onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
          />
        </Paper>
      </Card>

      {/* Product form dialog */}
      <Dialog
        fullScreen
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => setOpenModal(false)} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              {productFormData?.id ? 'Edit Product' : 'Create Product'}
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }} onClick={() => save()}>
              {productFormData?.id ? 'Update' : 'Save'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <Box>
            <Grid container style={{ maxWidth: '450px', paddingTop: '25px' }}>
              <Grid product xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Autocomplete
                  options={categoryList || []}
                  getOptionLabel={(option) => option.category_name}
                  value={category}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Category"
                      placeholder="Search name"
                      size='small'
                      error={errors?.created_by}
                      helperText={errors?.created_by}
                    />
                  )}
                  onInputChange={(event, newInputValue) => {
                    dispatch(searchCategoryAction(newInputValue))
                  }}
                  onChange={(event, selectedOptions) => {
                    setCategory(selectedOptions);
                    setProductFormData({
                      ...productFormData,
                      category: selectedOptions?._id
                    });
                  }}
                />
              </Grid>
              <Grid product xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='name'
                  label="Name"
                  variant="outlined"
                  size="small"
                  value={productFormData?.name}
                  error={errors?.name}
                  helperText={errors?.name}
                  onChange={(e) => {
                    setProductFormData({
                      ...productFormData,
                      name: e.target.value,
                      ...(productFormData.id ? {} : { slug: createSlug(e.target.value) })
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <FormControl variant="outlined" size="small" error={errors?.type} required style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                  <Select
                    name='type'
                    label="Type"
                    value={productFormData?.type}
                    onChange={(e) => DataHandleChange(e)}
                  >
                    <MenuItem value="product">Product</MenuItem>
                    <MenuItem value="brand">Brand</MenuItem>
                    <MenuItem value="service">Service</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.type}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='slug'
                  label="URL Slug"
                  variant="outlined"
                  size="small"
                  value={productFormData?.slug}
                  error={errors?.slug}
                  helperText={errors?.slug}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid product xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  name='description'
                  label="Description"
                  variant="outlined"
                  size="small"
                  rows={4}
                  multiline
                  value={productFormData?.description}
                  error={errors?.description}
                  helperText={errors?.description}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Box className='statusBox'>
                  <Typography color="inherit" className="flexSpacer">
                    <div>Status</div>
                  </Typography>
                  <FormControlLabel
                    style={{ marginLeft: 'auto', marginRight: 0 }}
                    control={
                      <Switch
                        checked={productFormData?.status}
                        onChange={(e) => setProductFormData({ ...productFormData, status: e?.target?.checked })}
                        color="primary"
                      />
                    }
                    label={productFormData?.status ? 'Active' : 'Inactive'}
                  />
                </Box>
              </Grid>

              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-1 px-1'>
                <Typography variant="h5" color="inherit">Meta Content</Typography>
              </Grid>

              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  name='meta_title'
                  label="Meta Title"
                  variant="outlined"
                  size="small"
                  value={productFormData?.meta_title}
                  error={errors?.meta_title}
                  helperText={errors?.meta_title}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  name='meta_keywords'
                  label="Meta Keywords"
                  variant="outlined"
                  size="small"
                  value={productFormData?.meta_keywords}
                  error={errors?.meta_keywords}
                  helperText={errors?.meta_keywords}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  name='meta_description'
                  label="Meta Description"
                  variant="outlined"
                  size="small"
                  value={productFormData?.meta_description}
                  error={errors?.meta_description}
                  helperText={errors?.meta_description}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>

            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      {/* delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }} onClick={() => deleteProduct(ID)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* import file dialog */}
      <Dialog
        open={importProductModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => closeImpCsvModal()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Import Products
        </DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: '25px' }}>
            <div className='CSVReaderInp' style={{ marginBottom: '10px' }}>
              <span className='FileNam'>
                {fileDetails && fileDetails?.name}
              </span>
              <Button color="primary" variant='contained' style={{ marginLeft: 'auto' }}>
                Browse
              </Button>
              <CSVReader
                onFileLoaded={handleCSVData}
                parserOptions={{ header: true }}
              />
            </div>
            <div className='SampleCsv'>
              Download <span onClick={() => productsDemoCsv()}>Sample.csv</span>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="default" variant='contained' onClick={() => closeImpCsvModal()}>
            Cancel
          </Button>
          {fileDetails &&
            <Button color="primary" variant='contained' onClick={() => importCsvData()}>
              Import
            </Button>
          }
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default ProductsList
