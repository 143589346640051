import { getAllProductsLoading, getAllProductsSuccess, getAllProductsError, createProductLoading, createProductSuccess, createProductError, updateProductLoading, updateProductSuccess, updateProductError, deleteProductLoading, deleteProductSuccess, deleteProductError, updateProductStatusLoading, updateProductStatusSuccess, updateProductStatusError, searchProductLoading, searchProductSuccess, searchProductError, exportAllCsvProductsLoading, exportAllCsvProductsSuccess, exportAllCsvProductsError, importAllCsvProductsLoading, importAllCsvProductsSuccess, importAllCsvProductsError, searchProductFromCatLoading, searchProductFromCatSuccess, searchProductFromCatError } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllProductsAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllProductsLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&name=${filter?.name}&type=${filter?.type}&status=${filter?.status}&created_by=${filter?.created_by}`;
      const response = await api.get(`product/get/all?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllProductsSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllProductsError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const getAllCsvProductsAction = (filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(exportAllCsvProductsLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&name=${filter?.name}&type=${filter?.type}&status=${filter?.status}&created_by=${filter?.created_by}`;
      const response = await api.get(`product/export/csv?${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(exportAllCsvProductsSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(exportAllCsvProductsError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const importCsvProductAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(importAllCsvProductsLoading());
      const response = await api.post('product/import/csv', postData);
      const data = response.data;
      dispatch(importAllCsvProductsSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(importAllCsvProductsError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const createProductAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(createProductLoading());
      const response = await api.post('product/create', postData);
      const data = response.data;
      dispatch(createProductSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(createProductError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateProductAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateProductLoading());
      const response = await api.post('product/update', postData);
      const data = response.data;
      dispatch(updateProductSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateProductError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateProductStatusAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateProductStatusLoading());
      const response = await api.post('product/update/status', postData);
      const data = response.data;
      dispatch(updateProductStatusSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateProductStatusError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const deleteProductAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(deleteProductLoading());
      const response = await api.post('product/delete', postData);
      const data = response.data;
      dispatch(deleteProductSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(deleteProductError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const searchProductAction = (qry) => {
  return async (dispatch) => {
    try {
      dispatch(searchProductLoading());
      const response = await api.get(`product/search?search_query=${qry}`);
      const data = response.data;
      dispatch(searchProductSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      dispatch(searchProductError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const searchProductFromCatAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(searchProductFromCatLoading());
      const response = await api.post(`product/search-from-categories`, postData);
      const data = response.data;
      dispatch(searchProductFromCatSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      dispatch(searchProductFromCatError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllProductsAction,
  getAllCsvProductsAction,
  importCsvProductAction,
  createProductAction,
  updateProductAction,
  updateProductStatusAction,
  deleteProductAction,
  searchProductAction,
  searchProductFromCatAction
};
