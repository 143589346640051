export const GET_ALL_NEWS_LETTERS_LOADING = 'GET_ALL_NEWS_LETTERS_LOADING';
export const GET_ALL_NEWS_LETTERS_SUCCESS = 'GET_ALL_NEWS_LETTERS_SUCCESS';
export const GET_ALL_NEWS_LETTERS_ERROR = 'GET_ALL_NEWS_LETTERS_ERROR';

export const EXPORT_ALL_CSV_NEWS_LETTERS_LOADING = 'EXPORT_ALL_CSV_NEWS_LETTERS_LOADING';
export const EXPORT_ALL_CSV_NEWS_LETTERS_SUCCESS = 'EXPORT_ALL_CSV_NEWS_LETTERS_SUCCESS';
export const EXPORT_ALL_CSV_NEWS_LETTERS_ERROR = 'EXPORT_ALL_CSV_NEWS_LETTERS_ERROR';

export const IMPORT_ALL_CSV_NEWS_LETTERS_LOADING = 'IMPORT_ALL_CSV_NEWS_LETTERS_LOADING';
export const IMPORT_ALL_CSV_NEWS_LETTERS_SUCCESS = 'IMPORT_ALL_CSV_NEWS_LETTERS_SUCCESS';
export const IMPORT_ALL_CSV_NEWS_LETTERS_ERROR = 'IMPORT_ALL_CSV_NEWS_LETTERS_ERROR';

export const CREATE_NEWS_LETTER_LOADING = 'CREATE_NEWS_LETTER_LOADING';
export const CREATE_NEWS_LETTER_SUCCESS = 'CREATE_NEWS_LETTER_SUCCESS';
export const CREATE_NEWS_LETTER_ERROR = 'CREATE_NEWS_LETTER_ERROR';

export const UPDATE_NEWS_LETTER_LOADING = 'UPDATE_NEWS_LETTER_LOADING';
export const UPDATE_NEWS_LETTER_SUCCESS = 'UPDATE_NEWS_LETTER_SUCCESS';
export const UPDATE_NEWS_LETTER_ERROR = 'UPDATE_NEWS_LETTER_ERROR';

export const UPDATE_NEWS_LETTER_STATUS_LOADING = 'UPDATE_NEWS_LETTER_STATUS_LOADING';
export const UPDATE_NEWS_LETTER_STATUS_SUCCESS = 'UPDATE_NEWS_LETTER_STATUS_SUCCESS';
export const UPDATE_NEWS_LETTER_STATUS_ERROR = 'UPDATE_NEWS_LETTER_STATUS_ERROR';

export const DELETE_NEWS_LETTER_LOADING = 'DELETE_NEWS_LETTER_LOADING';
export const DELETE_NEWS_LETTER_SUCCESS = 'DELETE_NEWS_LETTER_SUCCESS';
export const DELETE_NEWS_LETTER_ERROR = 'DELETE_NEWS_LETTER_ERROR';

export const SEARCH_NEWS_LETTER_LOADING = 'SEARCH_NEWS_LETTER_LOADING';
export const SEARCH_NEWS_LETTER_SUCCESS = 'SEARCH_NEWS_LETTER_SUCCESS';
export const SEARCH_NEWS_LETTER_ERROR = 'SEARCH_NEWS_LETTER_ERROR';
