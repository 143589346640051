export const GET_ALL_PROVIDERS_LOADING = 'GET_ALL_PROVIDERS_LOADING';
export const GET_ALL_PROVIDERS_SUCCESS = 'GET_ALL_PROVIDERS_SUCCESS';
export const GET_ALL_PROVIDERS_ERROR = 'GET_ALL_PROVIDERS_ERROR';

export const EXPORT_ALL_CSV_PROVIDERS_LOADING = 'EXPORT_ALL_CSV_PROVIDERS_LOADING';
export const EXPORT_ALL_CSV_PROVIDERS_SUCCESS = 'EXPORT_ALL_CSV_PROVIDERS_SUCCESS';
export const EXPORT_ALL_CSV_PROVIDERS_ERROR = 'EXPORT_ALL_CSV_PROVIDERS_ERROR';

export const IMPORT_ALL_CSV_PROVIDERS_LOADING = 'IMPORT_ALL_CSV_PROVIDERS_LOADING';
export const IMPORT_ALL_CSV_PROVIDERS_SUCCESS = 'IMPORT_ALL_CSV_PROVIDERS_SUCCESS';
export const IMPORT_ALL_CSV_PROVIDERS_ERROR = 'IMPORT_ALL_CSV_PROVIDERS_ERROR';

export const CREATE_PROVIDER_LOADING = 'CREATE_PROVIDER_LOADING';
export const CREATE_PROVIDER_SUCCESS = 'CREATE_PROVIDER_SUCCESS';
export const CREATE_PROVIDER_ERROR = 'CREATE_PROVIDER_ERROR';

export const UPDATE_PROVIDER_LOADING = 'UPDATE_PROVIDER_LOADING';
export const UPDATE_PROVIDER_SUCCESS = 'UPDATE_PROVIDER_SUCCESS';
export const UPDATE_PROVIDER_ERROR = 'UPDATE_PROVIDER_ERROR';

export const UPDATE_PROVIDER_STATUS_LOADING = 'UPDATE_PROVIDER_STATUS_LOADING';
export const UPDATE_PROVIDER_STATUS_SUCCESS = 'UPDATE_PROVIDER_STATUS_SUCCESS';
export const UPDATE_PROVIDER_STATUS_ERROR = 'UPDATE_PROVIDER_STATUS_ERROR';

export const DELETE_PROVIDER_LOADING = 'DELETE_PROVIDER_LOADING';
export const DELETE_PROVIDER_SUCCESS = 'DELETE_PROVIDER_SUCCESS';
export const DELETE_PROVIDER_ERROR = 'DELETE_PROVIDER_ERROR';

export const SEARCH_PROVIDER_LOADING = 'SEARCH_PROVIDER_LOADING';
export const SEARCH_PROVIDER_SUCCESS = 'SEARCH_PROVIDER_SUCCESS';
export const SEARCH_PROVIDER_ERROR = 'SEARCH_PROVIDER_ERROR';
