import { DELETE_USER_ERROR, DELETE_USER_LOADING, DELETE_USER_SUCCESS, GET_ALL_SEARCH_USER_ERROR, GET_ALL_SEARCH_USER_LOADING, GET_ALL_SEARCH_USER_SUCCESS, GET_ALL_USERS_ERROR, GET_ALL_USERS_LOADING, GET_ALL_USERS_SUCCESS, UPDATE_USER_STATUS_ERROR, UPDATE_USER_STATUS_LOADING, UPDATE_USER_STATUS_SUCCESS } from './actionTypes';

// users
const getAllUserLoading = () => {
  return {
    type: GET_ALL_USERS_LOADING,
  };
};

const getAllUserSuccess = (data) => {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload: data,
  };
};

const getAllUserError = (error) => {
  return {
    type: GET_ALL_USERS_ERROR,
    payload: error,
  };
};

// search users
const getAllSearchUsersLoading = () => {
  return {
    type: GET_ALL_SEARCH_USER_LOADING,
  };
};

const getAllSearchUsersSuccess = (data) => {
  return {
    type: GET_ALL_SEARCH_USER_SUCCESS,
    payload: data,
  };
};

const getAllSearchUsersError = (error) => {
  return {
    type: GET_ALL_SEARCH_USER_ERROR,
    payload: error,
  };
};

// update user status
const updateUserStatusLoading = () => {
  return {
    type: UPDATE_USER_STATUS_LOADING,
  };
};

const updateUserStatusSuccess = (data) => {
  return {
    type: UPDATE_USER_STATUS_SUCCESS,
    payload: data,
  };
};

const updateUserStatusError = (error) => {
  return {
    type: UPDATE_USER_STATUS_ERROR,
    payload: error,
  };
};

// delete user
const deleteUserLoading = () => {
  return {
    type: DELETE_USER_LOADING,
  };
};

const deleteUserSuccess = (data) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: data,
  };
};

const deleteUserError = (error) => {
  return {
    type: DELETE_USER_ERROR,
    payload: error,
  };
};

export { 
    getAllUserLoading, 
    getAllUserSuccess, 
    getAllUserError,

    getAllSearchUsersLoading, 
    getAllSearchUsersSuccess, 
    getAllSearchUsersError,
    
    updateUserStatusLoading, 
    updateUserStatusSuccess, 
    updateUserStatusError,

    deleteUserLoading, 
    deleteUserSuccess, 
    deleteUserError,
};
