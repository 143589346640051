import { getAllProviderTypesLoading, getAllProviderTypesSuccess, getAllProviderTypesError, createProviderTypeLoading, createProviderTypeSuccess, createProviderTypeError, updateProviderTypeLoading, updateProviderTypeSuccess, updateProviderTypeError, deleteProviderTypeLoading, deleteProviderTypeSuccess, deleteProviderTypeError, updateProviderTypeStatusLoading, updateProviderTypeStatusSuccess, updateProviderTypeStatusError, searchProviderTypeLoading, searchProviderTypeSuccess, searchProviderTypeError, exportAllCsvProviderTypesLoading, exportAllCsvProviderTypesSuccess, exportAllCsvProviderTypesError, importAllCsvProviderTypesLoading, importAllCsvProviderTypesSuccess, importAllCsvProviderTypesError } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllProviderTypesAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllProviderTypesLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&name=${filter?.name}&status=${filter?.status}&created_by=${filter?.created_by}`;
      const response = await api.get(`card-provider-type/get/all?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllProviderTypesSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllProviderTypesError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const getAllCsvProviderTypesAction = (filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(exportAllCsvProviderTypesLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&name=${filter?.name}&type_id=${filter?.type_id}&status=${filter?.status}&created_by=${filter?.created_by}`;
      const response = await api.get(`card-provider-type/export/csv?${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(exportAllCsvProviderTypesSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(exportAllCsvProviderTypesError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const importCsvProviderTypeAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(importAllCsvProviderTypesLoading());
      const response = await api.post('card-provider-type/import/csv', postData);
      const data = response.data;
      dispatch(importAllCsvProviderTypesSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(importAllCsvProviderTypesError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const createProviderTypeAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(createProviderTypeLoading());
      const response = await api.post('card-provider-type/create', postData);
      const data = response.data;
      dispatch(createProviderTypeSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(createProviderTypeError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateProviderTypeAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateProviderTypeLoading());
      const response = await api.post('card-provider-type/update', postData);
      const data = response.data;
      dispatch(updateProviderTypeSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateProviderTypeError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateProviderTypeStatusAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateProviderTypeStatusLoading());
      const response = await api.post('card-provider-type/update/status', postData);
      const data = response.data;
      dispatch(updateProviderTypeStatusSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateProviderTypeStatusError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const deleteProviderTypeAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(deleteProviderTypeLoading());
      const response = await api.post('card-provider-type/delete', postData);
      const data = response.data;
      dispatch(deleteProviderTypeSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(deleteProviderTypeError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const searchProviderTypeAction = (qry) => {
  return async (dispatch) => {
    try {
      dispatch(searchProviderTypeLoading());
      const response = await api.get(`card-provider-type/search?search_query=${qry}`);
      const data = response.data;
      dispatch(searchProviderTypeSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      dispatch(searchProviderTypeError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllProviderTypesAction,
  getAllCsvProviderTypesAction,
  importCsvProviderTypeAction,
  createProviderTypeAction,
  updateProviderTypeAction,
  updateProviderTypeStatusAction,
  deleteProviderTypeAction,
  searchProviderTypeAction
};
