import { getAllCategoriesLoading, getAllCategoriesSuccess, getAllCategoriesError, createCategoryLoading, createCategorySuccess, createCategoryError, updateCategoryLoading, updateCategorySuccess, updateCategoryError, deleteCategoryLoading, deleteCategorySuccess, deleteCategoryError, updateCategoryStatusLoading, updateCategoryStatusSuccess, updateCategoryStatusError, searchCategoryLoading, searchCategorySuccess, searchCategoryError, exportAllCsvCategoryLoading, exportAllCsvCategorySuccess, exportAllCsvCategoryError, importAllCsvCategoryLoading, importAllCsvCategorySuccess, importAllCsvCategoryError } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllCategoriesAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllCategoriesLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&type=${filter?.type}&name=${filter?.name}&status=${filter?.status}&created_by=${filter?.created_by}`;
      const response = await api.get(`category/get/all?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllCategoriesSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllCategoriesError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const createCategoryAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(createCategoryLoading());
      const response = await api.post('category/create', postData);
      const data = response.data;
      dispatch(createCategorySuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(createCategoryError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateCategoryAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateCategoryLoading());
      const response = await api.post('category/update', postData);
      const data = response.data;
      dispatch(updateCategorySuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateCategoryError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateCategoryStatusAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateCategoryStatusLoading());
      const response = await api.post('category/update/status', postData);
      const data = response.data;
      dispatch(updateCategoryStatusSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateCategoryStatusError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const deleteCategoryAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(deleteCategoryLoading());
      const response = await api.post('category/delete', postData);
      const data = response.data;
      dispatch(deleteCategorySuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(deleteCategoryError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const searchCategoryAction = (qryData) => {
  return async (dispatch) => {
    try {
      dispatch(searchCategoryLoading());
      const response = await api.get(`category/search?type=all&search_query=${qryData}`);
      const data = response.data;
      dispatch(searchCategorySuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      dispatch(searchCategoryError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const searchCategoryWithTypeAction = (qryData) => {
  return async (dispatch) => {
    try {
      dispatch(searchCategoryLoading());
      const response = await api.get(`category/search?type=${qryData?.type}&search_query=${qryData?.search_query}`);
      const data = response.data;
      dispatch(searchCategorySuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      dispatch(searchCategoryError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getAllCsvCategoryAction = (filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(exportAllCsvCategoryLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `type=${filter?.type}&name=${filter?.name}&status=${filter?.status}`;
      const response = await api.get(`category/export/csv?${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(exportAllCsvCategorySuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(exportAllCsvCategoryError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const importCsvCategoryAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(importAllCsvCategoryLoading());
      const response = await api.post('category/import/csv', postData);
      const data = response.data;
      dispatch(importAllCsvCategorySuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(importAllCsvCategoryError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllCategoriesAction,
  createCategoryAction,
  updateCategoryAction,
  updateCategoryStatusAction,
  deleteCategoryAction,
  searchCategoryAction,
  searchCategoryWithTypeAction,
  getAllCsvCategoryAction,
  importCsvCategoryAction
};
