// rootReducer.js
import { combineReducers } from 'redux';
import dashboardReducer from './dashboard/reducer';
import adminReducer from './admin/reducer';
import roleReducer from './role/reducer';
import providerReducer from './card-providers/reducer';
import providerTypeReducer from './card-providers-types/reducer';
import cardNetworkReducer from './card-networks/reducer';
import cardReducer from './cards/reducer';
import productReducer from './products/reducer';
import cardOfferReducer from './card-offers/reducer';
import newsLetterReducer from './news-letters/reducer';

import categoryReducer from './category/reducer';
import itmeReducer from './item/reducer';
import userReducer from './user/reducer';
import referralReducer from './referral/reducer';
import notificationReducer from './notification/reducer';
import popupReducer from './popup/reducer';
import helpReducer from './help/reducer';
import withdrawReqReducer from './withdraw-request/reducer';
import activityLogReducer from './activity-log/reducer';
import configDataReducer from './configData/reducer';
import walletReducer from './wallet/reducer';
import refundReqReducer from './refund-request/reducer';

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  admin: adminReducer,
  role: roleReducer,
  provider: providerReducer,
  providerType: providerTypeReducer,
  cardNetwork: cardNetworkReducer,
  card: cardReducer,
  category: categoryReducer,
  product: productReducer,
  offer: cardOfferReducer,
  newsLetter: newsLetterReducer,

  item: itmeReducer,
  user: userReducer,
  referral: referralReducer,
  notification: notificationReducer,
  popup: popupReducer,
  help: helpReducer,
  withdrawReq: withdrawReqReducer,
  activityLog: activityLogReducer,
  configData: configDataReducer,
  wallet: walletReducer,
  refundReq: refundReqReducer
});

export default rootReducer;
