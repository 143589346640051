import { getAllNewsLettersLoading, getAllNewsLettersSuccess, getAllNewsLettersError, createNewsLetterLoading, createNewsLetterSuccess, createNewsLetterError, updateNewsLetterLoading, updateNewsLetterSuccess, updateNewsLetterError, deleteNewsLetterLoading, deleteNewsLetterSuccess, deleteNewsLetterError, updateNewsLetterStatusLoading, updateNewsLetterStatusSuccess, updateNewsLetterStatusError, searchNewsLetterLoading, searchNewsLetterSuccess, searchNewsLetterError, exportAllCsvNewsLettersLoading, exportAllCsvNewsLettersSuccess, exportAllCsvNewsLettersError, importAllCsvNewsLettersLoading, importAllCsvNewsLettersSuccess, importAllCsvNewsLettersError } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllNewsLettersAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllNewsLettersLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&email=${filter?.email}&status=${filter?.status}`;
      const response = await api.get(`news-letter/get/all?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllNewsLettersSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllNewsLettersError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const getAllCsvNewsLettersAction = (filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(exportAllCsvNewsLettersLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&email=${filter?.email}&status=${filter?.status}`;
      const response = await api.get(`news-letter/export/csv?${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(exportAllCsvNewsLettersSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(exportAllCsvNewsLettersError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const importCsvNewsLetterAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(importAllCsvNewsLettersLoading());
      const response = await api.post('news-letter/import/csv', postData);
      const data = response.data;
      dispatch(importAllCsvNewsLettersSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(importAllCsvNewsLettersError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const createNewsLetterAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(createNewsLetterLoading());
      const response = await api.post('news-letter/create', postData);
      const data = response.data;
      dispatch(createNewsLetterSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(createNewsLetterError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateNewsLetterAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateNewsLetterLoading());
      const response = await api.post('news-letter/update', postData);
      const data = response.data;
      dispatch(updateNewsLetterSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateNewsLetterError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateNewsLetterStatusAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateNewsLetterStatusLoading());
      const response = await api.post('news-letter/update/status', postData);
      const data = response.data;
      dispatch(updateNewsLetterStatusSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateNewsLetterStatusError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const deleteNewsLetterAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(deleteNewsLetterLoading());
      const response = await api.post('news-letter/delete', postData);
      const data = response.data;
      dispatch(deleteNewsLetterSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(deleteNewsLetterError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const searchNewsLetterAction = (qry) => {
  return async (dispatch) => {
    try {
      dispatch(searchNewsLetterLoading());
      const response = await api.get(`news-letter/search?search_query=${qry}`);
      const data = response.data;
      dispatch(searchNewsLetterSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      dispatch(searchNewsLetterError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllNewsLettersAction,
  getAllCsvNewsLettersAction,
  importCsvNewsLetterAction,
  createNewsLetterAction,
  updateNewsLetterAction,
  updateNewsLetterStatusAction,
  deleteNewsLetterAction,
  searchNewsLetterAction
};
