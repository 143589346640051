import { CHECK_ALL_CSV_CARD_OFFERS_ERROR, CHECK_ALL_CSV_CARD_OFFERS_LOADING, CHECK_ALL_CSV_CARD_OFFERS_SUCCESS, CREATE_CARD_OFFER_ERROR, CREATE_CARD_OFFER_LOADING, CREATE_CARD_OFFER_SUCCESS, DELETE_CARD_OFFER_ERROR, DELETE_CARD_OFFER_LOADING, DELETE_CARD_OFFER_SUCCESS, EXPORT_ALL_CSV_CARD_OFFERS_ERROR, EXPORT_ALL_CSV_CARD_OFFERS_LOADING, EXPORT_ALL_CSV_CARD_OFFERS_SUCCESS, GET_ALL_CARD_OFFERS_ERROR, GET_ALL_CARD_OFFERS_LOADING, GET_ALL_CARD_OFFERS_SUCCESS, IMPORT_ALL_CSV_CARD_OFFERS_ERROR, IMPORT_ALL_CSV_CARD_OFFERS_LOADING, IMPORT_ALL_CSV_CARD_OFFERS_SUCCESS, SEARCH_CARD_OFFER_ERROR, SEARCH_CARD_OFFER_LOADING, SEARCH_CARD_OFFER_SUCCESS, SEARCH_CITIES_ERROR, SEARCH_CITIES_LOADING, SEARCH_CITIES_SUCCESS, SEARCH_COUNTRIES_ERROR, SEARCH_COUNTRIES_LOADING, SEARCH_COUNTRIES_SUCCESS, SEARCH_STATES_ERROR, SEARCH_STATES_LOADING, SEARCH_STATES_SUCCESS, UPDATE_CARD_OFFER_ERROR, UPDATE_CARD_OFFER_LOADING, UPDATE_CARD_OFFER_STATUS_ERROR, UPDATE_CARD_OFFER_STATUS_LOADING, UPDATE_CARD_OFFER_STATUS_SUCCESS, UPDATE_CARD_OFFER_SUCCESS } from "./actionTypes";

const initialState = {
    getAllCardOffers: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvCardOffers: {
        loading: false,
        data: null,
        error: null
    },
    checkAllCsvImportCardOffers: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvImportCardOffers: {
        loading: false,
        data: null,
        error: null
    },
    createCardOffer: {
        loading: false,
        data: null,
        error: null
    },
    updateCardOffer: {
        loading: false,
        data: null,
        error: null
    },
    updateCardOfferStatus: {
        loading: false,
        data: null,
        error: null
    },
    deleteCardOffer: {
        loading: false,
        data: null,
        error: null
    },
    searchCardOffer: {
        loading: false,
        data: null,
        error: null
    },
    searchCountries: {
        loading: false,
        data: null,
        error: null
    },
    searchStates: {
        loading: false,
        data: null,
        error: null
    },
    searchCities: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CARD_OFFERS_LOADING:
            return { ...state, getAllCardOffers: { ...state.getAllCardOffers, loading: true, data: null, error: null } };
        case GET_ALL_CARD_OFFERS_SUCCESS:
            return { ...state, getAllCardOffers: { ...state.getAllCardOffers, loading: false, data: action.payload, error: null } };
        case GET_ALL_CARD_OFFERS_ERROR:
            return { ...state, getAllCardOffers: { ...state.getAllCardOffers, loading: false, data: null, error: action.payload } };

        case EXPORT_ALL_CSV_CARD_OFFERS_LOADING:
            return { ...state, getAllCsvCardOffers: { ...state.getAllCsvCardOffers, loading: true, data: null, error: null } };
        case EXPORT_ALL_CSV_CARD_OFFERS_SUCCESS:
            return { ...state, getAllCsvCardOffers: { ...state.getAllCsvCardOffers, loading: false, data: action.payload, error: null } };
        case EXPORT_ALL_CSV_CARD_OFFERS_ERROR:
            return { ...state, getAllCsvCardOffers: { ...state.getAllCsvCardOffers, loading: false, data: null, error: action.payload } };

        case CHECK_ALL_CSV_CARD_OFFERS_LOADING:
            return { ...state, checkAllCsvImportCardOffers: { ...state.checkAllCsvImportCardOffers, loading: true, data: null, error: null } };
        case CHECK_ALL_CSV_CARD_OFFERS_SUCCESS:
            return { ...state, checkAllCsvImportCardOffers: { ...state.checkAllCsvImportCardOffers, loading: false, data: action.payload, error: null } };
        case CHECK_ALL_CSV_CARD_OFFERS_ERROR:
            return { ...state, checkAllCsvImportCardOffers: { ...state.checkAllCsvImportCardOffers, loading: false, data: null, error: action.payload } };

        case IMPORT_ALL_CSV_CARD_OFFERS_LOADING:
            return { ...state, getAllCsvImportCardOffers: { ...state.getAllCsvImportCardOffers, loading: true, data: null, error: null } };
        case IMPORT_ALL_CSV_CARD_OFFERS_SUCCESS:
            return { ...state, getAllCsvImportCardOffers: { ...state.getAllCsvImportCardOffers, loading: false, data: action.payload, error: null } };
        case IMPORT_ALL_CSV_CARD_OFFERS_ERROR:
            return { ...state, getAllCsvImportCardOffers: { ...state.getAllCsvImportCardOffers, loading: false, data: null, error: action.payload } };

        case CREATE_CARD_OFFER_LOADING:
            return { ...state, createCardOffer: { ...state.createCardOffer, loading: true, data: null, error: null } };
        case CREATE_CARD_OFFER_SUCCESS:
            return { ...state, createCardOffer: { ...state.createCardOffer, loading: false, data: action.payload, error: null } };
        case CREATE_CARD_OFFER_ERROR:
            return { ...state, createCardOffer: { ...state.createCardOffer, loading: false, data: null, error: action.payload } };

        case UPDATE_CARD_OFFER_LOADING:
            return { ...state, updateCardOffer: { ...state.updateCardOffer, loading: true, data: null, error: null } };
        case UPDATE_CARD_OFFER_SUCCESS:
            return { ...state, updateCardOffer: { ...state.updateCardOffer, loading: false, data: action.payload, error: null } };
        case UPDATE_CARD_OFFER_ERROR:
            return { ...state, updateCardOffer: { ...state.updateCardOffer, loading: false, data: null, error: action.payload } };

        case UPDATE_CARD_OFFER_STATUS_LOADING:
            return { ...state, updateCardOfferStatus: { ...state.updateCardOfferStatus, loading: true, data: null, error: null } };
        case UPDATE_CARD_OFFER_STATUS_SUCCESS:
            return { ...state, updateCardOfferStatus: { ...state.updateCardOfferStatus, loading: false, data: action.payload, error: null } };
        case UPDATE_CARD_OFFER_STATUS_ERROR:
            return { ...state, updateCardOfferStatus: { ...state.updateCardOfferStatus, loading: false, data: null, error: action.payload } };

        case DELETE_CARD_OFFER_LOADING:
            return { ...state, deleteCardOffer: { ...state.deleteCardOffer, loading: true, data: null, error: null } };
        case DELETE_CARD_OFFER_SUCCESS:
            return { ...state, deleteCardOffer: { ...state.deleteCardOffer, loading: false, data: action.payload, error: null } };
        case DELETE_CARD_OFFER_ERROR:
            return { ...state, deleteCardOffer: { ...state.deleteCardOffer, loading: false, data: null, error: action.payload } };

        case SEARCH_CARD_OFFER_LOADING:
            return { ...state, searchCardOffer: { ...state.searchCardOffer, loading: true, data: null, error: null } };
        case SEARCH_CARD_OFFER_SUCCESS:
            return { ...state, searchCardOffer: { ...state.searchCardOffer, loading: false, data: action.payload, error: null } };
        case SEARCH_CARD_OFFER_ERROR:
            return { ...state, searchCardOffer: { ...state.searchCardOffer, loading: false, data: null, error: action.payload } };

        case SEARCH_COUNTRIES_LOADING:
            return { ...state, searchCountries: { ...state.searchCountries, loading: true, data: null, error: null } };
        case SEARCH_COUNTRIES_SUCCESS:
            return { ...state, searchCountries: { ...state.searchCountries, loading: false, data: action.payload, error: null } };
        case SEARCH_COUNTRIES_ERROR:
            return { ...state, searchCountries: { ...state.searchCountries, loading: false, data: null, error: action.payload } };

        case SEARCH_STATES_LOADING:
            return { ...state, searchStates: { ...state.searchStates, loading: true, data: null, error: null } };
        case SEARCH_STATES_SUCCESS:
            return { ...state, searchStates: { ...state.searchStates, loading: false, data: action.payload, error: null } };
        case SEARCH_STATES_ERROR:
            return { ...state, searchStates: { ...state.searchStates, loading: false, data: null, error: action.payload } };

        case SEARCH_CITIES_LOADING:
            return { ...state, searchCities: { ...state.searchCities, loading: true, data: null, error: null } };
        case SEARCH_CITIES_SUCCESS:
            return { ...state, searchCities: { ...state.searchCities, loading: false, data: action.payload, error: null } };
        case SEARCH_CITIES_ERROR:
            return { ...state, searchCities: { ...state.searchCities, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
