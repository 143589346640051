import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import PermMediaIcon from '@material-ui/icons/PermMedia';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Button, Checkbox, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Grow, InputLabel, Menu, MenuItem, Paper, Select, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import { createCardOfferAction, deleteCardOfferAction, getAllCsvCardOffersAction, getAllCardOffersAction, importCsvCardOfferAction, searchCardOfferAction, updateCardOfferAction, updateCardOfferStatusAction, searchCountriesAction, searchStatesAction, searchCitiesAction, checkCsvCardOfferAction } from '../../store/card-offers/asyncActions';
import { Autocomplete } from '@material-ui/lab';
import { searchCategoryAction, searchCategoryWithTypeAction, searchTypeegoryAction } from '../../store/category/asyncActions';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { getAllPermissionAction } from '../../store/role/asyncActions';
import { createSlug, exportCSV, statusColor } from '../../helpers';
import CSVReader from 'react-csv-reader';
import { toast } from 'react-toastify';
import moment from 'moment';
import { searchAdminUserAction } from '../../store/admin/asyncActions';
import { searchProviderAction } from '../../store/card-providers/asyncActions';
import { searchCardNetworkAction } from '../../store/card-networks/asyncActions';
import { searchCardAction } from '../../store/cards/asyncActions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { searchProductFromCatAction } from '../../store/products/asyncActions';



const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  cardOffer: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  }
}));

function CardOffers() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, setID] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();


  const [cardOffers, setCardOffers] = useState(null);
  const [createdByList, setCreatedByList] = useState(null);
  const [providerList, setProviderList] = useState(null);
  const [cardList, setCardList] = useState(null);
  const [categoryList, setCategoryList] = useState(null);
  const [productList, setProductList] = useState(null);
  const [serviceList, setServiceList] = useState(null);
  const [brandList, setBrandList] = useState(null);

  const [import_error, setImport_error] = useState(false);
  const [import_error_data, setImport_error_data] = useState(null);
  const [import_verified_data, setImport_verified_data] = useState(null);

  const [countryList, setCountryList] = useState(null);
  const [stateList, setStateList] = useState(null);
  const [cityList, setCityList] = useState(null);

  const cardOfferList = useSelector((state) => state?.offer?.getAllCardOffers);
  const csvCardOfferList = useSelector((state) => state?.offer?.getAllCsvCardOffers);
  const checkImportCardOfferList = useSelector((state) => state?.offer?.checkAllCsvImportCardOffers);
  const importCardOfferList = useSelector((state) => state?.offer?.getAllCsvImportCardOffers);
  const providerSearch = useSelector((state) => state?.provider?.searchProvider);
  const cardSearch = useSelector((state) => state?.card?.searchCard);
  const newCardOffer = useSelector((state) => state?.offer?.createCardOffer);
  const updatedCardOffer = useSelector((state) => state?.offer?.updateCardOffer);
  const updatedCardOfferStatus = useSelector((state) => state?.offer?.updateCardOfferStatus);
  const deletedCardOffer = useSelector((state) => state?.offer?.deleteCardOffer);
  const createdByData = useSelector((state) => state?.admin?.adminUserSearch);
  const searchCategoryData = useSelector((state) => state?.category?.searchCategory);
  const searchProductData = useSelector((state) => state?.product?.searchProductFromCategories);

  const searchCountryData = useSelector((state) => state?.offer?.searchCountries);
  const searchStateData = useSelector((state) => state?.offer?.searchStates);
  const searchCityData = useSelector((state) => state?.offer?.searchCities);


  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermission, setAdminPermission] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
    dispatch(searchProviderAction(''))
    dispatch(searchCardAction({
      provider: cardOfferFormData?.card_provider_id,
      search_query: ''
    }))
    dispatch(searchCategoryWithTypeAction({
      type: cardOfferFormData?.type?.toLowerCase(),
      search_query: ''
    }))
    dispatch(searchCountriesAction({ search_query: '' }))
    dispatch(searchAdminUserAction(''))
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermission(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    if (providerSearch?.data?.status && Array.isArray(providerSearch?.data?.data)) {
      setProviderList(providerSearch?.data?.data)
    }
  }, [providerSearch]);

  useEffect(() => {
    if (cardSearch?.data?.status && Array.isArray(cardSearch?.data?.data)) {
      setCardList(cardSearch?.data?.data)
    }
  }, [cardSearch]);

  useEffect(() => {
    if (searchCategoryData?.data?.status && Array.isArray(searchCategoryData?.data?.data)) {
      setCategoryList(searchCategoryData?.data?.data)
    }
  }, [searchCategoryData]);

  useEffect(() => {
    if (searchProductData?.data?.status && Array.isArray(searchProductData?.data?.data)) {
      if (searchProductData?.data?.type === 'product') {
        setProductList(searchProductData?.data?.data)
      }
      if (searchProductData?.data?.type === 'service') {
        setServiceList(searchProductData?.data?.data)
      }
      if (searchProductData?.data?.type === 'brand') {
        setBrandList(searchProductData?.data?.data)
      }
    }
  }, [searchProductData]);

  useEffect(() => {
    if (searchCountryData?.data?.status && Array.isArray(searchCountryData?.data?.data)) {
      setCountryList(searchCountryData?.data?.data)
    }
  }, [searchCountryData]);

  useEffect(() => {
    if (searchStateData?.data?.status && Array.isArray(searchStateData?.data?.data)) {
      setStateList(searchStateData?.data?.data)
    }
  }, [searchStateData]);

  useEffect(() => {
    if (searchCityData?.data?.status && Array.isArray(searchCityData?.data?.data)) {
      setCityList(searchCityData?.data?.data)
    }
  }, [searchCityData]);

  useEffect(() => {
    if (createdByData?.data?.status && Array.isArray(createdByData?.data?.data)) {
      setCreatedByList(createdByData?.data?.data)
    }
  }, [createdByData]);

  useEffect(() => {
    dispatch(getAllCardOffersAction(page + 1, pageSize, filter));
  }, [page, pageSize]);

  useEffect(() => {
    document.title = 'Offers';
  }, []);

  useEffect(() => {
    if (cardOfferList?.data?.status && Array.isArray(cardOfferList?.data?.data?.records)) {
      setCardOffers(cardOfferList?.data?.data?.records);
      setTotalCount(cardOfferList?.data?.data?.totalCount);
    }
  }, [cardOfferList]);

  useEffect(() => {
    if (csvCardOfferList?.data?.status && Array.isArray(csvCardOfferList?.data?.data?.records)) {
      exportCSV(csvCardOfferList?.data?.data?.records, 'offers.csv');
      setCsvLoader(false);
      csvCardOfferList.data = null;
    }
  }, [csvCardOfferList]);

  useEffect(() => {
    if (checkImportCardOfferList?.data?.status && checkImportCardOfferList?.data?.response_code === 201) {
      setImport_verified_data(checkImportCardOfferList?.data?.data);
      setCsvLoader(false);
      // setFileData(null);
      // setFileDetails(null);
      checkImportCardOfferList.data = null;
    }
    if (checkImportCardOfferList?.data?.status && checkImportCardOfferList?.data?.response_code === 200) {
      setImport_error(true);
      setImport_error_data(checkImportCardOfferList?.data?.data);
      setCsvLoader(false);
      // setFileData(null);
      // setFileDetails(null);
      checkImportCardOfferList.data = null;
    }
  }, [checkImportCardOfferList]);

  useEffect(() => {
    if (importCardOfferList?.data?.status) {
      setCsvLoader(false);
      dispatch(getAllCardOffersAction(page + 1, pageSize, filter));
      setFileData(null);
      setFileDetails(null);
      importCardOfferList.data = null;
    }
  }, [importCardOfferList]);

  useEffect(() => {
    if (newCardOffer?.data?.status) {
      setOpenModal(false);
      dispatch(getAllCardOffersAction(page + 1, pageSize, filter));
      resetForm();
      newCardOffer.data = null;
    }
  }, [newCardOffer]);

  useEffect(() => {
    if (updatedCardOffer?.data?.status) {
      setOpenModal(false);
      dispatch(getAllCardOffersAction(page + 1, pageSize, filter));
      resetForm();
      updatedCardOffer.data = null;
    }
  }, [updatedCardOffer]);

  useEffect(() => {
    if (updatedCardOfferStatus?.data?.status) {
      dispatch(getAllCardOffersAction(page + 1, pageSize, filter));
      updatedCardOfferStatus.data = null;
    }
  }, [updatedCardOfferStatus]);

  useEffect(() => {
    if (deletedCardOffer?.data?.status) {
      dispatch(getAllCardOffersAction(page + 1, pageSize, filter));
      setDeleteModal(false);
      deletedCardOffer.data = null;
    }
  }, [deletedCardOffer]);

  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState({
    title: '',
    provider_id: '',
    card_id: '',
    type: '',
    status: '',
    created_by: ''
  });

  const filterHandleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  }

  const filterApply = () => {
    dispatch(getAllCardOffersAction(page + 1, pageSize, filter));
  }

  const filterClear = () => {
    const reset = {
      title: '',
      provider_id: '',
      card_id: '',
      type: '',
      status: '',
      created_by: ''
    }
    setFilter(reset);
    setFilterProvider(null);
    setCreatedBy(null);
    dispatch(getAllCardOffersAction(page + 1, pageSize, reset));
  }

  const [cardOfferFormData, setCardOfferFormData] = useState({
    card_provider_id: '',
    card_id: '',
    type: 'Service',
    categories: [],
    title: '',
    sub_title: '',
    slug: '',
    description: '',
    offer_per: '',
    products: [],
    services: [],
    brands: [],
    start_date: '',
    end_date: '',
    emi: false,
    emi_details: '',
    applicable: ['Online'],
    terms_and_conditions: '',
    countries: null,
    states: null,
    cities: null,
    link: '',
    thumb: null,
    banners: null,
    status: true,
    meta_title: '',
    meta_keywords: '',
    meta_description: '',
  });

  const [provider, setProvider] = useState(null);
  const [selectCard, setSelectCard] = useState(null);
  const [filterProvider, setFilterProvider] = useState(null);
  const [filterCard, setFilterCard] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);

  const resetForm = () => {
    setImagePreview(null);
    setImagePreviews([]);
    setErrors({});
    setProvider(null);
    setSelectCard(null);
    setCardOfferFormData({
      card_provider_id: '',
      card_id: '',
      type: 'Service',
      categories: [],
      title: '',
      sub_title: '',
      slug: '',
      description: '',
      offer_per: '',
      products: [],
      services: [],
      brands: [],
      start_date: '',
      end_date: '',
      emi: false,
      emi_details: '',
      applicable: ['Online'],
      terms_and_conditions: '',
      countries: null,
      states: null,
      cities: null,
      link: '',
      thumb: null,
      banners: null,
      status: true,
      meta_title: '',
      meta_keywords: '',
      meta_description: '',
    });
  }

  const DataHandleChange = (e) => {
    setCardOfferFormData({
      ...cardOfferFormData,
      [e.target.name]: e.target.value
    });
  }

  const addNew = () => {
    resetForm();
    // dispatch(searchCardOfferTypeAction(''))
    setOpenModal(true);
  }

  const editCardOffer = (row) => {
    if (!row?.thumb) {
      setImagePreview(null);
    }
    if (!row?.banners || !Array.isArray(row?.banners) || row?.banners?.length < 1) {
      setImagePreviews([]);
    }
    setErrors({});
    setCardOfferFormData({
      id: row?._id,
      card_provider_id: row?.provider?._id,
      card_id: row?.card?._id,
      banners: row?.banners,
      type: row?.type,
      categories: row?.categories,
      products: row?.products,
      services: row?.services,
      brands: row?.brands,
      countries: row?.countries,
      states: row?.states,
      cities: row?.cities,
      start_date: moment(row?.start_date).format('YYYY-MM-DD') || '',
      end_date: moment(row?.end_date).format('YYYY-MM-DD') || '',
      emi: row?.emi,
      emi_details: row?.emi_details,
      applicable: row?.applicable,
      terms_and_conditions: row?.terms_and_conditions,
      title: row?.title,
      sub_title: row?.sub_title,
      slug: row?.slug,
      description: row?.description,
      offer_per: row?.offer_per,
      link: row?.link,
      thumb: row?.thumb || null,
      status: row?.status,
      meta_title: row?.meta_title || '',
      meta_keywords: row?.meta_keywords || '',
      meta_description: row?.meta_description || '',
    });
    setProvider(row?.provider ? row?.provider : null);
    setSelectCard(row?.card ? row?.card : null);
    setTimeout(() => {
      setOpenModal(true);
    }, 50);
  }

  const createCardOffer = () => {
    dispatch(createCardOfferAction(setFormDataFunc()))
  }

  const updateCardOffer = () => {
    dispatch(updateCardOfferAction(setFormDataFunc()))
  }

  const updateCardOfferStatus = (data) => {
    {
      adminPermission && (adminPermission.includes('Edit CardOffer') || adminPermission.includes('All')) &&
        dispatch(updateCardOfferStatusAction(data))
    }
  }

  const setFormDataFunc = () => {
    const Data = new FormData();
    if (cardOfferFormData?.id && cardOfferFormData?.id !== null && cardOfferFormData?.id !== '') {
      Data.append('id', cardOfferFormData?.id);
    }
    Data.append('card_provider_id', cardOfferFormData?.card_provider_id);
    Data.append('card_id', cardOfferFormData?.card_id);
    Data.append('type', cardOfferFormData?.type);
    Data.append('categories', JSON.stringify(cardOfferFormData?.categories));
    Data.append('title', cardOfferFormData?.title);
    Data.append('sub_title', cardOfferFormData?.sub_title);
    Data.append('slug', cardOfferFormData?.slug);
    Data.append('description', cardOfferFormData?.description);
    Data.append('offer_per', cardOfferFormData?.offer_per);
    Data.append('products', JSON.stringify(cardOfferFormData?.products));
    Data.append('services', JSON.stringify(cardOfferFormData?.services));
    Data.append('brands', JSON.stringify(cardOfferFormData?.brands));
    Data.append('start_date', cardOfferFormData?.start_date);
    Data.append('end_date', cardOfferFormData?.end_date);
    Data.append('emi', cardOfferFormData?.emi);
    Data.append('emi_details', cardOfferFormData?.emi_details);
    Data.append('applicable', JSON.stringify(cardOfferFormData?.applicable));
    Data.append('terms_and_conditions', cardOfferFormData?.terms_and_conditions);
    Data.append('countries', JSON.stringify(cardOfferFormData?.countries));
    Data.append('states', JSON.stringify(cardOfferFormData?.states));
    Data.append('cities', JSON.stringify(cardOfferFormData?.cities));
    Data.append('link', cardOfferFormData?.link);
    Data.append('thumb', cardOfferFormData?.thumb);
    if (cardOfferFormData?.banners && cardOfferFormData.banners.length > 0) {
      cardOfferFormData.banners.forEach((banner, index) => {
        Data.append(`banners`, banner);
      });
    }
    Data.append('status', cardOfferFormData?.status);
    Data.append('meta_title', cardOfferFormData?.meta_title);
    Data.append('meta_keywords', cardOfferFormData?.meta_keywords);
    Data.append('meta_description', cardOfferFormData?.meta_description);
    return Data;
  }

  const save = () => {
    const validationResult = validationSchema.validate(cardOfferFormData, { abortEarly: false });
    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
      console.log(validationErrors);
    } else {
      setErrors({});
      if (cardOfferFormData?.id && cardOfferFormData?.id !== '' && cardOfferFormData?.id !== null) {
        updateCardOffer()
      } else {
        createCardOffer()
      }
    }
  }

  const deleteCardOffer = (id) => {
    dispatch(deleteCardOfferAction({ id: id }));
  }

  const [errors, setErrors] = useState({});
  const validationSchema = Joi.object({
    id: Joi.string().allow(null, ''),
    card_provider_id: Joi.string().required(),
    card_id: Joi.string().required(),
    type: Joi.string().required(),
    categories: Joi.array().required(),
    title: Joi.string().required(),
    slug: Joi.string().required(),
    sub_title: Joi.string().required(),
    description: Joi.string().allow(null, ''),
    offer_per: Joi.string().allow(null, ''),
    products: Joi.array().required().allow(null),
    services: Joi.array().required().allow(null),
    brands: Joi.array().required().allow(null),
    start_date: Joi.string().required(),
    end_date: Joi.string().required(),
    emi: Joi.boolean().required(),
    emi_details: Joi.when('emi', {
      is: true,
      then: Joi.string().required(),
      otherwise: Joi.string().allow(null, ''), // Allow null or empty string when emi is false
    }),
    applicable: Joi.array().required().min(1),
    terms_and_conditions: Joi.string().allow(null, ''),
    countries: Joi.array().required().allow(null),
    states: Joi.array().required().allow(null),
    cities: Joi.array().required().allow(null),
    link: Joi.string().allow(null, ''),
    thumb: Joi.allow(null, '').required(),
    banners: Joi.allow(null, '').required(),
    status: Joi.boolean().required(),
    meta_title: Joi.string().allow(null, ''),
    meta_keywords: Joi.string().allow(null, ''),
    meta_description: Joi.string().allow(null, ''),
  });

  const [importCardOfferModal, setImportCardOfferModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [csvLoader, setCsvLoader] = useState(false);
  const [fileDetails, setFileDetails] = useState(null);
  const [fileData, setFileData] = useState(null);
  const exportCsvData = () => {
    setCsvLoader(true);
    dispatch(getAllCsvCardOffersAction(filter));
    setAnchorEl(null);
  }
  const handleCSVData = (data, fileInfo) => {
    const filteredData = data.filter(row => Object.values(row).some(value => value.trim() !== ''));
    if (fileInfo?.type !== 'text/csv') {
      toast.error('Invalid file format!');
      setAnchorEl(null);
      return;
    }
    if (filteredData && Array.isArray(filteredData)) {
      setFileData(filteredData);
      setFileDetails(fileInfo);
      setAnchorEl(null);
      setCsvLoader(true);
      dispatch(checkCsvCardOfferAction({ cardOfferData: filteredData }));
    }
  };
  const importCsvData = () => {
    setImportCardOfferModal(false);
    setCsvLoader(true);
    dispatch(importCsvCardOfferAction({ cardOfferData: import_verified_data }));
  }
  const downloadReport = () => {
    exportCSV(import_error_data, 'import_offers_error_report.csv');
    closeImpCsvModal();
  }
  const closeImpCsvModal = () => {
    setImportCardOfferModal(false);
    setFileDetails(null);
    setFileData(null);
    setImport_error(false);
    setImport_error_data(null);
  }

  const cardOffersDemoCsv = () => {
    const demo = [
      {
        instruments_provider: `"Axis Bank"`,
        instrument_name: `"Visa"`,
        offer_title: `"Swiggy Food E-Gift Voucher"`,
        sub_title: `"10% OFF ON Food Voucher"`,
        offer_per: `"10"`,
        description: `"Order your favourite cuisines from restaurants online"`,
        type: `"Product"`,
        category: `"FOOD, SHOPPING"`,
        product: `"Product 01, Product 02"`,
        service: `"Service 01, Service 02"`,
        brand: `"Brand 01, Brand 02"`,
        applicable_for: `"Offline, Online"`,
        country: `"Afghanistan, India"`,
        state: `"Badakhshan, Gujarat"`,
        city: `"Eshkashem, Rajkot, Bharuch"`,
        start_date: `"01/01/2024"`,
        end_date: `"05/01/2024"`,
        link: `"https://your-link.com/link-01"`,
      },
      {
        instruments_provider: `"Bank Of Baroda"`,
        instrument_name: `"Platinum"`,
        offer_title: `"Google Play Gift Code"`,
        sub_title: `"5% OFF ON Men's Salon Voucher"`,
        offer_per: `"5"`,
        description: `"Google Play Gift Code"`,
        type: `"Service"`,
        category: `"Beauty Salon, Category 01"`,
        product: `"Product 01, Product 02"`,
        service: `"Service 01, Service 02"`,
        brand: `"Brand 01, Brand 02"`,
        applicable_for: `"Offline, Online"`,
        country: `"Afghanistan, India"`,
        state: `"Badakhshan, Gujarat"`,
        city: `"Eshkashem, Rajkot, Bharuch"`,
        start_date: `"01/01/2024"`,
        end_date: `"05/01/2024"`,
        link: `"https://your-link.com/link-01"`,
      },
    ];
    exportCSV(demo, 'import-offer-sample-file.csv');
  }

  const [imagePreview, setImagePreview] = useState(null);
  const handleFileChange = (event) => {
    setCardOfferFormData({
      ...cardOfferFormData,
      thumb: event?.target?.files[0]
    });
  };

  useEffect(() => {
    if (cardOfferFormData?.thumb !== '' && cardOfferFormData?.thumb !== 'remove' && cardOfferFormData?.thumb !== null) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      if (typeof cardOfferFormData?.thumb === 'string') {
        setImagePreview(cardOfferFormData?.thumb);
      } else {
        reader.readAsDataURL(cardOfferFormData?.thumb);
      }
    }
  }, [cardOfferFormData?.thumb]);

  const removeImg = () => {
    setCardOfferFormData({
      ...cardOfferFormData,
      thumb: 'remove'
    });
    setImagePreview(null);
  }

  const [imagePreviews, setImagePreviews] = useState([]);
  const handleBannersFileChange = (event) => {
    setCardOfferFormData({
      ...cardOfferFormData,
      banners: Array.isArray(cardOfferFormData.banners)
        ? [...cardOfferFormData.banners, ...event?.target?.files]
        : [...event?.target?.files],
    });
  };

  useEffect(() => {
    const newImagePreviews = [];
    if (Array.isArray(cardOfferFormData?.banners)) {
      cardOfferFormData.banners?.map((banner) => {
        if (banner !== '' && banner !== 'remove' && banner !== null) {
          if (typeof banner === 'string') {
            newImagePreviews.push(banner);
            setImagePreviews([...newImagePreviews]);
          } else {
            const reader = new FileReader();
            reader.onload = () => {
              newImagePreviews.push(reader.result);
              setImagePreviews([...newImagePreviews]);
            };
            reader.readAsDataURL(banner);
          }
        }
      });
    }
  }, [cardOfferFormData.banners]);

  const removeBannersImg = (indexToRemove) => {
    const newThumbArray = [...cardOfferFormData.banners];
    newThumbArray.splice(indexToRemove, 1);

    setCardOfferFormData({
      ...cardOfferFormData,
      banners: newThumbArray,
    });

    const newImagePreviews = [...imagePreviews];
    newImagePreviews.splice(indexToRemove, 1);
    setImagePreviews(newImagePreviews);
  };

  useEffect(() => {
    if (openModal) {
      setCardOfferFormData({
        ...cardOfferFormData,
        card_id: ''
      });
      dispatch(searchCardAction({
        provider: cardOfferFormData?.card_provider_id,
        search_query: ''
      }))
      setSelectCard(null);
    }
  }, [cardOfferFormData?.card_provider_id]);

  useEffect(() => {
    if (openModal) {
      setCardOfferFormData({
        ...cardOfferFormData,
        categories: null
      });
      dispatch(searchCategoryWithTypeAction({
        type: cardOfferFormData?.type?.toLowerCase(),
        search_query: ''
      }))
    }
  }, [cardOfferFormData?.type]);

  useEffect(() => {
    if (openModal) {
      setCardOfferFormData({
        ...cardOfferFormData,
        products: null,
        services: null,
        brands: null
      });
      dispatch(searchProductFromCatAction({
        type: 'product',
        categories: cardOfferFormData?.categories?.map((cat) => {
          return cat?._id
        }) || [],
        search_query: ''
      }))
      dispatch(searchProductFromCatAction({
        type: 'service',
        categories: cardOfferFormData?.categories?.map((cat) => {
          return cat?._id
        }) || [],
        search_query: ''
      }))
      dispatch(searchProductFromCatAction({
        type: 'brand',
        categories: cardOfferFormData?.categories?.map((cat) => {
          return cat?._id
        }) || [],
        search_query: ''
      }))
    }
  }, [cardOfferFormData?.categories]);

  useEffect(() => {
    if (openModal) {
      setCardOfferFormData({
        ...cardOfferFormData,
        states: null
      });
      dispatch(searchStatesAction({
        countries: cardOfferFormData?.countries?.map((item) => {
          return item?._id
        }) || [],
        search_query: ''
      }))
    }
  }, [cardOfferFormData?.countries]);

  useEffect(() => {
    if (openModal) {
      setCardOfferFormData({
        ...cardOfferFormData,
        cities: null
      });
      dispatch(searchCitiesAction({
        states: cardOfferFormData?.states?.map((item) => {
          return item?._id
        }) || [],
        search_query: ''
      }))
    }
  }, [cardOfferFormData?.states]);

  const handleCheckboxChange = (option) => {
    if (openModal) {
      const updatedOptions = cardOfferFormData?.applicable.includes(option)
        ? cardOfferFormData?.applicable.filter((selected) => selected !== option)
        : [...cardOfferFormData?.applicable, option];

      setCardOfferFormData({
        ...cardOfferFormData,
        applicable: updatedOptions
      });
    }
  };

  const removeDoubleQuotes = (value) => {
    if (value) {
      return value.replace(/^"(.*)"$/, '$1');
    }
    return '';
  };


  return (
    <div>
      <Card className='pageCover'>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant='h5' color="textSecondary" className="flexSpacer">
              Offers
            </Typography>

            <div style={{ display: 'flex', alignCardOffers: 'center' }}>
              <Button
                color='default' variant='outlined' className='mr-1'
                aria-owns={anchorEl ? 'csv-menu' : null}
                aria-haspopup="true"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                disabled={csvLoader}
              >
                Export / Import
                {csvLoader && <CircularProgress style={{ width: '20px', height: '20px' }} className='ml-1' />}
              </Button>
              <Menu
                id="csv-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => exportCsvData()}>Export CSV</MenuItem>
                <MenuItem style={{ width: '133px' }} onClick={() => setImportCardOfferModal(true)}>
                  Import CSV
                </MenuItem>
              </Menu>
            </div>

            {adminPermission && (adminPermission.includes('Add CardOffer') || adminPermission.includes('All')) &&
              <Tooltip title={'Add New Offer'} placement="left">
                <Button color='default' variant='outlined' onClick={() => addNew()}>+ Add New</Button>
              </Tooltip>
            }
            <Tooltip title={'Apply Filter On Data'} placement="left">
              <Button color='default' variant='outlined' className='ml-1' onClick={() => setOpenFilter(!openFilter)}>
                <span className='mr-1'>Filter</span> {openFilter ? <ExpandLessRoundedIcon className='btnIco' /> : <ExpandMoreRoundedIcon className='btnIco' />}
              </Button>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box className={`filterOuter ${openFilter && 'activeOpen'}`}>
          <Grid container style={{ width: '100%', paddingTop: '25px' }}>
            <Grid cardOffer xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                name='title'
                label="Title"
                variant="outlined"
                size="small"
                value={filter?.title}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid cardOffer xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <Autocomplete
                options={providerList || []}
                getOptionLabel={(option) => option.name}
                value={filterProvider}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Instrument Provider"
                    placeholder="Search name"
                    size='small'
                  />
                )}
                onInputChange={(event, newInputValue) => {
                  dispatch(searchProviderAction(newInputValue))
                }}
                onChange={(event, selectedOptions) => {
                  setFilterProvider(selectedOptions);
                  setFilter({
                    ...filter,
                    provider_id: selectedOptions?._id
                  });
                }}
              />
            </Grid>
            <Grid card xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <Autocomplete
                options={cardList || []}
                getOptionLabel={(option) => option.name}
                value={filterCard}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Instrument Name"
                    placeholder="Search name"
                    size='small'
                    error={errors?.card_id}
                    helperText={errors?.card_id}
                  />
                )}
                onInputChange={(event, newInputValue) => {
                  dispatch(searchCardAction({
                    provider: cardOfferFormData?.card_provider_id,
                    search_query: newInputValue
                  }))
                }}
                onChange={(event, selectedOptions) => {
                  setFilterCard(selectedOptions);
                  setFilter({
                    ...filter,
                    card_id: selectedOptions?._id
                  });
                }}
              />
            </Grid>
            <Grid cardOffer xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Product Type</InputLabel>
                <Select
                  name='type'
                  label="Product Type"
                  value={filter?.type}
                  onChange={(e) => filterHandleChange(e)}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Service">Service</MenuItem>
                  <MenuItem value="Product">Product</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid cardOffer xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                <Select
                  name='status'
                  label="Status"
                  value={filter?.status}
                  onChange={(e) => filterHandleChange(e)}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid cardOffer xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <Autocomplete
                options={createdByList || []}
                getOptionLabel={(option) => option.name}
                value={createdBy}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Created By"
                    placeholder="Search name"
                    size='small'
                    error={errors?.type_id}
                    helperText={errors?.type_id}
                  />
                )}
                onInputChange={(event, newInputValue) => {
                  dispatch(searchAdminUserAction(newInputValue))
                }}
                onChange={(event, selectedOptions) => {
                  setCreatedBy(selectedOptions);
                  setFilter({
                    ...filter,
                    created_by: selectedOptions?._id
                  });
                }}
              />
            </Grid>
            <Grid cardOffer xs={6} sm={6} md={2} xl={2} className='mb-2 px-1' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='primary' variant='contained' onClick={() => filterApply()}>Apply</Button>
              <Button color='default' variant='outlined' className='ml-1' onClick={() => filterClear()}>Clear</Button>
            </Grid>
          </Grid>
        </Box>
        <Paper>
          <TableContainer className='Custom_Datatable'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell style={{ minWidth: '75px' }}>Thumb</TableCell>
                  <TableCell style={{ minWidth: '125px' }}>Title</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Instrument Provider</TableCell>
                  <TableCell style={{ minWidth: '125px' }}>Instrument Name</TableCell>
                  <TableCell style={{ minWidth: '125px' }}>Product Type</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Status</TableCell>
                  <TableCell style={{ minWidth: '125px' }}>Created By</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Created At</TableCell>
                  <TableCell style={{ minWidth: '125px' }} align='right'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cardOffers && cardOffers.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                      <TableCell>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={row?.thumb ? row?.thumb : `${process.env.PUBLIC_URL}/static/images/offer-default.jpg`} alt={row?.thumb} className='LogoIMG' />
                        </div>
                      </TableCell>
                      <TableCell>{row?.title}</TableCell>
                      <TableCell>{row?.provider?.name}</TableCell>
                      <TableCell>{row?.card?.name}</TableCell>
                      <TableCell>{row?.type}</TableCell>
                      <TableCell>
                        <div className='StatusDiv'>
                          <div className={`StatusDot ${statusColor(row?.status ? 'Active' : 'Inactive')}`}></div>
                          {row?.status ? 'Active' : 'Inactive'}
                        </div>
                      </TableCell>
                      <TableCell>
                        {row?.created_by?.first_name + ' ' + row?.created_by?.last_name}
                      </TableCell>
                      <TableCell>
                        {moment(row?.createdAt).format('DD-MM-YYYY hh:mmA')}
                      </TableCell>
                      <TableCell align='right'>
                        {adminPermission && (adminPermission.includes('Edit CardOffer') || adminPermission.includes('All')) &&
                          <Tooltip title={'Edit Offer'} placement="left">
                            <IconButton onClick={() => editCardOffer(row)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        }
                        {adminPermission && (adminPermission.includes('Delete CardOffer') || adminPermission.includes('All')) &&
                          <Tooltip title={'Delete Offer'} placement="left">
                            <IconButton onClick={() => { setID(row?._id); setDeleteModal(true) }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            onChangePage={(e, page) => handlePageChange(page)}
            onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
          />
        </Paper>
      </Card>

      {/* CardOffer form dialog */}
      <Dialog
        fullScreen
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => setOpenModal(false)} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              {cardOfferFormData?.id ? 'Edit Offer' : 'Create Offer'}
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }} onClick={() => save()}>
              {cardOfferFormData?.id ? 'Update' : 'Save'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <Box>
            <Grid container style={{ maxWidth: '450px', paddingTop: '25px' }}>
              <Grid card xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Autocomplete
                  options={providerList || []}
                  getOptionLabel={(option) => option.name}
                  value={provider}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Instrument Provider"
                      placeholder="Search name"
                      size='small'
                      error={errors?.card_provider_id}
                      helperText={errors?.card_provider_id}
                    />
                  )}
                  onInputChange={(event, newInputValue) => {
                    dispatch(searchProviderAction(newInputValue))
                  }}
                  onChange={(event, selectedOptions) => {
                    setProvider(selectedOptions);
                    setCardOfferFormData({
                      ...cardOfferFormData,
                      card_provider_id: selectedOptions?._id
                    });
                  }}
                />
              </Grid>
              <Grid card xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Autocomplete
                  options={cardList || []}
                  getOptionLabel={(option) => option.name}
                  value={selectCard}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Instrument Name"
                      placeholder="Search name"
                      size='small'
                      error={errors?.card_id}
                      helperText={errors?.card_id}
                    />
                  )}
                  onInputChange={(event, newInputValue) => {
                    dispatch(searchCardAction({
                      provider: cardOfferFormData?.card_provider_id,
                      search_query: newInputValue
                    }))
                  }}
                  onChange={(event, selectedOptions) => {
                    setSelectCard(selectedOptions);
                    setCardOfferFormData({
                      ...cardOfferFormData,
                      card_id: selectedOptions?._id
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <FormControl variant="outlined" size="small" error={errors?.type} required style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-outlined-label">Product Type</InputLabel>
                  <Select
                    name='type'
                    label="Product Type"
                    value={cardOfferFormData?.type}
                    onChange={(e) => DataHandleChange(e)}
                  >
                    <MenuItem value="Service">Service</MenuItem>
                    <MenuItem value="Product">Product</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.type}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Autocomplete
                  multiple
                  options={categoryList || []}
                  getOptionLabel={(option) => option.category_name}
                  value={cardOfferFormData?.categories || []}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Categories"
                      placeholder="Search name"
                      error={errors?.categories}
                      helperText={errors?.categories}
                    />
                  )}
                  onInputChange={(event, newInputValue) => {
                    dispatch(searchCategoryWithTypeAction({
                      type: cardOfferFormData?.type?.toLowerCase(),
                      search_query: newInputValue
                    }))
                  }}
                  onChange={(event, selectedOptions) => {
                    setCardOfferFormData({
                      ...cardOfferFormData,
                      categories: selectedOptions
                    });
                  }}
                />
              </Grid>
              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='title'
                  label="Title"
                  variant="outlined"
                  size="small"
                  value={cardOfferFormData?.title}
                  error={errors?.title}
                  helperText={errors?.title}
                  onChange={(e) => {
                    setCardOfferFormData({
                      ...cardOfferFormData,
                      title: e.target.value,
                      ...(cardOfferFormData.id ? {} : { slug: createSlug(e.target.value) })
                    });
                  }}
                />
              </Grid>
              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='sub_title'
                  label="Sub title"
                  variant="outlined"
                  size="small"
                  value={cardOfferFormData?.sub_title}
                  error={errors?.sub_title}
                  helperText={errors?.sub_title}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='slug'
                  label="URL Slug"
                  variant="outlined"
                  size="small"
                  value={cardOfferFormData?.slug}
                  error={errors?.slug}
                  helperText={errors?.slug}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Typography variant='caption' color="textSecondary" className="flexSpacer">
                  Description
                </Typography>
                <CKEditor
                  editor={ClassicEditor}
                  data={cardOfferFormData?.description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setCardOfferFormData({
                      ...cardOfferFormData,
                      description: data
                    });
                  }}
                />
              </Grid>
              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  type='number'
                  name='offer_per'
                  label="Offer %"
                  variant="outlined"
                  size="small"
                  value={cardOfferFormData?.offer_per}
                  error={errors?.offer_per}
                  helperText={errors?.offer_per}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Autocomplete
                  multiple
                  options={productList || []}
                  getOptionLabel={(option) => option.name}
                  value={cardOfferFormData?.products || []}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Products Convered in Offer"
                      placeholder="Search name"
                    />
                  )}
                  onInputChange={(event, newInputValue) => {
                    dispatch(searchProductFromCatAction({
                      type: 'product',
                      categories: cardOfferFormData?.categories?.map((cat) => {
                        return cat?._id
                      }) || [],
                      search_query: newInputValue
                    }))
                  }}
                  onChange={(event, selectedOptions) => {
                    setCardOfferFormData({
                      ...cardOfferFormData,
                      products: selectedOptions
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Autocomplete
                  multiple
                  options={serviceList || []}
                  getOptionLabel={(option) => option.name}
                  value={cardOfferFormData?.services || []}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Services Covered in Offer"
                      placeholder="Search name"
                    />
                  )}
                  onInputChange={(event, newInputValue) => {
                    dispatch(searchProductFromCatAction({
                      type: 'service',
                      categories: cardOfferFormData?.categories?.map((cat) => {
                        return cat?._id
                      }) || [],
                      search_query: newInputValue
                    }))
                  }}
                  onChange={(event, selectedOptions) => {
                    setCardOfferFormData({
                      ...cardOfferFormData,
                      services: selectedOptions
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Autocomplete
                  multiple
                  options={brandList || []}
                  getOptionLabel={(option) => option.name}
                  value={cardOfferFormData?.brands || []}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Brands Covered in Offer"
                      placeholder="Search name"
                    />
                  )}
                  onInputChange={(event, newInputValue) => {
                    dispatch(searchProductFromCatAction({
                      type: 'brand',
                      categories: cardOfferFormData?.categories?.map((cat) => {
                        return cat?._id
                      }) || [],
                      search_query: newInputValue
                    }))
                  }}
                  onChange={(event, selectedOptions) => {
                    setCardOfferFormData({
                      ...cardOfferFormData,
                      brands: selectedOptions
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6} className='mb-2 px-1'>
                <TextField
                  variant="outlined"
                  label="Start Date"
                  type="date"
                  name='start_date'
                  className='w-100'
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={cardOfferFormData?.start_date}
                  error={errors?.start_date}
                  helperText={errors?.start_date}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6} className='mb-2 px-1'>
                <TextField
                  variant="outlined"
                  label="End Date"
                  type="date"
                  name='end_date'
                  className='w-100'
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={cardOfferFormData?.end_date}
                  error={errors?.end_date}
                  helperText={errors?.end_date}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Box className='statusBox'>
                  <Typography color="inherit" className="flexSpacer">
                    <div>EMI Available?</div>
                  </Typography>
                  <FormControlLabel
                    style={{ marginLeft: 'auto', marginRight: 0 }}
                    control={
                      <Switch
                        checked={cardOfferFormData?.emi}
                        onChange={(e) => setCardOfferFormData({ ...cardOfferFormData, emi: e?.target?.checked })}
                        color="primary"
                      />
                    }
                    label={cardOfferFormData?.emi ? 'Yes' : 'No'}
                  />
                </Box>
              </Grid>
              {cardOfferFormData?.emi &&
                <Grid card xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <Typography variant='caption' color="textSecondary" className="flexSpacer">
                    EMI Details
                  </Typography>
                  <CKEditor
                    editor={ClassicEditor}
                    data={cardOfferFormData?.emi_details}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setCardOfferFormData({
                        ...cardOfferFormData,
                        emi_details: data
                      });
                    }}
                  />
                  {errors?.emi_details &&
                    <Typography variant='caption' color="textSecondary" style={{ color: 'red' }}>
                      {errors?.emi_details}
                    </Typography>
                  }
                </Grid>
              }
              {/* <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <FormControl variant="outlined" size="small" error={errors?.applicable} required style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-outlined-label">Applicable For?</InputLabel>
                  <Select
                    name='applicable'
                    label="Applicable For?"
                    value={cardOfferFormData?.applicable}
                    onChange={(e) => DataHandleChange(e)}
                  >
                    <MenuItem value="Online">Online</MenuItem>
                    <MenuItem value="Offline">Offline</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.applicable}</FormHelperText>
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-1 px-1'>
                <Typography color="inherit" className="flexSpacer">
                  <div>Applicable For ?</div>
                </Typography>
                <FormControl required style={{ width: '100%' }}>
                  <div style={{ display: 'flex' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cardOfferFormData?.applicable.includes('Online')}
                          onChange={() => handleCheckboxChange('Online')}
                          name='applicableOnline'
                          color='primary'
                        />
                      }
                      label='Online'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cardOfferFormData?.applicable.includes('Offline')}
                          onChange={() => handleCheckboxChange('Offline')}
                          name='applicableOffline'
                          color='primary'
                        />
                      }
                      label='Offline'
                    />
                  </div>
                  <FormHelperText style={{ color: '#f44336' }}>{errors?.applicable}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid card xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Typography variant='caption' color="textSecondary" className="flexSpacer">
                  Terms & Conditions
                </Typography>
                <CKEditor
                  editor={ClassicEditor}
                  data={cardOfferFormData?.terms_and_conditions}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setCardOfferFormData({
                      ...cardOfferFormData,
                      terms_and_conditions: data
                    });
                  }}
                />
                {errors?.terms_and_conditions &&
                  <Typography variant='caption' color="textSecondary" style={{ color: 'red' }}>
                    {errors?.terms_and_conditions}
                  </Typography>
                }
              </Grid>



              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Autocomplete
                  multiple
                  options={countryList || []}
                  getOptionLabel={(option) => option.name}
                  value={cardOfferFormData?.countries || []}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Countries"
                      placeholder="Search name"
                      error={errors?.countries}
                      helperText={errors?.countries}
                    />
                  )}
                  onInputChange={(event, newInputValue) => {
                    dispatch(searchCountriesAction({ search_query: newInputValue }))
                  }}
                  onChange={(event, selectedOptions) => {
                    setCardOfferFormData({
                      ...cardOfferFormData,
                      countries: selectedOptions
                    });
                  }}
                />
              </Grid>
              {cardOfferFormData?.countries && cardOfferFormData?.countries?.length > 0 &&
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <Autocomplete
                    multiple
                    options={stateList || []}
                    getOptionLabel={(option) => option.name}
                    value={cardOfferFormData?.states || []}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="States"
                        placeholder="Search name"
                        error={errors?.states}
                        helperText={errors?.states}
                      />
                    )}
                    onInputChange={(event, newInputValue) => {
                      dispatch(searchStatesAction({
                        countries: cardOfferFormData?.countries?.map((item) => {
                          return item?._id
                        }) || [],
                        search_query: newInputValue
                      }))
                    }}
                    onChange={(event, selectedOptions) => {
                      setCardOfferFormData({
                        ...cardOfferFormData,
                        states: selectedOptions
                      });
                    }}
                  />
                </Grid>
              }
              {cardOfferFormData?.states && cardOfferFormData?.states?.length > 0 &&
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <Autocomplete
                    multiple
                    options={cityList || []}
                    getOptionLabel={(option) => option.name}
                    value={cardOfferFormData?.cities || []}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Cities"
                        placeholder="Search name"
                        error={errors?.cities}
                        helperText={errors?.cities}
                      />
                    )}
                    onInputChange={(event, newInputValue) => {
                      dispatch(searchCitiesAction({
                        states: cardOfferFormData?.states?.map((item) => {
                          return item?._id
                        }) || [],
                        search_query: newInputValue
                      }))
                    }}
                    onChange={(event, selectedOptions) => {
                      setCardOfferFormData({
                        ...cardOfferFormData,
                        cities: selectedOptions
                      });
                    }}
                  />
                </Grid>
              }

              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  name='link'
                  label="Link"
                  variant="outlined"
                  size="small"
                  value={cardOfferFormData?.link}
                  error={errors?.link}
                  helperText={errors?.link}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>

              {!imagePreview ?
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <div className='fileDragArea'>
                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e)} />
                    <div className='iconDiv'><PermMediaIcon /></div>
                    <div className='fileupTxt'>Drag & Drop to upload image</div>
                    <div className='fileupTxt'>or</div>
                    <div style={{ paddingTop: '10px' }}>
                      <Button color="primary" variant='contained'>
                        Browse file
                      </Button>
                    </div>
                  </div>
                </Grid>
                :
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <div className='fileDragArea'>
                    <div className='imgPrevOuter'>
                      <img src={imagePreview} alt="Image Preview" />
                      <div className='imgRemove' onClick={() => removeImg()}>
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                </Grid>
              }

              {imagePreviews.length === 0 ? (
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <div className='fileDragArea'>
                    <input type="file" accept="image/*" multiple onChange={(e) => handleBannersFileChange(e)} />
                    <div className='iconDiv'><PermMediaIcon /></div>
                    <div className='fileupTxt'>Drag & Drop to upload multiple image</div>
                    <div className='fileupTxt'>or</div>
                    <div style={{ paddingTop: '10px' }}>
                      <Button color="primary" variant='contained'>
                        Browse file
                      </Button>
                    </div>
                  </div>
                </Grid>
              ) : (
                // Render image previews when there are images
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <div className='fileDragArea' style={{ height: 'auto', minHeight: '225px' }}>
                    {imagePreviews.map((preview, index) => (
                      <div key={index} className='imgPrevOuter'>
                        <img src={preview} alt={`Image Preview ${index}`} style={{ maxHeight: '225px' }} />
                        <div className='imgRemove' onClick={() => removeBannersImg(index)}>
                          <CloseIcon />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='AddMoreBtnDiv'>
                    <Button variant='outlined' color='default'>
                      <input type="file" accept="image/*" multiple onChange={(e) => handleBannersFileChange(e)} />
                      + Add More Images
                    </Button>
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Box className='statusBox'>
                  <Typography color="inherit" className="flexSpacer">
                    <div>Status</div>
                  </Typography>
                  <FormControlLabel
                    style={{ marginLeft: 'auto', marginRight: 0 }}
                    control={
                      <Switch
                        checked={cardOfferFormData?.status}
                        onChange={(e) => setCardOfferFormData({ ...cardOfferFormData, status: e?.target?.checked })}
                        color="primary"
                      />
                    }
                    label={cardOfferFormData?.status ? 'Active' : 'Inactive'}
                  />
                </Box>
              </Grid>

              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-1 px-1'>
                <Typography variant="h5" color="inherit">Meta Content</Typography>
              </Grid>

              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  name='meta_title'
                  label="Meta Title"
                  variant="outlined"
                  size="small"
                  value={cardOfferFormData?.meta_title}
                  error={errors?.meta_title}
                  helperText={errors?.meta_title}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  name='meta_keywords'
                  label="Meta Keywords"
                  variant="outlined"
                  size="small"
                  value={cardOfferFormData?.meta_keywords}
                  error={errors?.meta_keywords}
                  helperText={errors?.meta_keywords}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid cardOffer xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  name='meta_description'
                  label="Meta Description"
                  variant="outlined"
                  size="small"
                  value={cardOfferFormData?.meta_description}
                  error={errors?.meta_description}
                  helperText={errors?.meta_description}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>

            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      {/* delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }} onClick={() => deleteCardOffer(ID)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* import file dialog */}
      <Dialog
        open={importCardOfferModal}
        // keepMounted
        onClose={() => closeImpCsvModal()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth={import_error && import_error_data ? 'lg' : 'xs'}
        fullWidth
      >
        <DialogTitle id="alert-dialog-slide-title">
          Import Offers
        </DialogTitle>
        <DialogContent>
          {csvLoader ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: '10px 0px' }}>
              <CircularProgress />
              <Typography color="textSecondary" className="flexSpacer w-100 mt-1" align='center'>
                Verifying CSV file...
              </Typography>
            </div>
            :
            <div style={{ marginBottom: '25px' }}>
              <div className='CSVReaderInp' style={{ marginBottom: '10px' }}>
                <span className='FileNam'>
                  {fileDetails && fileDetails?.name}
                </span>
                <Button color="primary" variant='contained' style={{ marginLeft: 'auto' }}>
                  Browse
                </Button>
                <CSVReader
                  onFileLoaded={handleCSVData}
                  parserOptions={{ header: true }}
                />
              </div>
              <div className='SampleCsv'>
                Download <span onClick={() => cardOffersDemoCsv()}>Sample.csv</span>
              </div>
            </div>
          }
          {import_error && import_error_data &&
            <div class="table-wrapper-custom">
              <table>
                <thead>
                  <tr class="sticky">
                    <th>Instruments Provider</th>
                    <th>Instrument Name</th>
                    <th>Offer Title</th>
                    <th>Sub Title</th>
                    <th>Offer per</th>
                    <th>Description</th>
                    <th>type</th>
                    <th>category</th>
                    <th>product</th>
                    <th>service</th>
                    <th>brand</th>
                    <th>applicable for</th>
                    <th>start date</th>
                    <th>end date</th>
                    <th>link</th>
                    <th>error</th>
                  </tr>
                </thead>
                <tbody>
                  {import_error_data && import_error_data.map((itm, i) => {
                    return (
                      <tr key={i}>
                        <td>{removeDoubleQuotes(itm?.instruments_provider)}</td>
                        <td>{removeDoubleQuotes(itm?.instrument_name)}</td>
                        <td>{removeDoubleQuotes(itm?.offer_title)}</td>
                        <td>{removeDoubleQuotes(itm?.sub_title)}</td>
                        <td>{removeDoubleQuotes(itm?.offer_per)}</td>
                        <td>{removeDoubleQuotes(itm?.description)}</td>
                        <td>{removeDoubleQuotes(itm?.type)}</td>
                        <td>{removeDoubleQuotes(itm?.category)}</td>
                        <td>{removeDoubleQuotes(itm?.product)}</td>
                        <td>{removeDoubleQuotes(itm?.service)}</td>
                        <td>{removeDoubleQuotes(itm?.brand)}</td>
                        <td>{removeDoubleQuotes(itm?.applicable_for)}</td>
                        <td>{removeDoubleQuotes(itm?.start_date)}</td>
                        <td>{removeDoubleQuotes(itm?.end_date)}</td>
                        <td>{removeDoubleQuotes(itm?.link)}</td>
                        <td style={{ color: 'indianred' }}>{removeDoubleQuotes(itm?.error)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button color="default" variant='contained' onClick={() => closeImpCsvModal()}>
            Cancel
          </Button>
          {!import_error && import_verified_data &&
            <Button color="primary" variant='contained' onClick={() => importCsvData()}>
              Import
            </Button>
          }
          {import_error && import_error_data &&
            <Button color="primary" variant='contained' onClick={() => downloadReport()}>
              Download Report
            </Button>
          }
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default CardOffers
