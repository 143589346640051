import { getAllCardsLoading, getAllCardsSuccess, getAllCardsError, createCardLoading, createCardSuccess, createCardError, updateCardLoading, updateCardSuccess, updateCardError, deleteCardLoading, deleteCardSuccess, deleteCardError, updateCardStatusLoading, updateCardStatusSuccess, updateCardStatusError, searchCardLoading, searchCardSuccess, searchCardError, exportAllCsvCardsLoading, exportAllCsvCardsSuccess, exportAllCsvCardsError, importAllCsvCardsLoading, importAllCsvCardsSuccess, importAllCsvCardsError } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllCardsAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllCardsLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&provider_id=${filter?.provider_id}&name=${filter?.name}&type=${filter?.type}&status=${filter?.status}&created_by=${filter?.created_by}`;
      const response = await api.get(`card/get/all?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllCardsSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllCardsError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const getAllCsvCardsAction = (filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(exportAllCsvCardsLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&provider_id=${filter?.provider_id}&name=${filter?.name}&type=${filter?.type}&status=${filter?.status}&created_by=${filter?.created_by}`;
      const response = await api.get(`card/export/csv?${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(exportAllCsvCardsSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(exportAllCsvCardsError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const importCsvCardAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(importAllCsvCardsLoading());
      const response = await api.post('card/import/csv', postData);
      const data = response.data;
      dispatch(importAllCsvCardsSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(importAllCsvCardsError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const createCardAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(createCardLoading());
      const response = await api.post('card/create', postData);
      const data = response.data;
      dispatch(createCardSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(createCardError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateCardAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateCardLoading());
      const response = await api.post('card/update', postData);
      const data = response.data;
      dispatch(updateCardSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateCardError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateCardStatusAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateCardStatusLoading());
      const response = await api.post('card/update/status', postData);
      const data = response.data;
      dispatch(updateCardStatusSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateCardStatusError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const deleteCardAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(deleteCardLoading());
      const response = await api.post('card/delete', postData);
      const data = response.data;
      dispatch(deleteCardSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(deleteCardError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const searchCardAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(searchCardLoading());
      const response = await api.get(`card/search?search_query=${postData?.search_query}&provider=${postData?.provider}`);
      const data = response.data;
      dispatch(searchCardSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      dispatch(searchCardError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllCardsAction,
  getAllCsvCardsAction,
  importCsvCardAction,
  createCardAction,
  updateCardAction,
  updateCardStatusAction,
  deleteCardAction,
  searchCardAction
};
