import { GET_ALL_PROVIDERS_LOADING, GET_ALL_PROVIDERS_SUCCESS, GET_ALL_PROVIDERS_ERROR, CREATE_PROVIDER_LOADING, CREATE_PROVIDER_SUCCESS, CREATE_PROVIDER_ERROR, UPDATE_PROVIDER_LOADING, UPDATE_PROVIDER_SUCCESS, UPDATE_PROVIDER_ERROR, DELETE_PROVIDER_LOADING, DELETE_PROVIDER_SUCCESS, DELETE_PROVIDER_ERROR, UPDATE_PROVIDER_STATUS_LOADING, UPDATE_PROVIDER_STATUS_SUCCESS, UPDATE_PROVIDER_STATUS_ERROR, SEARCH_PROVIDER_LOADING, SEARCH_PROVIDER_SUCCESS, SEARCH_PROVIDER_ERROR, EXPORT_ALL_CSV_PROVIDERS_LOADING, EXPORT_ALL_CSV_PROVIDERS_SUCCESS, EXPORT_ALL_CSV_PROVIDERS_ERROR, IMPORT_ALL_CSV_PROVIDERS_LOADING, IMPORT_ALL_CSV_PROVIDERS_SUCCESS, IMPORT_ALL_CSV_PROVIDERS_ERROR } from './actionTypes';

// get all Providers
const getAllProvidersLoading = () => {
  return {
    type: GET_ALL_PROVIDERS_LOADING,
  };
};

const getAllProvidersSuccess = (data) => {
  return {
    type: GET_ALL_PROVIDERS_SUCCESS,
    payload: data,
  };
};

const getAllProvidersError = (error) => {
  return {
    type: GET_ALL_PROVIDERS_ERROR,
    payload: error,
  };
};

// export all csv Providers
const exportAllCsvProvidersLoading = () => {
  return {
    type: EXPORT_ALL_CSV_PROVIDERS_LOADING,
  };
};

const exportAllCsvProvidersSuccess = (data) => {
  return {
    type: EXPORT_ALL_CSV_PROVIDERS_SUCCESS,
    payload: data,
  };
};

const exportAllCsvProvidersError = (error) => {
  return {
    type: EXPORT_ALL_CSV_PROVIDERS_ERROR,
    payload: error,
  };
};

// import all csv Providers
const importAllCsvProvidersLoading = () => {
  return {
    type: IMPORT_ALL_CSV_PROVIDERS_LOADING,
  };
};

const importAllCsvProvidersSuccess = (data) => {
  return {
    type: IMPORT_ALL_CSV_PROVIDERS_SUCCESS,
    payload: data,
  };
};

const importAllCsvProvidersError = (error) => {
  return {
    type: IMPORT_ALL_CSV_PROVIDERS_ERROR,
    payload: error,
  };
};

// create provider
const createProviderLoading = () => {
  return {
    type: CREATE_PROVIDER_LOADING,
  };
};

const createProviderSuccess = (data) => {
  return {
    type: CREATE_PROVIDER_SUCCESS,
    payload: data,
  };
};

const createProviderError = (error) => {
  return {
    type: CREATE_PROVIDER_ERROR,
    payload: error,
  };
};

// update provider
const updateProviderLoading = () => {
  return {
    type: UPDATE_PROVIDER_LOADING,
  };
};

const updateProviderSuccess = (data) => {
  return {
    type: UPDATE_PROVIDER_SUCCESS,
    payload: data,
  };
};

const updateProviderError = (error) => {
  return {
    type: UPDATE_PROVIDER_ERROR,
    payload: error,
  };
};

// update provider status
const updateProviderStatusLoading = () => {
  return {
    type: UPDATE_PROVIDER_STATUS_LOADING,
  };
};

const updateProviderStatusSuccess = (data) => {
  return {
    type: UPDATE_PROVIDER_STATUS_SUCCESS,
    payload: data,
  };
};

const updateProviderStatusError = (error) => {
  return {
    type: UPDATE_PROVIDER_STATUS_ERROR,
    payload: error,
  };
};

// delete provider
const deleteProviderLoading = () => {
  return {
    type: DELETE_PROVIDER_LOADING,
  };
};

const deleteProviderSuccess = (data) => {
  return {
    type: DELETE_PROVIDER_SUCCESS,
    payload: data,
  };
};

const deleteProviderError = (error) => {
  return {
    type: DELETE_PROVIDER_ERROR,
    payload: error,
  };
};

// search provider
const searchProviderLoading = () => {
  return {
    type: SEARCH_PROVIDER_LOADING,
  };
};

const searchProviderSuccess = (data) => {
  return {
    type: SEARCH_PROVIDER_SUCCESS,
    payload: data,
  };
};

const searchProviderError = (error) => {
  return {
    type: SEARCH_PROVIDER_ERROR,
    payload: error,
  };
};

export { 
    getAllProvidersLoading, 
    getAllProvidersSuccess, 
    getAllProvidersError,

    exportAllCsvProvidersLoading, 
    exportAllCsvProvidersSuccess, 
    exportAllCsvProvidersError,

    importAllCsvProvidersLoading, 
    importAllCsvProvidersSuccess, 
    importAllCsvProvidersError,

    createProviderLoading, 
    createProviderSuccess, 
    createProviderError,

    updateProviderLoading, 
    updateProviderSuccess, 
    updateProviderError,

    updateProviderStatusLoading, 
    updateProviderStatusSuccess, 
    updateProviderStatusError,

    deleteProviderLoading, 
    deleteProviderSuccess, 
    deleteProviderError,

    searchProviderLoading, 
    searchProviderSuccess, 
    searchProviderError
};
