export const GET_ALL_CARDS_LOADING = 'GET_ALL_CARDS_LOADING';
export const GET_ALL_CARDS_SUCCESS = 'GET_ALL_CARDS_SUCCESS';
export const GET_ALL_CARDS_ERROR = 'GET_ALL_CARDS_ERROR';

export const EXPORT_ALL_CSV_CARDS_LOADING = 'EXPORT_ALL_CSV_CARDS_LOADING';
export const EXPORT_ALL_CSV_CARDS_SUCCESS = 'EXPORT_ALL_CSV_CARDS_SUCCESS';
export const EXPORT_ALL_CSV_CARDS_ERROR = 'EXPORT_ALL_CSV_CARDS_ERROR';

export const IMPORT_ALL_CSV_CARDS_LOADING = 'IMPORT_ALL_CSV_CARDS_LOADING';
export const IMPORT_ALL_CSV_CARDS_SUCCESS = 'IMPORT_ALL_CSV_CARDS_SUCCESS';
export const IMPORT_ALL_CSV_CARDS_ERROR = 'IMPORT_ALL_CSV_CARDS_ERROR';

export const CREATE_CARD_LOADING = 'CREATE_CARD_LOADING';
export const CREATE_CARD_SUCCESS = 'CREATE_CARD_SUCCESS';
export const CREATE_CARD_ERROR = 'CREATE_CARD_ERROR';

export const UPDATE_CARD_LOADING = 'UPDATE_CARD_LOADING';
export const UPDATE_CARD_SUCCESS = 'UPDATE_CARD_SUCCESS';
export const UPDATE_CARD_ERROR = 'UPDATE_CARD_ERROR';

export const UPDATE_CARD_STATUS_LOADING = 'UPDATE_CARD_STATUS_LOADING';
export const UPDATE_CARD_STATUS_SUCCESS = 'UPDATE_CARD_STATUS_SUCCESS';
export const UPDATE_CARD_STATUS_ERROR = 'UPDATE_CARD_STATUS_ERROR';

export const DELETE_CARD_LOADING = 'DELETE_CARD_LOADING';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_ERROR = 'DELETE_CARD_ERROR';

export const SEARCH_CARD_LOADING = 'SEARCH_CARD_LOADING';
export const SEARCH_CARD_SUCCESS = 'SEARCH_CARD_SUCCESS';
export const SEARCH_CARD_ERROR = 'SEARCH_CARD_ERROR';
